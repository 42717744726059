import { createGlobalStyle } from "styled-components";
import common from "./common";
import Assets from "./Assets";
import Dashboard from "./Dashboard";
import Graphs from "./Graphs";
import KpiTable from "./KpiTable";
import Notifications from "./Notifications";
import Users from "./Users";

/* NOTE if you're not adding rules to html and/or body, you probably
 * shouldn't be touching this. This is for global items only, or edge case
 * third party integration. 99.9% of styling should be done in the theme.
 */
export const GlobalStyle = createGlobalStyle`
  html {
    /* NOTE need to override material-ui's styling which is included after styled-component's styling.
    */
    -webkit-font-smoothing: subpixel-antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }
`;

export default {
  ...common,
  Assets,
  Dashboard,
  Graphs,
  KpiTable,
  Notifications,
  Users
};

import api from "./api";

export const getMicrocodes = params => {
  return api.get(`/customers/assets/microcodes`, { params });
};

export const muteMicrocodes = serialNumbers => {
  return api.put(`/customers/assets/microcodes/mute`, { serialNumbers });
};

export const unmuteMicrocodes = serialNumbers => {
  return api.put(`/customers/assets/microcodes/unmute`, { serialNumbers });
};

export const upgradeMicrocodes = serialNumbers => {
  return api.put(`/customers/assets/microcodes/upgrade`, { serialNumbers });
};

import { call, put, takeEvery } from "redux-saga/effects";
import { CONST_VALUES } from "../../constants/constants";
import * as multiSarePermissionsService from "../../services/multiSharePermissionsService";
import * as permissionService from "../../services/permissionService";
import {
  CREATE_MULTIPLE_ASSET_PERMISSIONS_PENDING,
  postMultipleAssetPermissionsFulfilled,
  postMultipleAssetPermissionsRejected,
  CREATE_MULTIPLE_ASSET_PERMISSIONS_RESET_PENDING
} from "../actions/multiSharePermissionsAction";

import getResourceError from "./helpers/getResourceError";

export function* postMultipleAssetPermissions(action) {
  const { payload, panelType } = action.payload;
  try {
    let response = {};
    if (panelType === CONST_VALUES.edit) {
      response = yield call(permissionService.putPermissions, payload);
    } else {
      response = yield call(
        multiSarePermissionsService.multiSharePermissions,
        payload
      );
    }
    yield put(postMultipleAssetPermissionsFulfilled(response));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "POST",
      "PERMISSIONS",
      "Oops. An error occurred when updating the permissions."
    );
    yield put(postMultipleAssetPermissionsRejected(resourceError));
  }
}

export function* postMultipleAssetPermissionsReset(action) {
  const { payload } = action.payload;
  yield put(postMultipleAssetPermissionsFulfilled(payload));
}

function* watchMultipleAssetPermissions() {
  yield takeEvery(
    CREATE_MULTIPLE_ASSET_PERMISSIONS_PENDING,
    postMultipleAssetPermissions
  );
}
function* watchMultipleAssetPermissionsReset() {
  yield takeEvery(
    CREATE_MULTIPLE_ASSET_PERMISSIONS_RESET_PENDING,
    postMultipleAssetPermissionsReset
  );
}

export default [
  watchMultipleAssetPermissions,
  watchMultipleAssetPermissionsReset
];

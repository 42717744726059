import { call, put, takeEvery } from "redux-saga/effects";
import * as commentService from "../../services/commentService";
import {
  CREATE_COMMENT_PENDING,
  GET_COMMENTS_PENDING,
  postCommentRejected,
  postCommentFulfilled,
  getCommentsRejected,
  getCommentsFulfilled
} from "../actions/commentActions";
import getResourceError from "./helpers/getResourceError";
import { notificationSend } from "../actions/notificationActions";

export function* getComments(action) {
  const { serialNumber } = action.payload;

  try {
    if (serialNumber) {
      const comments = yield call(commentService.getComments, serialNumber);
      yield put(getCommentsFulfilled(comments));
    } else {
      yield put(getCommentsFulfilled(null));
    }
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "COMMENTS",
      "Oops. An error occurred when fetching the list of comments."
    );
    yield put(getCommentsRejected(resourceError));
  }
}

export function* postComment(action) {
  const { serialNumber, comment } = action.payload;

  try {
    const response = yield call(
      commentService.postComment,
      serialNumber,
      comment
    );
    yield put(postCommentFulfilled(response));
    const comments = yield call(commentService.getComments, serialNumber);

    yield put(getCommentsFulfilled(comments));
    yield put(
      notificationSend({
        id: new Date(),
        label: "Comment created successfully.",
        variant: "success",
        dismissAfter: 5000
      })
    );
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "POST",
      "COMMENT",
      "Oops. An error occurred when updating the comment."
    );
    yield put(postCommentRejected(resourceError));
  }
}

function* watchGetComments() {
  yield takeEvery(GET_COMMENTS_PENDING, getComments);
}

function* watchPostComment() {
  yield takeEvery(CREATE_COMMENT_PENDING, postComment);
}

export default [watchGetComments, watchPostComment];

import { CLEAR_REMOTE_RESOURCE_ERRORS } from "../../actions/globalRemoteResourceActions";
import { initialRemoteObjectState } from "./remoteResourceInitialStates";

/**
 * This is a higher order function common resource reducer for remote resources.
 *
 * This resolver processes actions with the following naming pattern:
 * `^${operation}_{resourceId}_{operationState}$`
 *
 * @param operationReducers An object of reducers arranged by operation and operation state.
 * example:
 * {
 *   ["SOME_OPERATION"]: {
 *     ["SOME_OPERATION_STATE"]: (state, action) => {return state;}
 *     ["SOME_OTHER_OPERATION_STATE"]: (state, action) => {return state;}
 *   },
 *   ["SOME_OTHER_OPERATION"]: {
 *     ["SOME_OTHER_OPERATION_STATE"]: (state, action) => {return state;}
 *     ["SOME_OTHER_OTHER_OPERATION_STATE"]: (state, action) => {return state;}
 *   }
 * }
 * @param defaultState The initial state for the reducer
 * @param resourceId The string name of the resource being managed example: "PROJECT_LOCATIONS"
 * @param dataKey (Optional) The field name where the resource value can be found on the server response
 * @param parentIdKey (Optional) The field name where the parent ID can be found on the value object in the server response
 * @param initialValue (Optional) The initial value present in the state's value property
 * @returns {Function}
 */
export default (
  operationReducers,
  getActionExpression,
  defaultState = initialRemoteObjectState
) => (resourceId, dataKey, parentIdKey, initialValue) => {
  // action expressions use the format:
  // `${operation}_{resourceId}_{operationState}
  const actionExpression = new RegExp(getActionExpression(resourceId));

  const initialState = parentIdKey
    ? {
        ...defaultState,
        value: initialValue || null,
        [parentIdKey]: null
      }
    : {
        ...defaultState,
        value: initialValue || null
      };

  return (state = initialState, { type, meta, payload }) => {
    if (type === CLEAR_REMOTE_RESOURCE_ERRORS) {
      if (payload && payload.length > 0) {
        const shouldClear = payload.some(error => error.source === resourceId);
        if (shouldClear) {
          return { ...state, error: null };
        }
      }
    }
    // Parse the action into the operation and operation state
    const actionExpressionMatches = actionExpression.exec(type);

    // If the action type does not match the format, ignore it
    if (actionExpressionMatches != null) {
      const [, operation, operationState] = actionExpressionMatches;

      // get the operation state handlers for the current operation
      const operationStateHandlers = operationReducers[operation];

      // get the operation state handler for the current operation state
      const operationStateHandler =
        operationStateHandlers && operationStateHandlers[operationState];

      // if we found a function, call it. otherwise return state.
      return operationStateHandler
        ? operationStateHandler(state, {
            type,
            payload,
            meta: {
              ...meta, // meta should not be able to override the props below
              dataKey,
              operation,
              operationState,
              resourceId,
              parentIdKey
            }
          })
        : state;
    }

    return state;
  };
};

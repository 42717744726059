import api from "./api";

export const getComments = serialNumber => {
  return api.get(`/partners/assets/escalated-reports/${serialNumber}/notes`);
};

export const postComment = (serialNumber, comment) => {
  return api.post(`/partners/assets/escalated-reports/${serialNumber}/notes`, {
    ...comment
  });
};

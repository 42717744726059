import api from "./api";

export const getContractRenewals = params => {
  return api.get(`/customers/assets/renewals`, { params });
};

export const muteContractRenewals = serialNumbers => {
  return api.put(`/customers/assets/renewals/mute`, { serialNumbers });
};

export const unmuteContractRenewals = serialNumbers => {
  return api.put(`/customers/assets/renewals/unmute`, { serialNumbers });
};

export const renewContractRenewals = serialNumbers => {
  return api.put(`/customers/assets/renewals/renew`, { serialNumbers });
};

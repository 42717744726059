const typesToShow = ["Validation"];

const getServerMessage = (serverResponse, defaultMessage) =>
  serverResponse && typesToShow.includes(serverResponse.type)
    ? serverResponse.errors.join(" ")
    : defaultMessage;

export default (serverResponse, operation, source, defaultMessage) => ({
  message: getServerMessage(serverResponse, defaultMessage),
  operation,
  source
});

import { call, put, takeEvery } from "redux-saga/effects";
import * as customerService from "../../services/customerService";
import {
  GET_CUSTOMER_SITES_PENDING,
  getCustomerSitesRejected,
  getCustomerSitesFulfilled
} from "../actions/customerActions";
import getResourceError from "./helpers/getResourceError";

export function* getCustomerSites(action) {
  try {
    var customerSites = yield call(customerService.getCustomerSites);
   
    yield put(getCustomerSitesFulfilled(customerSites));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "CUSTOMER_SITES",
      "Oops. An error occurred when fetching the customer sites."
    );
    yield put(getCustomerSitesRejected(resourceError));
  }
}

function* watchGetCustomerSites() {
  yield takeEvery(GET_CUSTOMER_SITES_PENDING, getCustomerSites);
}

export default [watchGetCustomerSites];

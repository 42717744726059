import createWithActionCreator from "./helpers/createWithActionCreator";

// GET CONTRACT_RENEWALS
export const GET_CONTRACT_RENEWALS_PENDING = "GET_CONTRACT_RENEWALS_PENDING";
export const GET_CONTRACT_RENEWALS_REJECTED = "GET_CONTRACT_RENEWALS_REJECTED";
export const GET_CONTRACT_RENEWALS_FULFILLED =
  "GET_CONTRACT_RENEWALS_FULFILLED";

export const getContractRenewalsPending = params => ({
  type: GET_CONTRACT_RENEWALS_PENDING,
  payload: { params }
});

export const getContractRenewalsRejected = error => ({
  type: GET_CONTRACT_RENEWALS_REJECTED,
  payload: error
});

export const getContractRenewalsFulfilled = payload => {
  return {
    type: GET_CONTRACT_RENEWALS_FULFILLED,
    payload
  };
};

// PUT CONTRACT_RENEWALS
export const PUT_CONTRACT_RENEWALS_PENDING = "PUT_CONTRACT_RENEWALS_PENDING";
export const PUT_CONTRACT_RENEWALS_REJECTED = "PUT_CONTRACT_RENEWALS_REJECTED";
export const PUT_CONTRACT_RENEWALS_FULFILLED =
  "PUT_CONTRACT_RENEWALS_FULFILLED";

export const muteContractRenewalsPending = (serialNumbers, params) => ({
  type: PUT_CONTRACT_RENEWALS_PENDING,
  payload: { serialNumbers, params, type: "mute" }
});

export const unmuteContractRenewalsPending = (serialNumbers, params) => ({
  type: PUT_CONTRACT_RENEWALS_PENDING,
  payload: { serialNumbers, params, type: "unmute" }
});

export const renewContractRenewalsPending = (serialNumbers, params) => ({
  type: PUT_CONTRACT_RENEWALS_PENDING,
  payload: { serialNumbers, params, type: "renew" }
});

export const putContractRenewalsRejected = error => ({
  type: PUT_CONTRACT_RENEWALS_REJECTED,
  payload: error
});

export const putContractRenewalsFulfilled = payload => {
  return {
    type: PUT_CONTRACT_RENEWALS_FULFILLED,
    payload
  };
};

export const withGetContractRenewals = createWithActionCreator(
  "getContractRenewals",
  getContractRenewalsPending
);

export const withMuteContractRenewals = createWithActionCreator(
  "muteContractRenewals",
  muteContractRenewalsPending
);

export const withUnmuteContractRenewals = createWithActionCreator(
  "unmuteContractRenewals",
  unmuteContractRenewalsPending
);

export const withRenewContractRenewals = createWithActionCreator(
  "renewContractRenewals",
  renewContractRenewalsPending
);

import { css } from "styled-components";
import { breakpoint } from "./helpers/breakpoints";

export default {
  CapacityTrend: {
    CapacityLegendIcon: {
      style: css`
        width: 16px;
        height: ${p => (p.dotted ? "auto" : "16px")};
        background-color: ${p => (p.dotted ? "white" : p.color)};
        border-bottom: ${p => (p.dotted ? `2px dotted ${p.color}` : "none")};
      `
    },
    StorageTrendWrapper: {
      style: css`
        overflow: auto;
        width: 100%;
      `
    },
    StorageTrend: {
      style: css`
        width: 100%;
        background-color: white;
        padding: 15px;
        min-width: 700px;

        @media ${breakpoint.sm} {
          padding: 40px;
        }

        @media print {
          padding: 15px;
        }
      `
    },
    StorageTrendHeader: {
      style: css`
        display: grid;
        grid-template-columns: auto 1fr auto;
        margin-right: 0;
        grid-row-gap: 15px;

        @media ${breakpoint.sm} {
          margin-right: 35px;
        }
      `
    },
    StorageTrendLegend: {
      style: css`
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 20px;
      `
    }
  },
  DataDriveBreakdown: {
    DataDrives: {
      style: css`
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 100%;
        grid-template-columns: 100%;
        grid-gap: 20px;
        padding: 20px 20px 10px;

        @media ${breakpoint.sm} {
          -ms-grid-columns: 65% auto;
          grid-template-columns: 65% auto;
        }

        @media print {
          -ms-grid-columns: 65% auto;
          grid-template-columns: 65% auto;
          .chartjs-render-monitor {
            position: relative;
            left: -40px;
          }
        }
      `
    },
    DataDrivesAnalytics: {
      style: css`
        display: grid;
        grid-template-columns: 100%;
        grid-gap: 10px;
        padding: 20px;
      `
    },
    StyledDataDrivesAnalytics: {
      style: css`
        display: grid;
        grid-template-columns: 100%;
        grid-gap: 20px;
        padding: 0px 16px 0px 16px;
      `
    },
    DataDriveInfo: {
      style: css`
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 15px;
        padding-top: 6px;
        border-top: 1px solid black;
      `
    },
    LegendGrid: {
      style: css`
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        justify-items: left;
        align-items: center;
        margin-top: 10px;

        @media ${breakpoint.sm} {
          -ms-grid-columns: 1fr 1fr;
          grid-template-columns: 1fr 1fr;
        }

        @media print {
          -ms-grid-columns: 1fr 1fr;
          grid-template-columns: 1fr 1fr;
        }
      `
    },
    LegendGridAnalytics: {
      style: css`
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
        justify-items: left;
        align-items: center;

        @media ${breakpoint.l} {
          -ms-grid-columns: 1fr 1fr;
          grid-template-columns: 1fr 1fr;
        }

        @media print {
          -ms-grid-columns: 1fr 1fr;
          grid-template-columns: 1fr 1fr;
        }
      `
    },
    LegendIcon: {
      style: css`
        width: 24px;
        height: 24px;
        background-color: ${p => p.color};
        & > div:nth-of-type(1) {
          background-color: ${p => p.color};
          display: grid;
          grid-template-columns: 1fr;
          width: 100%;
          height: 15px;
          border-bottom: 1px solid white;
        }

        & > div:nth-of-type(2) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          height: 15px;

          div:nth-of-type(1) {
            background-color: ${p => p.color}99;
            width: 100%;
          }

          div:nth-of-type(2) {
            background-color: ${p => p.color}4D;
            width: 100%;
            border-left: 1px solid white;
          }
        }
      `
    },
    LegendIconInternalDrive: {
      style: css`
        width: 24px;
        height: 24px;
        background-color: ${p => p.color};
        & > div:nth-of-type(1) {
          background-color: ${p => p.color};
          display: grid;
          grid-template-columns: 1fr;
          width: 100%;
          height: 15px;
          border-bottom: 1px solid white;
        }

        & > div:nth-of-type(2) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          height: 15px;

          div:nth-of-type(1) {
            background-color: ${p => p.color}99;
            width: 100%;
          }

          div:nth-of-type(2) {
            background-color: ${p => p.color}4D;
            width: 100%;
            border-left: 1px solid white;
          }
        }
      `
    },
    LegendText: {
      style: css`
        & > span {
          display: block;
          white-space: nowrap;
        }
      `
    },
    StyledExpandBlock: {
      style: css`
        position: absolute;
        bottom: 4px;
        display: flex;
        justify-content: center;
        width: 100%;
      `
    },
    StyledImage: {
      style: css`
        cursor: pointer;
      `
    },
    StyledLabel: {
      style: css`
        cursor: pointer;
        font-size: 12px;
        color: #2a6bd2;
        margin-left: 4px;
      `
    },

    DriveDetails: {
      style: css`
        display: -ms-grid;
        display: grid;
        justify-items: center;
        margin-top: 8px;
        white-space: nowrap;
        width: 100%;

        && p {
          font-weight: 600;
        }
      `
    },

    DriveSummary: {
      style: css`
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        justify-items: left;
        margin-bottom: 10px;
        white-space: nowrap;
        width: 100%;

        && p {
          font-weight: 600;
        }
      `
    }
  },
  DateRangeWrapper: {
    style: css`
      // display: -ms-grid;
      // display: grid;
      // -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
      // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      // grid-gap: 10px;
      // margin: 0px;
      border-bottom: 1px solid lightgrey;
      // justify-items: center;
      display: flex;
      justify-content: flex-end;
      text-align: center;
      max-width: 312px;
      margin-left: auto !important;

      @media ${breakpoint.sm} {
        // -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
        // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        // grid-gap: 20px;
        margin: 0px;
        max-width: 312px;
        margin-left: auto;
      }
      @media ${breakpoint.standardSize} {
        // margin: 0px;
        max-width: 270px;
        // margin-left: auto;
      }

      @media print {
        max-width: 500px;
        margin-left: auto;
      }
      & span {
        flex: 1;
      }
      &.day-wrapper {
        // -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr;
        // grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        max-width: 550px;
      }
    `
  },
  DateRangeItem: {
    style: css`
      width: max-content;
      cursor: pointer;
      padding: 5px 5px 10px 5px;
      color: #414141;
      &.selected {
        span {
          font-weight: bold;
        }
        border-bottom: 2px solid black;
      }

      @media ${breakpoint.sm} {
        padding: 5px 12px 10px 12px;
      }
      @media ${breakpoint.standardSize} {
        padding: 5px 4px 10px 4px;
      }
    `
  },
  Legend: {
    style: css`
      // width: max-content;
      // max-width: 75%;
      // // margin: 0 auto;
      // float: right;
      display: grid;
      grid-template-columns: 1fr auto;
      @media ${breakpoint.mw} {
        margin-left: auto;
        margin-bottom: 12px;
      }
    `
  },
  LegendItem: {
    style: css`
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: auto 1fr;
      grid-template-columns: auto 1fr;
      grid-column-gap: 4px;
      align-items: center;
    `
  },
  LengendItems: {
    style: css`
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 4px;
      justify-content: center;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        float: right;
      }

      & div {
        display: grid;
        display: -ms-grid;
        grid-template-columns: auto auto auto;
        -ms-grid-columns: auto auto auto;
        grid-gap: 4px;
        align-items: center;
      }
    `
  },
  LegendIcon: {
    style: css`
      background-color: ${p => p.color};
      width: 10px;
      height: 10px;
    `
  },
  Tooltip: {
    style: css`
      position: absolute;
      top: ${p => p.top}px;
      left: ${p => p.left}px;
      border-radius: 0;
      z-index: 100;
    `
  },
  TooltipTitle: {
    style: css`
      padding: 10px 15px;
      border-bottom: 2px solid lightgrey;
      font-weight: 600;
      font-size: 14px;
    `
  },
  TooltipDetails: {
    style: css`
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 15px;
      padding: 10px 15px;
      font-size: 14px;

      & span:nth-of-type(odd) {
        font-weight: 600;
      }

      & span:nth-of-type(even) {
        text-align: right;
      }
      @media (min-width: 200px) and (max-width: 600px) {
        grid-template-columns: 1fr;
        grid-gap: 5px;
        & span:nth-of-type(even) {
          text-align: left;
        }
      }
    `
  },

  TooltipDetailsPerformance: {
    style: css`
      display: grid;
      // grid-template-columns: 1fr auto;
      grid-gap: 15px;
      padding: 10px 15px;
      font-size: 14px;

      & span:nth-of-type(odd) {
        font-weight: 600;
      }

      & span:nth-of-type(even) {
        text-align: right;
      }
    `
  }
};

// Font
export const BASE_FONT_SIZE = 16;
export const FONT_FAMILY = ` 'Open Sans', Arial, Helvetica, sans-serif`;

// Spacing
export const SPACE_TINY = BASE_FONT_SIZE / 4;
export const SPACE_SMALL = BASE_FONT_SIZE / 2;
export const SPACE_MEDIUM = BASE_FONT_SIZE / 1.5;
export const SPACE_DEFAULT = BASE_FONT_SIZE;
export const SPACE_LARGE = BASE_FONT_SIZE * 1.5;
export const SPACE_EXTRA_LARGE = BASE_FONT_SIZE * 2.5;
export const SPACE_EXTRA_EXTRA_LARGE = BASE_FONT_SIZE * 5;

import qs from "qs";
import Cookies from "js-cookie";
import { replace } from "lodash";
import { CONST_VALUES } from "../constants/constants";
import { validateUrlForSec } from "../utils/clearsightUtils";

const defaultFetchOptions = {
  credentials: "same-origin",
  headers: {
    Accept: "application/json",
    withCredentials: true
  }
};

const serializeQuery = parameters => qs.stringify(parameters);

let noAccessJson = undefined;
let signal = undefined;
let controller = undefined;
const browsr = window.navigator.userAgent;
const ie = browsr.indexOf("Trident/");
if (ie === -1) {
  // setup AbortController
  controller = new AbortController();
  // signal to pass to fetch
  signal = controller.signal;
}

const request = (url, options = {}) => {
  let query = "";
  if (options.params != null) {
    // Extract the parameters and format the query string
    const serializedParameters = serializeQuery(options.params);
    if (serializedParameters && serializedParameters.length) {
      query = `?${serializedParameters}`;
    }

    // Fetch does not accept a params option
    // Copy the options and remove the params key
    options = { ...options };
    delete options.params;
  }

  const fetchOptions = {
    ...defaultFetchOptions,
    ...options,
    headers: {
      ...options.headers,
      "X-CSRFToken": Cookies.get("csrftoken")
    },
    signal: signal
  };

  function validateInnerHtmls(data) {
    let response_stirng = CONST_VALUES.error_contact_admin;
    if (CONST_VALUES.invalid_chars.find(a => data.includes(a)) === undefined) {
      response_stirng = data;
    }
    return response_stirng;
  }

  return fetch(`${url}${query}`, fetchOptions).then(response => {
    const successful = [200, 201, 202].includes(response.status);

    if (response.status === 204) {
      return response.status;
    }

    const contentTypeHeader = response.headers.get("content-type");
    if (contentTypeHeader && contentTypeHeader.includes("application/json")) {
      return response
        .json()
        .then(body => {
          if (response.status === 401) {
            window.location.href =
              validateUrlForSec(body.logoutUrl) || validateUrlForSec("/");
          }

          if (successful) {
            if (
              body &&
              body.hasOwnProperty("message") &&
              (body.message === CONST_VALUES.Asset_Not_Assigned ||
                body.message === CONST_VALUES.Asset_Revoked)
            ) {
              // Cancel all the subsequent requests in the even of unassigned asset or permissions revoked for the asset
              if (ie === -1) {
                controller.abort();
              }
              document
                .getElementById("multiShare_permission_slider_id")
                .classList.remove("active");
              document
                .getElementById("multiShare_permission_slider_id")
                .classList.add("inactive");
              const modal = document.getElementById("no-access-modal");
              const header = `<h6 class="modal-header-text">${replace(
                noAccessJson[body.message].code,
                /_/g,
                " "
              )}</h4>`;
              const content = `<p>${noAccessJson[body.message].label}</p>`;
              modal.getElementsByClassName(
                "modal-header"
              )[0].innerHTML = validateInnerHtmls(header);
              modal.getElementsByClassName(
                "modal-body"
              )[0].innerHTML = validateInnerHtmls(content);
              modal.style.display = "block";
            } else {
              if (body && body.hasOwnProperty("no_access_msg")) {
                noAccessJson = body.no_access_msg;
              }
              return body;
            }
          }

          throw body;
        })
        .catch(err => {
          console.log(err);
        });
    }

    // If the content type does not match a handler
    // the response must be manually parsed for data
    if (successful) {
      return response;
    }
    throw response;
  });
};

const ApiService = {
  get: (url, options = {}) => {
    return request(url, {
      ...options,
      method: "GET"
    });
  },

  post: (url, body, options = {}) => {
    return request(url, {
      ...options,
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        ...options.headers,
        "Content-Type": "application/json"
      }
    });
  },

  put: (url, body, options = {}) => {
    return request(url, {
      ...options,
      method: "put",
      body: JSON.stringify(body),
      headers: {
        ...options.headers,
        "Content-Type": "application/json"
      }
    });
  },

  // consolidate this code - it's the same as post except for method
  patch: (url, body, options = {}) => {
    return request(url, {
      ...options,
      method: "PATCH",
      body: JSON.stringify(body),
      headers: {
        ...options.headers,
        "Content-Type": "application/json"
      }
    });
  },

  delete: (url, body, options = {}) => {
    return request(url, {
      ...options,
      method: "DELETE",
      body: JSON.stringify(body),
      headers: {
        ...options.headers,
        "Content-Type": "application/json"
      }
    });
  }
};

export default ApiService;

import createActionCreator from "./helpers/createActionCreator";
import createWithActionCreator from "./helpers/createWithActionCreator";

export const CLEAR_REMOTE_RESOURCE_ERRORS = "CLEAR_REMOTE_RESOURCE_ERRORS";

export const clearRemoteResourceErrors = createActionCreator(
  CLEAR_REMOTE_RESOURCE_ERRORS
);

export const withClearRemoteResourceErrors = createWithActionCreator(
  "clearRemoteResourceErrors",
  clearRemoteResourceErrors
);

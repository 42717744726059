import { gtag } from "../index";

export function getEnvironment() {
  var environmentName = "PROD";
  environmentName = window.location.hostname.toLowerCase().match("-dev");
  if (environmentName != null) {
    environmentName = "DEV";
  } else if (window.location.hostname.includes("172.23.98.199")) {
    environmentName = "SANDBOX";
  } else {
    environmentName = window.location.hostname.toLowerCase().match("-uat");
    if (environmentName != null) {
      environmentName = "UAT";
    } else {
      environmentName = window.location.hostname
        .toLowerCase()
        .match("localhost");
      if (environmentName != null) {
        environmentName = "local";
      } else {
        environmentName = "PROD";
      }
    }
  }
  return environmentName;
}

export function getGaIdEnv(environmentName) {
  let code = "";
  let hotjarSiteId = null;
  switch (environmentName) {
    case "DEV":
      code = "G-LTPLJL1RV4";
      hotjarSiteId = 2771432;
      break;
    case "UAT2":
      code = "G-5VLVEQ3V5Y";
      hotjarSiteId = 2602155;
      break;
    case "UAT":
      code = "G-5VLVEQ3V5Y";
      hotjarSiteId = 2602155;
      break;
    case "local":
      code = "G-LTPLJL1RV4";
      hotjarSiteId = 2602155;
      break;
    case "PROD":
      code = "G-6YB625BYS1";
      hotjarSiteId = 2602155;
      break;
    default:
      break;
  }
  return [code, hotjarSiteId];
}

export function sendGA4Event(
  eventAction,
  eventName,
  eventLabel,
  pageName,
  user_type,
  user_id,
  product_family,
  productModelName
) {
  try {
    gtag("event", eventName, {
      event_action: eventAction,
      event_label: eventLabel,
      page_location: window.location.href,
      page_title: pageName,
      user_type: user_type,
      user_identifier: user_id,
      product_family: product_family,
      product_model_name: productModelName
    });
  } catch (error) {
    console.log("GA4 Error catch block", error);
  }
}

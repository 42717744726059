import createRemoteObjectResourceReducer from "./helpers/createRemoteObjectResourceReducer";

export const user = createRemoteObjectResourceReducer("USER");
export const auth = createRemoteObjectResourceReducer("AUTH");

export const salesforceToSiteIdMap = (state = {}, action) => {
  switch (action.type) {
    case "GET_SITE_IDS_FULFILLED":
      const siteIdObj = {};
      let key;

      action.payload &&
        action.payload.forEach(site => {
          const sfdcSplitedIdsArr = site?.salesforce_site_id?.split("/");
          const marketingName = site?.marketing_name
            ?.split(" ")
            .join("_")
            .toLowerCase();
          if (sfdcSplitedIdsArr?.length > 1) {
            sfdcSplitedIdsArr?.forEach(id => {
              key = `${id}_${marketingName}_${site?.serial_id}`;

              siteIdObj[key] = site?.site_id; //hro site id
            });
          } else if (site.salesforce_site_id === "-") {
            key = `${site?.site_id}_${marketingName}_${site?.serial_id}`; // if sfdc is blank use hro site id
            siteIdObj[key] = site?.site_id;
          } else {
            key = `${site.salesforce_site_id}_${marketingName}_${site?.serial_id}`; // if sfdc is blank use hro site id
            siteIdObj[key] = site?.site_id;
          }
        });
      return {
        ...state,
        value: {
          ...state.value,
          ...siteIdObj
        }
      };
    default:
      return state;
  }
};

import createRemoteArrayResourceReducer from "./helpers/createRemoteArrayResourceReducer";

export const sustainabilityScore = createRemoteArrayResourceReducer(
  "SUSTAINABILITY_SCORE"
);

export const sustainabilityAssetList = createRemoteArrayResourceReducer(
  "SUSTAINABILITY_ASSET"
);

export const sustainabilityAssetInfoList = createRemoteArrayResourceReducer(
  "SUSTAINABILITY_ASSET_INFO"
);

export const sustainabilitySiteList = createRemoteArrayResourceReducer(
  "SUSTAINABILITY_SITE"
);

export const sustainabilityAssetManagerUrl = createRemoteArrayResourceReducer(
  "SUSTAINABILITY_MANAGER"
);

import { call, put, takeEvery } from "redux-saga/effects";
import * as escalatedReportService from "../../services/escalatedReportService";
import {
  GET_ESCALATED_REPORTS_PENDING,
  getEscalatedReportsRejected,
  getEscalatedReportsFulfilled
} from "../actions/escalatedReportActions";
import getResourceError from "./helpers/getResourceError";

export function* getEscalatedReports(action) {
  try {
    const reports = yield call(escalatedReportService.getEscalatedReports);
    yield put(getEscalatedReportsFulfilled(reports));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "ESCALATED_REPORTS",
      "Oops. An error occurred when fetching the list of escalated reports."
    );
    yield put(getEscalatedReportsRejected(resourceError));
  }
}

function* watchGetEscalatedReports() {
  yield takeEvery(GET_ESCALATED_REPORTS_PENDING, getEscalatedReports);
}

export default [watchGetEscalatedReports];

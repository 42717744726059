export const createFulfilledArrayValueReducer = (
  state,
  { payload, meta: { operation, operationState } }
) =>
  operation === "CREATE" && operationState === "FULFILLED"
    ? {
        value: [payload, ...(state.value || [])],
        version: payload.version
      }
    : state;

export const deleteFulfilledArrayValueReducer = (
  state,
  { payload, meta: { operation, operationState } }
) =>
  operation === "DELETE" && operationState === "FULFILLED"
    ? {
        value: state.value.filter(({ id }) => id !== payload.id)
      }
    : state;

export const getFulfilledArrayValueReducer = (
  state,
  { payload, meta: { dataKey, operation, operationState, parentIdKey } }
) => {
  if (operation !== "GET" || operationState !== "FULFILLED") {
    return state;
  }

  const newState = {
    value: (dataKey ? payload[dataKey] : payload) || null,
    version: payload ? payload.version || null : null
  };
  return parentIdKey
    ? { ...newState, [parentIdKey]: payload[parentIdKey] }
    : newState;
};

export const patchFulfilledArrayValueReducer = (
  state,
  { payload, meta: { operation, operationState } }
) =>
  operation === "PATCH" && operationState === "FULFILLED"
    ? {
        value: state.value.map(entity =>
          entity.id === payload.id ? payload : entity
        ),
        version: payload.version
      }
    : state;

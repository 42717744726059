import {
  NOTIFICATION_SEND,
  NOTIFICATION_DISMISS,
  NOTIFICATION_CLEAR
} from "../actions/notificationActions";
import createRemoteObjectResourceReducer from "./helpers/createRemoteObjectResourceReducer";

export const notificationMessages = createRemoteObjectResourceReducer(
  "NOTIFICATION_MESSAGES"
);

export function notifications(domain = [], action) {
  if (!action || !action.type) {
    return domain;
  }

  switch (action.type) {
    case NOTIFICATION_SEND:
      return [
        ...domain.filter(({ id }) => id !== action.payload.id),
        action.payload
      ];
    case NOTIFICATION_DISMISS:
      return domain.filter(notif => notif.id !== action.payload);
    case NOTIFICATION_CLEAR:
      return [];
    default:
      return domain;
  }
}

import { call, put, takeEvery } from "redux-saga/effects";
import * as eulaService from "../../services/eulaService";
import * as userService from "../../services/userService";
import {
  PUT_USER_EULA_RESPONSE_PENDING,
  putUserEulaResponseRejected,
  putUserEulaResponseFulfilled
} from "../actions/eulaActions";
import { getUserFulfilled } from "../actions/userActions";

export function* userEulaResponse(action) {
  try {
    const eulaResponse = yield call(
      eulaService.putUserEulaResponse,
      action.payload
    );
    yield put(putUserEulaResponseFulfilled(action.payload));
    if (action.payload.eula_status === action.payload.eula_version) {
      const user = yield call(userService.getUser);
      yield put(getUserFulfilled(user));
    }
    if (eulaResponse.message !== "Eula Updated") {
      yield put(putUserEulaResponseRejected(action.payload));
    }
  } catch (error) {}
}

function* watchUserEulaResponse() {
  yield takeEvery(PUT_USER_EULA_RESPONSE_PENDING, userEulaResponse);
}

export default [watchUserEulaResponse];

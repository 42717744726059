import api from "./api";
import URLS from "../utils/urls.js";

export const getSustainabilityInfoAsset = () => {
  return api.get(
    URLS.BACKEND_API + `/sustainability/get_score_recommendations`
  );
};

export const getSustainabilityInfoAssetAtAssetLevel = params => {
  return api.get(
    URLS.BACKEND_API +
      `/sustainability/get_score_recommendations?site_id=${params?.site_id}&serial_id=${params?.serial_id}&product_family_id=${params?.product_family_id}`
  );
};

export const updateRenewableEnergy = payload => {
  return api.post(URLS.BACKEND_API + `/sustainability/renewable_energy_site`, {
    ...payload
  });
};

export const addBookmark = newParams => {
  return api.post(
    URLS.BACKEND_API + `/sustainability/bookmark_dismiss_recommendation`,
    {
      ...newParams
    }
  );
};

export const deleteBookmark = newParams => {
  return api.delete(
    URLS.BACKEND_API + `/sustainability/bookmark_dismiss_recommendation`,
    {
      ...newParams
    }
  );
};

export const dismissRecommendation = newParams => {
  return api.post(
    URLS.BACKEND_API + `/sustainability/bookmark_dismiss_recommendation`,
    {
      ...newParams
    }
  );
};

export const getAssetManagerUrl = (serialNumber, siteId, productFamilyId) => {
  const params = {
    site_id: siteId,
    serial_id: serialNumber,
    product_family_id: productFamilyId
  };

  return api.get(`${URLS.BACKEND_API}/sustainability/asset_manager_url`, {
    params
  });
};

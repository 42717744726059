import { call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import * as assetService from "../../services/assetService";
import * as sustainabilityService from "../../services/sustainabilityService";
import * as userService from "../../services/userService";
import _ from "lodash";
import moment from "moment";
import {
  CREATE_ASSET_PENDING,
  GET_ASSETS_PENDING,
  GET_FILTERED_ASSETS_PENDING,
  GET_ACTIVE_ASSETS_PENDING,
  GET_ASSETS_THRESHOLD_PENDING,
  GET_ENTITLEMENTS_PENDING,
  GET_ASSETS_RESET,
  GET_ASSET_RESET,
  GET_ASSET_PENDING,
  GET_ASSET_DETAILS_PENDING,
  GET_HCP_ASSET_DETAILS_PENDING,
  GET_HNAS_ASSET_DETAILS_PENDING,
  getHNASAssetDetailsFulfilled,
  getHCPAssetDetailsFulfilled,
  getHCPAssetDetailsRejected,
  GET_ASSET_DETAILS_RESET_PENDING,
  GET_ASSET_GRAPH_PENDING,
  GET_ANALYTICS_PENDING,
  PATCH_ASSET_PENDING,
  PATCH_USER_PREFERENCE_PENDING,
  PATCH_ASSET_TO_FAVORITES_PENDING,
  GET_INTUSER_ASSETS_PENDING,
  DELETE_REMOVE_ASSETS_PENDING,
  CREATE_ADD_ASSETS_PENDING,
  CREATE_ADD_ASSETS_ACTION_RESET_PENDING,
  DELETE_REMOVE_ASSETS_ACTION_RESET_PENDING,
  CREATE_GET_ASSETS_REPORT_CSV_PENDING,
  CREATE_GET_ASSETS_REPORT_CSV_ACTION_RESET_PENDING,
  GET_CLOUD_CONNECTION_PENDING,
  GET_ORG_DETAILS_PENDING,
  GET_CREDENTIALS_DETAILS_PENDING,
  CREATE_CREDENTIALS_DETAILS_PENDING,
  GET_PERFORMANCE_DETAILS_PENDING,
  GET_GRAPH_RANGE_DETAILS_PENDING,
  GET_CLOUD_CONNECTION_NEXT_PENDING,
  postAssetRejected,
  postAssetFulfilled,
  getAssetsRejected,
  getAssetsPending,
  getAssetsFulfilled,
  getAssetsThresholdFulfilled,
  getEntitlementsRejected,
  getEntitlementsFulfilled,
  getAssetRejected,
  getAssetFulfilled,
  getAssetDetailsFulfilled,
  getAssetDetailsResetFulfilled,
  getAssetGraphFulfilled,
  getAssetDetailsRejected,
  getAnalyticsFulfilled,
  getAnalyticsRejected,
  patchAssetFulfilled,
  patchAssetRejected,
  patchUserPreferenceFulfilled,
  patchUserPreferenceRejected,
  patchAssetToFavouritesFulfilled,
  patchAssetToFavouritesRejected,
  getIntUserAssetsFulfilled,
  getIntUserAssetsRejected,
  removeAssetsFulfilled,
  addAssetsFulfilled,
  getAssetsReportCSVFulfilled,
  removeAssetsRejected,
  addAssetsRejected,
  getAssetsReportCSVRejected,
  getCloudConnectionFulfilled,
  getCloudConnectionRejected,
  getOrgDetailsFulfilled,
  getOrgDetailsRejected,
  getPerformanceDetailsFulfilled,
  getPerformanceDetailsRejected,
  getCredentialsDetailsFulfilled,
  createCredentialsDetailsFulfilled,
  getCredentialsDetailsRejected,
  createCredentialsDetailsRejected,
  getApiGetwayInfoFulfilled,
  getApiGetwayInfoRejected,
  getGraphRangeDetailsFulfilled,
  getGraphRangeDetailsRejected,
  GET_PERFORMACE_APPROVAL_PENDING,
  getPerformanceApprovalRejected,
  getPerformanceApprovalFulfilled,
  GET_PERFORMANCE_GRAPH_PENDING,
  GET_SELECTED_ASSET_PENDING,
  getSelectedAssetFulfilled,
  getPerformanceDetailsResetFulfilled,
  getGraphRangeDetailsResetFulfilled,
  GET_PERFORMANCE_DETAILS_RESET_PENDING,
  GET_GRAPH_RANGE_DETAILS_RESET_PENDING,
  GET_API_GATEWAY_INFO_PENDING,
  UPDATE_WHITELIST_IP_PENDING,
  updateWhitelistIpFulfilled,
  updateWhitelistIpRejected,
  GET_LUN_HOST_GROUP_DETAILS_PENDING,
  getLunHostGroupDetailsFulfilled,
  getLunHostGroupDetailsRejected,
  getFilteredAssetsFulfilled,
  getActiveAssetsFulfilled
} from "../actions/assetActions";
import { getSiteIdsFulfilled, getUserFulfilled } from "../actions/userActions";
import {
  getSustainabilityAssetInfoFulfilled,
  getSustainabilityAssetInfoRejected
} from "../actions/sustainabilityAction";
import getResourceError from "./helpers/getResourceError";
import { notificationSend } from "../actions/notificationActions";
import { getEscalatedReportsPending } from "../actions/escalatedReportActions";
import { CONST_VALUES } from "../../constants/constants";
import { omit, get } from "lodash";
import { handleLoader } from "../actions/loaderAction";
import { validateLocalStorageValue } from "../../utils/clearsightUtils";

export function* getAssets(action) {
  let { params, site_info } = action.payload;
  try {
    if (get(params, "getUserData")) {
      params = omit(params, "getUserData");
      const user = yield call(userService.getUser);
      yield put(getUserFulfilled(user));
      if (user) {
        yield put(getSiteIdsFulfilled(user.site_info));
      }
    }
    const assets = yield call(assetService.getAssets, params, site_info);
    var product_category_id =
      action.payload &&
      action.payload.params &&
      action.payload.params.product_category_id;
    const allAssets =
      (assets &&
        assets.data &&
        assets.message !== CONST_VALUES.Error &&
        assets.message !== "No Favorite Assets" &&
        Object.values(assets.data).flat()) ||
      [];
    const assetsThreshold = !!assets
      ? assets.threshold
      : {
          Critical: "90",
          Warning: "80"
        };
    yield put(getAssetsFulfilled(allAssets));
    yield put(getAssetsThresholdFulfilled(assetsThreshold));
    if (allAssets && allAssets.length > 0) {
      const entitlementInfo = yield call(
        assetService.getEntitlementInfo,
        params,
        product_category_id
      );
      yield put(getEntitlementsFulfilled(entitlementInfo));
    }
  } catch (error) {
    const assetResourceError = yield call(
      getResourceError,
      error,
      "GET",
      "ASSETS",
      "Oops. An error occurred when fetching the list of assets."
    );
    const entitlementResourceError = yield call(
      getResourceError,
      error,
      "GET",
      "ENTITLEMENTS",
      "Oops. An error occurred when fetching the list of entitlements."
    );
    yield put(getAssetsRejected(assetResourceError));
    yield put(getEntitlementsRejected(entitlementResourceError));
  }
}

export function* getActiveAssets(action) {
  let { assets } = action.payload;
  yield put(handleLoader(true));
  const todayDate = moment().format("YYYY-MM-DD");
  const activeAssets = assets?.filter(
    el => moment(todayDate).diff(moment(el.report_date), "days") < 30
  );
  yield put(getActiveAssetsFulfilled(activeAssets));
  yield put(handleLoader(false));
}

export function* getFilteredAssets(action) {
  let {
    assets,
    entitlements,
    selectedFilterValues,
    storageOption
  } = action.payload;
  yield put(handleLoader(true));
  const selectedSite = selectedFilterValues.filter(el => el.category == "site");
  const selectedModel = selectedFilterValues.filter(
    el => el.category == "model"
  );
  const selectedStatus = selectedFilterValues.filter(
    el => el.category == "status"
  );
  const selectedVersion = selectedFilterValues.filter(
    el => el.category == "microcode"
  );
  const selectedSupportLevel = selectedFilterValues.filter(
    el => el.category == "support_level"
  );

  let selectedSiteAssets = assets.filter(item => {
    return selectedSite.some(filter => {
      return item["siteId"] === filter.value;
    });
  });

  let selectedModelAssets = assets.filter(item => {
    return selectedModel.some(filter => {
      return item["model"] === filter.value;
    });
  });

  let selectedStatusAssets = assets.filter(item => {
    return selectedStatus.some(filter => {
      return storageOption === "block"
        ? item["microcodeStatus"] === filter.value
        : item["version_status"] === filter.value;
    });
  });

  let selectedVersionAssets = assets.filter(item => {
    return selectedVersion.some(filter => {
      return storageOption === "block"
        ? item["microcode"] === filter.value
        : item["software_version"] === filter.value;
    });
  });

  let selectedSupportLevelAssets = assets.filter(item => {
    return selectedSupportLevel.some(filter => {
      return (
        entitlements[
          `${item.siteId}~${item.serialNumber}~${item.product_family_id}`
        ]?.support_level === filter.value && item
      );
    });
  });

  let updatedAssets = _.intersection(
    selectedSiteAssets?.length > 0 ? selectedSiteAssets : assets,
    selectedModelAssets?.length > 0 ? selectedModelAssets : assets,
    selectedStatusAssets?.length > 0 ? selectedStatusAssets : assets,
    selectedVersionAssets?.length > 0 ? selectedVersionAssets : assets,
    selectedSupportLevelAssets?.length > 0 ? selectedSupportLevelAssets : assets
  );

  yield put(getFilteredAssetsFulfilled(updatedAssets));
  yield put(handleLoader(false));
}

// Get assets for Internal User
export function* getAssetsForIntUser(action) {
  let { params } = action.payload;
  const productCategoryId = params.productCategoryId;
  let isAssetInfoUrl = window?.location?.hash.indexOf("asset-info") > -1;

  const type = params.type;
  try {
    if (get(params, "getUserData")) {
      // yield put(handleLoader(true));
      params = omit(params, "getUserData");
      const user = yield call(userService.getUser);
      if (user?.id == 0) {
        window.location.href = "/";
      }
      yield put(getUserFulfilled(user));
      if (user) {
        yield put(getSiteIdsFulfilled(user.site_info));
      }
      params["allsites"] = [];
      params["allsites"] = user.listOfSites.map(category =>
        category.sites.flat(1).map(item => item.site_id)
      );

      params["product_category_id"] =
        type === "add-asset"
          ? productCategoryId && productCategoryId.toString()
          : localStorage.getItem("storageOption") === "block"
          ? "1"
          : localStorage.getItem("storageOption") === "object"
          ? "2"
          : "3";
      if (type === "add-asset") {
        if (
          validateLocalStorageValue(localStorage.getItem("storageOption")) !==
            -1 &&
          productCategoryId
        ) {
          switch (productCategoryId.toString()) {
            case "1":
              localStorage.setItem("storageOption", "block");
              break;
            case "2":
              localStorage.setItem("storageOption", "object");
              break;
            case "3":
              localStorage.setItem("storageOption", "file");
              break;
            default:
              localStorage.setItem("storageOption", "block");
              break;
          }
        }
      }

      params["allsites"] =
        "[" +
        String(params["allsites"])
          .split(",")
          .map(s => `'${s}'`)
          .join(",") +
        "]";

      if (isAssetInfoUrl) {
        const locationArray = window?.location?.hash?.split("/");
        let assetInfoKeyIndex = locationArray.indexOf("asset-info");
        const serialId = locationArray[assetInfoKeyIndex + 1];
        const hitrackSiteId = locationArray[assetInfoKeyIndex + 2];
        let modelName = "";
        let siteInfo = {};
        for (
          var index = assetInfoKeyIndex + 3;
          index < locationArray.length;
          index++
        ) {
          modelName += locationArray[index];
        }
        let adjustedModelName = modelName.replace(/%2F/g, "/");
        adjustedModelName = decodeURIComponent(adjustedModelName);

        siteInfo = user?.site_info?.find(
          el =>
            (el.serial_id?.toUpperCase() === serialId?.toUpperCase() ||
              el.cluster_nodes.includes(serialId)) &&
            el.display_model_name?.toUpperCase() ===
              adjustedModelName?.toUpperCase() &&
            el.site_id?.toUpperCase() === hitrackSiteId?.toUpperCase()
        );
        params["site_id"] = siteInfo?.site_id;
        params["serial_id"] = siteInfo?.serial_id;
        params["product_family_id"] = siteInfo?.product_family_id;
        params["productCategoryId"] = siteInfo?.product_category_id;
      }
    }

    const assets = yield call(assetService.getAssetsForIntUser, params);
    const allAssets =
      (assets &&
        assets.data &&
        assets.message !== CONST_VALUES.Error &&
        Object.values(assets.data).flat()) ||
      [];
    const assetsThreshold = !!assets
      ? assets.threshold
      : {
          Critical: "90",
          Warning: "80"
        };
    yield put(getIntUserAssetsFulfilled(allAssets));
    const entitlementInfo = yield call(assetService.getEntitlementInfo, params);
    yield put(getAssetsThresholdFulfilled(assetsThreshold));
    yield put(getEntitlementsFulfilled(entitlementInfo));
    // yield put(handleLoader(false));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "INTUSERASSETS",
      "Oops. An error occurred when fetching the list of assets."
    );
    yield put(getIntUserAssetsRejected(resourceError));
  }
}

export function* resetAssets(action) {
  yield put(getAssetsFulfilled(action.payload));
  yield put(getEntitlementsFulfilled(action.payload));
}

export function* resetAsset(action) {
  yield put(getAssetFulfilled(action.payload));
}

export function* getAsset(action) {
  const { serialNumber, siteId, productFamilyId, params } = action.payload;
  try {
    const asset = yield call(
      assetService.getAsset,
      serialNumber,
      siteId,
      productFamilyId,
      params
    );
    yield put(getAssetFulfilled(asset));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "ASSET",
      "Oops. An error occurred when fetching the asset."
    );
    yield put(getAssetFulfilled(null));
    yield put(getAssetRejected(resourceError));
  }
}

function* getStorageCapacity(serialNumber, site, site_info, noAccessMsg) {
  try {
    const capacity = yield call(
      assetService.getStorageCapacity,
      serialNumber,
      site,
      site_info
    );
    yield put(
      getHCPAssetDetailsFulfilled({
        data: capacity,
        detailType: "HCPStorageCapacity"
      })
    );
  } catch (error) {
    yield put(
      getHCPAssetDetailsFulfilled({
        data: null,
        detailType: "HCPStorageCapacity"
      })
    );
    yield put(
      getHCPAssetDetailsFulfilled({
        data: null,
        detailType: "HCPStorageCapacity"
      })
    );
  }
}
function* getHNASStorageCapacity(serialNumber, site, site_info, noAccessMsg) {
  try {
    const capacity = yield call(
      assetService.getStorageCapacity,
      serialNumber,
      site,
      site_info
    );
    yield put(
      getHNASAssetDetailsFulfilled({
        data: capacity,
        detailType: "HNASStorageCapacity"
      })
    );
  } catch (error) {
    yield put(
      getHNASAssetDetailsFulfilled({
        data: null,
        detailType: "HNASStorageCapacity"
      })
    );
    yield put(
      getHNASAssetDetailsFulfilled({
        data: null,
        detailType: "HNASStorageCapacity"
      })
    );
  }
}

function* getHNASCapacityHistoryData(
  serialNumber,
  site,
  site_info,
  noAccessMsg
) {
  try {
    const capacity = yield call(
      assetService.getHNASCapacityHistory,
      serialNumber,
      site,
      site_info
    );
    if (
      capacity.hasOwnProperty("message") &&
      capacity?.message.toLowerCase() === "error"
    ) {
      yield put(
        getHNASAssetDetailsFulfilled({
          data: null,
          detailType: "CapacityHistory"
        })
      );
    } else {
      yield put(
        getHNASAssetDetailsFulfilled({
          data: capacity,
          detailType: "CapacityHistory"
        })
      );
    }
  } catch (error) {
    // yield put(
    //   getHNASCapacityHistoryDataFulfilled({
    //     data: null,
    //     detailType: "CapacityHistory"
    //   })
    // );
    // yield put(
    //   getHNASCapacityHistoryDataFulfilled({
    //     data: null,
    //     detailType: "CapacityHistory"
    //   })
    // );
    getHNASAssetDetailsFulfilled({
      data: null,
      detailType: "CapacityHistory"
    });

    yield put(
      getHNASAssetDetailsFulfilled({
        data: null,
        detailType: "CapacityHistory"
      })
    );
  }
}

function* getHNASFileStorageNodeData(
  serialNumber,
  site,
  site_info,
  noAccessMsg
) {
  try {
    const nodes = yield call(
      assetService.getHNASFileStorageNodeDetails,
      serialNumber,
      site,
      site_info
    );
    if (
      nodes.hasOwnProperty("message") &&
      nodes?.message.toLowerCase() === "error"
    ) {
      yield put(
        getHNASAssetDetailsFulfilled({
          data: null,
          detailType: "Nodes"
        })
      );
    } else {
      yield put(
        getHNASAssetDetailsFulfilled({
          data: nodes,
          detailType: "Nodes"
        })
      );
    }
  } catch (error) {
    getHNASAssetDetailsFulfilled({
      data: null,
      detailType: "Nodes"
    });

    yield put(
      getHNASAssetDetailsFulfilled({
        data: null,
        detailType: "Nodes"
      })
    );
  }
}

function* getHNASFileStorageSpanData(
  serialNumber,
  site,
  site_info,
  noAccessMsg
) {
  try {
    const spans = yield call(
      assetService.getHNASFileStorageSpanDetails,
      serialNumber,
      site,
      site_info
    );
    if (
      spans.hasOwnProperty("message") &&
      spans?.message.toLowerCase() === "error"
    ) {
      yield put(
        getHNASAssetDetailsFulfilled({
          data: null,
          detailType: "Spans"
        })
      );
    } else {
      yield put(
        getHNASAssetDetailsFulfilled({
          data: spans,
          detailType: "Spans"
        })
      );
    }
  } catch (error) {
    getHNASAssetDetailsFulfilled({
      data: null,
      detailType: "Spans"
    });
    yield put(
      getHNASAssetDetailsFulfilled({
        data: null,
        detailType: "Spans"
      })
    );
  }
}

function* internalDriveSummaryInfo(serialNumber, site, site_info, noAccessMsg) {
  try {
    const capacity = yield call(
      assetService.getInternalDriveSummaryInfo,
      serialNumber,
      site,
      site_info
    );
    yield put(
      getHCPAssetDetailsFulfilled({
        data: capacity,
        detailType: "InternalDriveSummary"
      })
    );
  } catch (error) {
    yield put(
      getHCPAssetDetailsFulfilled({
        data: null,
        detailType: "InternalDriveSummary"
      })
    );
    yield put(
      getHCPAssetDetailsFulfilled({
        data: null,
        detailType: "InternalDriveSummary"
      })
    );
  }
}

function* getMetricesDetails(site, serialNumber, site_info, unit, noAccessMsg) {
  try {
    const capacity = yield call(
      assetService.getMetricesDetails,
      serialNumber,
      site,
      site_info,
      unit
    );
    yield put(
      getHCPAssetDetailsFulfilled({
        data: capacity,
        detailType: "HCPMetricesDetails"
      })
    );
  } catch (error) {
    yield put(
      getHCPAssetDetailsFulfilled({
        data: null,
        detailType: "HCPMetricesDetails"
      })
    );
    yield put(
      getHCPAssetDetailsFulfilled({
        data: null,
        detailType: "HCPMetricesDetails"
      })
    );
  }
}

function* getStorageComponentsInfo(site, serialNumber, site_info, noAccessMsg) {
  try {
    const capacity = yield call(
      assetService.getStorageComponentDetails,
      serialNumber,
      site,
      site_info
    );
    yield put(
      getHCPAssetDetailsFulfilled({
        data: capacity,
        detailType: "StorageComponentsDetails"
      })
    );
  } catch (error) {
    yield put(
      getHCPAssetDetailsFulfilled({
        data: null,
        detailType: "StorageComponentsDetails"
      })
    );
    yield put(
      getHCPAssetDetailsFulfilled({
        data: null,
        detailType: "StorageComponentsDetails"
      })
    );
  }
}

function* getNodesSummaryDetails(site, serialNumber, site_info, noAccessMsg) {
  try {
    const capacity = yield call(
      assetService.getNodesSummaryDetails,
      serialNumber,
      site,
      site_info
    );
    yield put(
      getHCPAssetDetailsFulfilled({
        data: capacity,
        detailType: "NodesSummaryDetails"
      })
    );
  } catch (error) {
    yield put(
      getHCPAssetDetailsFulfilled({
        data: null,
        detailType: "NodesSummaryDetails"
      })
    );
    yield put(
      getHCPAssetDetailsFulfilled({
        data: null,
        detailType: "NodesSummaryDetails"
      })
    );
  }
}

function* getAssetCapacity(serialNumber, params, site, site_info, noAccessMsg) {
  try {
    const capacity = yield call(
      assetService.getPhysicalPoolCapacity,
      serialNumber,
      params,
      site,
      site_info
    );
    if (
      capacity === null ||
      (capacity.hasOwnProperty("message") && noAccessMsg[capacity.message])
    ) {
      yield put(
        getAssetDetailsFulfilled({ data: capacity, detailType: "poolCapacity" })
      );
      yield put(
        getAssetDetailsFulfilled({
          data: capacity,
          detailType: "parityGroupCapacity"
        })
      );
    } else {
      if (capacity.hasOwnProperty("pool_capacity")) {
        yield put(
          getAssetDetailsFulfilled({
            data: capacity.pool_capacity,
            detailType: "poolCapacity"
          })
        );
      } else {
        yield put(
          getAssetDetailsFulfilled({ data: null, detailType: "poolCapacity" })
        );
      }
      if (capacity.hasOwnProperty("parity_capacity")) {
        yield put(
          getAssetDetailsFulfilled({
            data: capacity.parity_capacity,
            detailType: "parityGroupCapacity"
          })
        );
      } else {
        yield put(
          getAssetDetailsFulfilled({
            data: null,
            detailType: "parityGroupCapacity"
          })
        );
      }
    }
  } catch (error) {
    yield put(
      getAssetDetailsFulfilled({
        data: null,
        detailType: "parityGroupCapacity"
      })
    );
    yield put(
      getAssetDetailsFulfilled({ data: null, detailType: "poolCapacity" })
    );
  }
}

function* getAssetTempAndPower(
  serialNumber,
  params,
  site,
  noAccessMsg,
  site_info
) {
  try {
    if (noAccessMsg[site_info.model_name]) {
      yield put(
        getAssetDetailsFulfilled({
          data: { message: "No Access" },
          detailType: "Temperature"
        })
      );
      yield put(
        getAssetDetailsFulfilled({
          data: { message: "No Access" },
          detailType: "Power"
        })
      );
    } else {
      const tempAndPower = yield call(
        assetService.getPowerAndTemperature,
        serialNumber,
        params,
        site,
        site_info
      );
      if (
        tempAndPower &&
        tempAndPower.hasOwnProperty("message") &&
        noAccessMsg[tempAndPower.message]
      ) {
        yield put(
          getAssetDetailsFulfilled({
            data: tempAndPower,
            detailType: "Temperature"
          })
        );
        yield put(
          getAssetDetailsFulfilled({ data: tempAndPower, detailType: "Power" })
        );
      } else {
        if (tempAndPower && tempAndPower.hasOwnProperty("temperatureTrend")) {
          yield put(
            getAssetDetailsFulfilled({
              data: tempAndPower.temperatureTrend,
              detailType: "Temperature"
            })
          );
        } else {
          yield put(
            getAssetDetailsFulfilled({ data: null, detailType: "Temperature" })
          );
        }

        if (
          tempAndPower &&
          tempAndPower.hasOwnProperty("powerConsumptionTrend")
        ) {
          yield put(
            getAssetDetailsFulfilled({
              data: tempAndPower.powerConsumptionTrend,
              detailType: "Power"
            })
          );
        } else {
          yield put(
            getAssetDetailsFulfilled({ data: null, detailType: "Power" })
          );
        }

        if (tempAndPower && tempAndPower.hasOwnProperty("serialNumber")) {
          yield put(
            getAssetDetailsFulfilled({
              data: tempAndPower.serialNumber,
              detailType: "serialNumber"
            })
          );
        } else {
          yield put(
            getAssetDetailsFulfilled({ data: null, detailType: "serialNumber" })
          );
        }
      }
    }
  } catch (error) {
    yield put(getAssetDetailsFulfilled({ data: null, detailType: "Power" }));
    yield put(
      getAssetDetailsFulfilled({ data: null, detailType: "Temperature" })
    );
  }
}

function* getSustainabilityDetailsAtAssetLevel(site_info) {
  try {
    const assetDetails = yield call(
      sustainabilityService.getSustainabilityInfoAssetAtAssetLevel,
      site_info
    );
    yield put(
      getSustainabilityAssetInfoFulfilled({
        data: assetDetails
      })
    );
  } catch (error) {
    yield put(
      getSustainabilityAssetInfoRejected({
        data: null
      })
    );
  }
}

function* getAssetStorageTrend(
  serialNumber,
  params,
  site,
  site_info,
  noAccessMsg
) {
  try {
    const storage = yield call(
      assetService.getStorageTrend,
      serialNumber,
      params,
      site,
      site_info
    );
    yield put(
      getAssetDetailsFulfilled({
        data: storage,
        detailType: "UsedCapacityUsageTrend"
      })
    );
  } catch (error) {
    yield put(
      getAssetDetailsFulfilled({
        data: null,
        detailType: "UsedCapacityUsageTrend"
      })
    );
  }
}

function* getAssetDiskDriveBreakdown(serialNumber, params, site, site_info) {
  try {
    let diskdrive = yield call(
      assetService.getDiskDriveBreakdown,
      serialNumber,
      params,
      site,
      site_info
    );

    diskdrive = diskdrive ? diskdrive : { driveCount: [] };

    let diskDrive = {
      ssdCount: "",
      spareSsdCount: "",
      ssdTotal: "",
      hddCount: "",
      spareHddCount: "",
      hddTotal: "",
      fmdCount: "",
      spareFmdCount: "",
      fmdTotal: "",
      nvmeCount: "",
      spareNvmeCount: "",
      nvmeTotal: "",
      totalRawCapacity: "",
      unit: ""
    };
    if (Array.isArray(diskdrive.driveCount)) {
      diskdrive.driveCount.forEach(x => {
        switch (x.type) {
          case CONST_VALUES.ssd:
            diskDrive.ssdCount = x.count;
            diskDrive.spareSsdCount = x.spareCount;
            diskDrive.ssdTotal = x.total;
            break;
          case CONST_VALUES.hdd:
            diskDrive.hddCount = x.count;
            diskDrive.spareHddCount = x.spareCount;
            diskDrive.hddTotal = x.total;
            break;
          case CONST_VALUES.fmd:
            diskDrive.fmdCount = x.count;
            diskDrive.spareFmdCount = x.spareCount;
            diskDrive.fmdTotal = x.total;
            break;
          case CONST_VALUES.nvme:
            diskDrive.nvmeCount = x.count;
            diskDrive.spareNvmeCount = x.spareCount;
            diskDrive.nvmeTotal = x.total;
            break;
          default:
            break;
        }
      });
      diskDrive.totalRawCapacity = diskdrive.total_raw_capacity;
      diskDrive.unit = diskdrive.unit;
    } else if (diskdrive.message === CONST_VALUES.Error) {
      throw new Error(diskdrive.message);
    } else {
      diskDrive = diskdrive;
    }
    yield put(
      getAssetDetailsFulfilled({
        data: diskDrive,
        detailType: "diskDriveBreakdown"
      })
    );
  } catch (error) {
    yield put(
      getAssetDetailsFulfilled({
        data: { diskDriveBreakdownError: "Error" },
        detailType: "diskDriveBreakdown"
      })
    );
  }
}
function* getAssetUsedAvailableCapcity(serialNumber, params, site, site_info) {
  try {
    const usedAvailableCapacity = yield call(
      assetService.getUsedAllocatedCapcity,
      serialNumber,
      params,
      site,
      site_info
    );
    yield put(
      getAssetDetailsFulfilled({
        data: usedAvailableCapacity,
        detailType: "Capacity"
      })
    );
  } catch (error) {
    yield put(
      getAssetDetailsFulfilled({
        data: null,
        detailType: "Capacity"
      })
    );
  }
}

function* showNotification(message) {
  yield put(
    notificationSend({
      id: new Date(),
      label: message,
      variant: "error",
      dismissAfter: 5000
    })
  );
}

function* getAssetVolumeIOPs(serialNumber, params, site) {
  try {
    const volumeIOPsData = yield call(
      assetService.getAssetGraphForVolumeIOPs,
      serialNumber,
      params
    );
    yield put(
      getPerformanceDetailsFulfilled({
        data: volumeIOPsData,
        detailType: "VolumeIOPs"
      })
    );
  } catch (error) {
    yield put(
      getPerformanceDetailsFulfilled({
        data: null,
        detailType: "VolumeIOPs"
      })
    );
  }
}
function* getAssetVolumeResponseTime(serialNumber, params, site) {
  try {
    const volumeResponseTimeData = yield call(
      assetService.getAssetGraphForVolumeResponseTime,
      serialNumber,
      params
    );
    yield put(
      getPerformanceDetailsFulfilled({
        data: volumeResponseTimeData,
        detailType: "VolumeResponseTime"
      })
    );
  } catch (error) {
    yield put(
      getPerformanceDetailsFulfilled({
        data: null,
        detailType: "VolumeResponseTime"
      })
    );
  }
}
function* getAssetVolumeTransferRate(serialNumber, params, site) {
  try {
    const volumeTransferRateData = yield call(
      assetService.getAssetGraphForVolumeTransferRate,
      serialNumber,
      params
    );
    yield put(
      getPerformanceDetailsFulfilled({
        data: volumeTransferRateData,
        detailType: "VolumeTransferRate"
      })
    );
  } catch (error) {
    yield put(
      getPerformanceDetailsFulfilled({
        data: null,
        detailType: "VolumeTransferRate"
      })
    );
  }
}
function* getAssetProcessorBusyRate(serialNumber, params) {
  try {
    const processorBusyRateData = yield call(
      assetService.getAssetGraphForProcessorBusyRate,
      serialNumber,
      params
    );
    if (processorBusyRateData.status_code === CONST_VALUES.status_code_503) {
      yield call(showNotification, processorBusyRateData.message);
    }
    yield put(
      getPerformanceDetailsFulfilled({
        data: processorBusyRateData,
        detailType: "ProcessorBusyRate"
      })
    );
  } catch (error) {
    yield put(
      getPerformanceDetailsFulfilled({
        data: null,
        detailType: "ProcessorBusyRate"
      })
    );
  }
}

function* getAssetOperationPerSecond(params) {
  try {
    const operationPerSecond = yield call(
      assetService.getAssetGraphFoOperationPerSecond,
      params
    );
    yield put(
      getPerformanceDetailsFulfilled({
        data: operationPerSecond,
        detailType: "OperationPerSecond"
      })
    );
  } catch (error) {
    yield put(
      getPerformanceDetailsFulfilled({
        data: null,
        detailType: "OperationPerSecond"
      })
    );
  }
}

function* getAssetServerUtilization(params) {
  try {
    const serverUtilization = yield call(
      assetService.getAssetGraphForServerUtilization,
      params
    );
    yield put(
      getPerformanceDetailsFulfilled({
        data: serverUtilization,
        detailType: "ServerUtilization"
      })
    );
  } catch (error) {
    yield put(
      getPerformanceDetailsFulfilled({
        data: null,
        detailType: "ServerUtilization"
      })
    );
  }
}

function* getAssetDiskLatency(params) {
  try {
    const diskLatency = yield call(
      assetService.getAssetGraphForDiskLatency,
      params
    );
    yield put(
      getPerformanceDetailsFulfilled({
        data: diskLatency,
        detailType: "DiskLatency"
      })
    );
  } catch (error) {
    yield put(
      getPerformanceDetailsFulfilled({
        data: null,
        detailType: "DiskLatency"
      })
    );
  }
}

function* getAssetEthernetThroughput(params) {
  try {
    const ethernetThroughput = yield call(
      assetService.getAssetGraphForEthernetThroughput,
      params
    );
    yield put(
      getPerformanceDetailsFulfilled({
        data: ethernetThroughput,
        detailType: "EthernetThroughput"
      })
    );
  } catch (error) {
    yield put(
      getPerformanceDetailsFulfilled({
        data: null,
        detailType: "EthernetThroughput"
      })
    );
  }
}

function* getAssetCacheUsageRate(serialNumber, params, site) {
  try {
    const cacheUsageRateData = yield call(
      assetService.getAssetGraphForCacheUsageRate,
      serialNumber,
      params
    );
    if (cacheUsageRateData.status_code === CONST_VALUES.status_code_503) {
      yield call(showNotification, cacheUsageRateData.message);
    }
    yield put(
      getPerformanceDetailsFulfilled({
        data: cacheUsageRateData,
        detailType: "CacheUsageRate"
      })
    );
  } catch (error) {
    yield put(
      getPerformanceDetailsFulfilled({
        data: null,
        detailType: "CacheUsageRate"
      })
    );
  }
}

function* getAssetCacheWritePendingRate(serialNumber, params, site) {
  try {
    const cacheWritePendingRateData = yield call(
      assetService.getAssetGraphForCacheWritePendingRate,
      serialNumber,
      params
    );
    if (
      cacheWritePendingRateData.status_code === CONST_VALUES.status_code_503
    ) {
      yield call(showNotification, cacheWritePendingRateData.message);
    }
    yield put(
      getPerformanceDetailsFulfilled({
        data: cacheWritePendingRateData,
        detailType: "CacheWritePendingRate"
      })
    );
  } catch (error) {
    yield put(
      getPerformanceDetailsFulfilled({
        data: null,
        detailType: "CacheWritePendingRate"
      })
    );
  }
}

export function* getHCPAssetDetails(action) {
  const { serialNumber, params, site, noAccessMsg, site_info } = action.payload;
  try {
    if (site_info.product_family.indexOf("HCP-S") < 0) {
      yield fork(getStorageComponentsInfo, serialNumber, site, site_info);
      yield fork(getNodesSummaryDetails, serialNumber, site, site_info);
    }

    if (site_info.product_family.indexOf("HCP-S") > -1) {
      yield fork(getMetricesDetails, site, serialNumber, site_info, "TiB");
    }
    yield fork(getStorageCapacity, serialNumber, site, site_info);
    yield fork(internalDriveSummaryInfo, serialNumber, site, site_info);
    yield fork(
      getAssetUsedAvailableCapcity,
      serialNumber,
      { range: params.range },
      site,
      site_info
    );

    yield fork(
      getAssetStorageTrend,
      serialNumber,
      { range: params.range },
      site,
      site_info,
      noAccessMsg
    );

    site_info?.product_family !== "VSSB" &&
      (yield fork(
        getAssetTempAndPower,
        serialNumber,
        params,
        site,
        noAccessMsg,
        site_info
      ));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "HCP_ASSET_DETAILS",
      "Oops. An error occurred when fetching the asset."
    );
    yield put(getHCPAssetDetailsFulfilled(null));
    yield put(getHCPAssetDetailsRejected(resourceError));
  }
}

export function* getHNASAssetDetails(action) {
  const { serialNumber, site, site_info } = action.payload;
  try {
    yield fork(getHNASStorageCapacity, serialNumber, site, site_info);
    yield fork(getHNASCapacityHistoryData, serialNumber, site, site_info);
    yield fork(getHNASFileStorageNodeData, serialNumber, site, site_info);
    yield fork(getHNASFileStorageSpanData, serialNumber, site, site_info);
  } catch (error) {
    // yield put(getHNASAssetDetailsFulfilled(null));
    // yield put(getHNASAssetDetailsRejected(resourceError));
  }
}

export function* getAssetDetails(action) {
  const {
    serialNumber,
    params,
    site,
    noAccessMsg,
    site_info,
    userRole
  } = action.payload;
  try {
    yield fork(
      getAssetCapacity,
      serialNumber,
      params,
      site,
      site_info,
      noAccessMsg
    );
    yield fork(internalDriveSummaryInfo, serialNumber, site, site_info);
    yield fork(
      getAssetUsedAvailableCapcity,
      serialNumber,
      { range: params.range },
      site,
      site_info
    );
    site_info?.product_family !== "VSSB" &&
      (yield fork(
        getAssetTempAndPower,
        serialNumber,
        params,
        site,
        noAccessMsg,
        site_info
      ));
    yield fork(
      getAssetStorageTrend,
      serialNumber,
      { range: params.range },
      site,
      site_info,
      noAccessMsg
    );
    if (userRole?.toLowerCase() == "customer") {
      yield fork(getSustainabilityDetailsAtAssetLevel, site_info);
    }

    // let payload = {
    //   siteId: site,
    //   serialNumber: serialNumber,
    //   product_family_id: site_info?.product_family_id
    // };
    // yield fork(getLunHostGroupDetails, { payload });
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "ASSET_DETAILS",
      "Oops. An error occurred when fetching the asset."
    );
    yield put(getAssetDetailsFulfilled(null));
    yield put(getAssetDetailsRejected(resourceError));
  }
}

export function* getAssetDetailsReset(action) {
  yield put(getAssetDetailsResetFulfilled(action));
}

export function* getPerformanceDetailsReset(action) {
  yield put(getPerformanceDetailsResetFulfilled(action));
}

export function* getGraphRangeDetailsReset(action) {
  yield put(getGraphRangeDetailsResetFulfilled(action));
}

export function* getAssetGraph(action) {
  const { serialNumber, params, productFamily, site_info } = action.payload;
  try {
    var asset = null;
    switch (params.type) {
      case "UsedCapacityUsageTrend":
        asset = yield call(
          assetService.getAssetGraphForCapacityTrend,
          serialNumber,
          params,
          site_info
        );
        yield put(getAssetGraphFulfilled(asset, params.type));
        break;
      case "Capacity":
        asset = yield call(
          assetService.getAssetGraphForUsedAvailableCapacity,
          serialNumber,
          params,
          site_info
        );
        yield put(getAssetGraphFulfilled(asset, params.type));
        break;
      case "Temperature":
      case "Power":
        if (productFamily?.indexOf("HCP") > -1) {
          asset = yield call(
            assetService.getPowerAndTemperature,
            serialNumber,
            params,
            params.site,
            site_info
          );
          yield put(getAssetGraphFulfilled(asset, params.type));
        } else {
          asset = yield call(
            assetService.getAssetGraph,
            serialNumber,
            params,
            site_info
          );
          yield put(getAssetGraphFulfilled(asset, params.type));
        }
        break;
      default:
        break;
    }
    // if (params.type === "UsedCapacityUsageTrend") {
    //   asset = yield call(
    //     assetService.getAssetGraphForCacheWritePendingRate,
    //     serialNumber,
    //     params
    //   );
    // } else if (params.type === "Capacity") {
    //   asset = yield call(
    //     assetService.getAssetGraphForUsedAvailableCapacity,
    //     serialNumber,
    //     params
    //   );
    // } else if (
    //   (params.type === "Temperature" || params.type === "Power") &&
    //   productFamily === CONST_VALUES.raid_900
    // ) {
    //   yield put(getAssetGraphFulfilled(null, params.type));
    // } else if (params.type === "VolumeIOPs") {
    //   asset = yield call(
    //     assetService.getAssetGraphForVolumeIOPs,
    //     serialNumber,
    //     params
    //   );
    // } else if (params.type === "VolumeResponseTime") {
    //   asset = yield call(
    //     assetService.getAssetGraphForVolumeResponseTime,
    //     serialNumber,
    //     params
    //   );
    // } else if (params.type === "VolumeTransferRate") {
    //   asset = yield call(
    //     assetService.getAssetGraphForVolumeTransferRate,
    //     serialNumber,
    //     params
    //   );
    // } else if (params.type === "CacheUsageRate") {
    //   asset = yield call(
    //     assetService.getAssetGraphForCacheUsageRate,
    //     serialNumber,
    //     params
    //   );
    // } else if (params.type === "CacheWritePendingRate") {
    //   asset = yield call(
    //     assetService.getAssetGraphForCacheWritePendingRate,
    //     serialNumber,
    //     params
    //   );
    // } else if (params.type === "ProcessorBusyRate") {
    //   asset = yield call(
    //     assetService.getAssetGraphForProcessorBusyRate,
    //     serialNumber,
    //     params
    //   );
    // }
    // else {
    //   asset = yield call(assetService.getAssetGraph, serialNumber, params);
    // }
    // yield put(getAssetGraphFulfilled(asset, params.type));
  } catch (error) {
    console.log(error);
  }
}
export function* getPerformanceGraph(action) {
  const { serialNumber, params } = action.payload;
  try {
    var asset = null;
    switch (params.type) {
      case "VolumeIOPs":
        asset = yield call(getAssetVolumeIOPs, serialNumber, params);
        break;
      case "VolumeResponseTime":
        asset = yield call(getAssetVolumeResponseTime, serialNumber, params);
        break;
      case "VolumeTransferRate":
        asset = yield call(getAssetVolumeTransferRate, serialNumber, params);
        break;
      case "CacheUsageRate":
        asset = yield call(getAssetCacheUsageRate, serialNumber, params);
        break;
      case "CacheWritePendingRate":
        asset = yield call(getAssetCacheWritePendingRate, serialNumber, params);
        break;
      case "ProcessorBusyRate":
        asset = yield call(getAssetProcessorBusyRate, serialNumber, params);
        break;
      case "OperationPerSecond":
        asset = yield call(getAssetOperationPerSecond, params);
        break;
      case "DiskLatency":
        asset = yield call(getAssetDiskLatency, params);
        break;
      case "EthernetThroughput":
        asset = yield call(getAssetEthernetThroughput, params);
        break;
      case "ServerUtilization":
        asset = yield call(getAssetServerUtilization, params);
        break;
      default:
        asset = yield call(assetService.getAssetGraph, serialNumber, params);
        yield put(getPerformanceDetailsFulfilled(asset, params.type));
        break;
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getGraphRangeDetails(action) {
  const { range, tab, graph } = action.payload;
  try {
    yield put(
      getGraphRangeDetailsFulfilled({
        data: range,
        tab: tab,
        graph: graph
      })
    );
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "GRAPH_RANGE_DETAILS",
      "Oops. An error occurred when fetching the range of graph."
    );
    // yield put(getGraphRangeDetailsFulfilled(null));
    yield put(getGraphRangeDetailsRejected(resourceError));
  }
}

export function checkAnalytics(action) {
  var site = [];
  if (action.siteId === CONST_VALUES.all_sites) {
    action.user.map(x => site.push(String(x.site_id)));
  } else {
    site.push(String(action.siteId));
  }
  let params = {};
  site =
    "[" +
    String(site)
      .split(",")
      .map(s => `'${s}'`)
      .join(",") +
    "]";
  if (site.indexOf(CONST_VALUES.favourites) > -1)
    params["favourites"] = CONST_VALUES.favourites;
  return assetService.getPhysicalPoolCapacityAnalytics(
    params,
    site,
    action.product_category_id
  );
}

function* getLicensedAndInstalledCapacity(params, site, product_category_id) {
  const capacity = yield call(
    assetService.getLicensedAndInstalledCapacity,
    params,
    site,
    product_category_id
  );

  if (capacity === null) {
    yield put(
      getAnalyticsFulfilled({
        data: capacity,
        analyticsType: "licensedAndInstalledCapacity"
      })
    );
  } else {
    yield put(
      getAnalyticsFulfilled({
        data: capacity,
        analyticsType: "licensedAndInstalledCapacity"
      })
    );
  }
}

function* getFileStorageCapacity(params, site, product_category_id) {
  const capacity = yield call(
    assetService.getFileStorageCapacityAnalytics,
    params,
    site,
    product_category_id
  );
  if (capacity?.hasOwnProperty("message") || capacity?.hasOwnProperty("HNAS")) {
    yield put(
      getAnalyticsFulfilled({
        data: capacity,
        analyticsType: "fileStorageCapacity"
      })
    );
  } else {
    yield put(
      getAnalyticsFulfilled({
        data: null,
        analyticsType: "fileStorageCapacity"
      })
    );
  }
}

function* getCapacity(params, site, product_category_id) {
  const capacity = yield call(
    assetService.getPhysicalPoolCapacityAnalytics,
    params,
    site,
    product_category_id
  );

  if (capacity === null) {
    yield put(
      getAnalyticsFulfilled({ data: capacity, analyticsType: "poolCapacity" })
    );
    yield put(
      getAnalyticsFulfilled({
        data: capacity,
        analyticsType: "parityGroupCapacity"
      })
    );
  } else {
    if (capacity.hasOwnProperty("pool_capacity")) {
      yield put(
        getAnalyticsFulfilled({
          data: capacity.pool_capacity,
          analyticsType: "poolCapacity"
        })
      );
    } else {
      if (capacity.hasOwnProperty("message")) {
        yield put(
          getAnalyticsFulfilled({
            data: capacity,
            analyticsType: "poolCapacity"
          })
        );
      } else {
        yield put(
          getAnalyticsFulfilled({ data: null, analyticsType: "poolCapacity" })
        );
      }
    }

    if (capacity.hasOwnProperty("parity_capacity")) {
      yield put(
        getAnalyticsFulfilled({
          data: capacity.parity_capacity,
          analyticsType: "parityGroupCapacity"
        })
      );
    } else {
      yield put(
        getAnalyticsFulfilled({
          data: null,
          analyticsType: "parityGroupCapacity"
        })
      );
    }
  }
}

function* getMicrocodes(params, site, product_category_id) {
  const microcodes = yield call(
    assetService.getArrayMicrocodes,
    params,
    site,
    product_category_id
  );
  yield put(
    getAnalyticsFulfilled({
      data: microcodes,
      analyticsType: "arrayMicrocodes"
    })
  );
}

function* getHardwareStatus(site, product_category_id) {
  const hardware = yield call(
    assetService.getHardwareStatus,
    {},
    site,
    product_category_id
  );
  yield put(
    getAnalyticsFulfilled({
      data: hardware,
      analyticsType: "supportContractRenewals"
    })
  );
}

function* getClosestToCapacity(params, site, product_category_id) {
  const closestToCapacity = yield call(
    assetService.closestToCapacity,
    params,
    site,
    product_category_id
  );
  yield put(
    getAnalyticsFulfilled({
      data: closestToCapacity,
      analyticsType: "systemCapacityMetrics"
    })
  );
}

function* getClosestToTemperature(params, site, product_category_id) {
  const closestToTemperature = yield call(
    assetService.getClosestToTemperature,
    params,
    site,
    product_category_id
  );
  yield put(
    getAnalyticsFulfilled({
      data: closestToTemperature,
      analyticsType: "systemTemperatureMetrics"
    })
  );
}

function* getDataDriveBreakdown(params, site, product_category_id) {
  let diskdrive = yield call(
    assetService.getDataDriveBreakdown,
    params,
    site,
    product_category_id
  );
  if (
    diskdrive?.hasOwnProperty("Block Storage") ||
    diskdrive?.hasOwnProperty("Object Storage") ||
    diskdrive?.hasOwnProperty("File Storage") ||
    diskdrive?.hasOwnProperty("message")
  ) {
    yield put(
      getAnalyticsFulfilled({
        data: diskdrive,
        analyticsType: "InternalDriveSummary"
      })
    );
  } else {
    yield put(
      getAnalyticsFulfilled({
        data: null,
        analyticsType: "InternalDriveSummary"
      })
    );
  }
}

export function* getAnalytics(action) {
  let storageOption = localStorage.getItem("storageOption");
  let user;
  var site = [];
  if (action.payload.params.siteId === CONST_VALUES.all_sites) {
    action.payload.params.user.map(
      x => x.site_id && site.push(String(x.site_id))
    );
  } else if (
    action.payload.params.favourites === true ||
    action.payload.params.siteId === CONST_VALUES.favourites
  ) {
    user = yield call(userService.getUser);
    yield put(getUserFulfilled(user));
  } else {
    action.payload.params.siteId &&
      site.push(String(action.payload.params.siteId));
  }
  site =
    "[" +
    String(site)
      .split(",")
      .map(s => `'${s}'`)
      .join(",") +
    "]";
  var product_category_id =
    action.payload &&
    action.payload.params &&
    action.payload.params.product_category_id;
  yield put(
    getAnalyticsFulfilled({
      data: { loading: true },
      analyticsType: "arrayMicrocodes"
    })
  );
  try {
    const callAnalyticsApis =
      action.payload.params.favourites === true ||
      action.payload.params.siteId === CONST_VALUES.favourites
        ? !!user && !!user.site_info
          ? storageOption === "overview"
            ? !!user.site_info.find(site => site.is_favourite)
            : !!user.site_info.find(
                site =>
                  site.product_category.split(" ")[0].toLowerCase() ===
                    storageOption && site.is_favourite
              )
          : false
        : true;
    if (callAnalyticsApis) {
      switch (storageOption) {
        case "block":
          yield fork(
            getCapacity,
            action.payload.params,
            site,
            product_category_id
          );
          yield fork(
            getDataDriveBreakdown,
            action.payload.params,
            site,
            product_category_id
          );
          yield fork(
            getClosestToTemperature,
            action.payload.params,
            site,
            product_category_id
          );
          break;
        case "object":
          yield fork(
            getLicensedAndInstalledCapacity,
            action.payload.params,
            site,
            product_category_id
          );
          yield fork(
            getDataDriveBreakdown,
            action.payload.params,
            site,
            product_category_id
          );
          yield fork(
            getClosestToTemperature,
            action.payload.params,
            site,
            product_category_id
          );
          break;
        case "file":
          yield fork(
            getFileStorageCapacity,
            action.payload.params,
            site,
            product_category_id
          );
          // yield fork(
          //   getFileStorageCapacity,
          //   action.payload.params,
          //   site,
          //   product_category_id
          // );
          break;
        default:
          yield fork(
            getLicensedAndInstalledCapacity,
            action.payload.params,
            site,
            product_category_id
          );
          yield fork(
            getCapacity,
            action.payload.params,
            site,
            product_category_id
          );
          yield fork(
            getFileStorageCapacity,
            action.payload.params,
            site,
            product_category_id
          );
          yield fork(
            getDataDriveBreakdown,
            action.payload.params,
            site,
            product_category_id
          );
          yield fork(
            getClosestToTemperature,
            action.payload.params,
            site,
            product_category_id
          );
      }
      yield fork(
        getMicrocodes,
        action.payload.params,
        site,
        product_category_id
      );
      yield fork(
        getClosestToCapacity,
        action.payload.params,
        site,
        product_category_id
      );
      // yield fork(
      //   getClosestToTemperature,
      //   action.payload.params,
      //   site,
      //   product_category_id
      // );
    }
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "ASSET",
      "Oops. An error occurred when fetching the analytics."
    );
    yield put(getAnalyticsFulfilled(null));
    yield put(getAnalyticsRejected(resourceError));
  }
}

export function* postAsset(action) {
  const { asset } = action.payload;

  try {
    const response = yield call(assetService.postAsset, asset);
    yield put(postAssetFulfilled(response));
    yield put(
      notificationSend({
        id: new Date(),
        label:
          "The information was successfully submitted. A new escalated report was created.",
        variant: "success",
        dismissAfter: 5000,
        banner: true
      })
    );
    yield put(getEscalatedReportsPending());
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "POST",
      "ASSET",
      "Oops. An error occurred when updating the asset."
    );
    yield put(postAssetRejected(resourceError));
  }
}

export function* patchAsset(action) {
  const { serialNumber, asset, params } = action.payload;

  try {
    const response = yield call(assetService.patchAsset, serialNumber, asset);
    yield put(patchAssetFulfilled(response));
    yield put(getAssetsPending(params));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "PATCH",
      "ASSET",
      "Oops. An error occurred when updating the asset."
    );
    yield put(patchAssetRejected(resourceError));
  }
}

export function* patchUserPreference(action) {
  const { hideNotification } = action.payload;

  try {
    const params = { hide_notification: hideNotification };
    const response = yield call(assetService.saveUserPreference, params);
    yield put(patchUserPreferenceFulfilled(response));
    const user = yield call(userService.getUser);
    yield put(getUserFulfilled(user));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "PATCH",
      "ASSET",
      "Oops. An error occurred when updating the asset."
    );
    yield put(patchUserPreferenceRejected(resourceError));
  }
}

export function* patchAssetToFavorites(action) {
  const {
    params,
    state,
    allAssets,
    isFavoriteSelectedInInventory,
    isFavInBulk
  } = action.payload;

  const { site_id, serial_id, product_family_id } = params;

  try {
    const new_params = {
      action: state,
      assets_list: isFavInBulk ? params : [params]
    };

    yield put(handleLoader(true));
    const response = yield call(assetService.updateFavoriteAsset, new_params);
    yield put(patchAssetToFavouritesFulfilled(response));
    if (response.status === "success") {
      let updatedAsset = [];
      if ((state === "remove") & isFavoriteSelectedInInventory) {
        const inventoryParams = {
          favourites: true,
          product_category_id: allAssets[0].product_category_id
        };
        const assets = yield call(assetService.getAssets, inventoryParams);
        updatedAsset =
          (assets &&
            assets.data &&
            assets.message !== CONST_VALUES.Error &&
            assets.message !== "No Favorite Assets" &&
            Object.values(assets.data).flat()) ||
          [];
        const assetsThreshold = !!assets
          ? assets.threshold
          : {
              Critical: "90",
              Warning: "80"
            };
        yield put(getAssetsThresholdFulfilled(assetsThreshold));
      } else {
        if (isFavInBulk) {
          for (var i = 0; i < params.length; i++) {
            updatedAsset = allAssets.map(asset => {
              if (
                (asset.serialNumber === params[i].serial_id ||
                  asset.serial_id === params[i].serial_id) &&
                (asset.siteId === params[i].site_id ||
                  asset.site_id === params[i].site_id) &&
                asset.product_family_id === params[i].product_family_id
              )
                if (state === "add") asset.is_favourite = true;
                else asset.is_favourite = false;
              return asset;
            });
          }
        } else {
          updatedAsset = allAssets.map(asset => {
            if (
              (asset.serialNumber === serial_id ||
                asset.serial_id === serial_id) &&
              (asset.siteId === site_id || asset.site_id === site_id) &&
              asset.product_family_id === product_family_id
            )
              if (state === "add") asset.is_favourite = true;
              else asset.is_favourite = false;
            return asset;
          });
        }
      }
      yield put(getAssetsFulfilled(updatedAsset));
    }
    yield put(handleLoader(false));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "PATCH",
      "ASSET",
      "Oops. An error occurred when adding asset to favorites."
    );
    yield put(patchAssetToFavouritesRejected(resourceError));
  }
}

export function* removeAssets(action) {
  try {
    yield put(handleLoader(true));
    const response = yield call(
      assetService.removeAssets,
      action.payload.assets
    );
    yield put(removeAssetsFulfilled(response));
    yield put(handleLoader(false));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "REMOVE",
      "ASSETS",
      "Oops. An error occurred when removing the asset."
    );
    yield put(removeAssetsRejected(resourceError));
  }
}

export function* addAssets(action) {
  try {
    yield put(handleLoader(true));
    const response = yield call(assetService.addAssets, action.payload.assets);
    yield put(addAssetsFulfilled(response));
    yield put(handleLoader(false));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "REMOVE",
      "ASSETS",
      "Oops. An error occurred when removing the asset."
    );
    yield put(addAssetsRejected(resourceError));
  }
}

export function* getAssetsReportCSV(action) {
  try {
    yield put(handleLoader(true));
    const response = yield call(
      assetService.getAssetsReportCSV,
      action.payload.assets
    );
    yield put(getAssetsReportCSVFulfilled(response));
    yield put(handleLoader(false));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "ASSETS_REPORT",
      "Oops. An error occurred while downloading the report."
    );
    yield put(getAssetsReportCSVRejected(resourceError));
  }
}
export function* getLunHostGroupDetails(action) {
  const { payload } = action;
  const response = yield call(assetService.getLunHostGroupDetails, payload);
  try {
    if (response) {
      yield put(getLunHostGroupDetailsFulfilled(response));
    }
  } catch (error) {
    yield put(getLunHostGroupDetailsRejected(error));
  }
}

export function* addAssetsActionReset(action) {
  const { payload } = action.payload;
  yield put(addAssetsFulfilled(payload));
}

export function* getSelectedAsset(action) {
  const { payload } = action.payload;
  yield put(getSelectedAssetFulfilled(payload));
}

export function* getAssetsReportCSVActionReset(action) {
  const { payload } = action.payload;
  yield put(getAssetsReportCSVFulfilled(payload));
}

export function* removeAssetsActionReset(action) {
  yield put(removeAssetsFulfilled(null));
}

export function* getPerformanceDetails(action) {
  const { params } = action.payload;
  const marketingName = params?.marketing_name;
  try {
    yield fork(getAssetOperationPerSecond, {
      serialNumber: params.serial,
      site: params.site,
      productFamilyId: params.productFamilyId,
      range: params.range
    });

    if (marketingName !== "VSP One File 30 Series") {
      yield fork(getAssetDiskLatency, {
        serialNumber: params.serial,
        site: params.site,
        productFamilyId: params.productFamilyId,
        range: params.range
      });

      yield fork(getAssetEthernetThroughput, {
        serialNumber: params.serial,
        site: params.site,
        productFamilyId: params.productFamilyId,
        range: params.range
      });
    }

    yield fork(getAssetServerUtilization, {
      serialNumber: params.serial,
      site: params.site,
      productFamilyId: params.productFamilyId,
      range: params.range
    });

    // yield fork(getAssetProcessorBusyRate, serialNumber, {
    //   site: params.site,
    //   range: params.range
    // });
    // yield fork(getAssetCacheUsageRate, serialNumber, {
    //   site: params.site,
    //   range: params.range
    // });
    // yield fork(getAssetCacheWritePendingRate, serialNumber, {
    //   site: params.site,
    //   range: params.range
    // });
    // yield fork(getAssetVolumeIOPs, serialNumber, {
    //   site: params.site,
    //   range: params.range
    // });
    // yield fork(getAssetVolumeResponseTime, serialNumber, {
    //   site: params.site,
    //   range: params.range
    // });
    // yield fork(getAssetVolumeTransferRate, serialNumber, {
    //   site: params.site,
    //   range: params.range
    // });
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "PERFORMACE_DETAILS",
      "Oops. An error occurred when fetching the asset."
    );
    yield put(getPerformanceDetailsFulfilled(null));
    yield put(getPerformanceDetailsRejected(resourceError));
  }
}

export function* getCloudConnection(action) {
  try {
    const response = yield call(
      assetService.getCloudConnection,
      action.payload
    );
    if (response && response.status_code === CONST_VALUES.status_code_503) {
      yield call(showNotification, response.message);
    }
    yield put(getCloudConnectionFulfilled(response));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "REMOVE",
      "ASSETS",
      "Oops. An error occurred when removing the asset."
    );
    yield put(getCloudConnectionRejected(resourceError));
  }
}

export function* getCredentialsDetails(action) {
  try {
    const response = yield call(
      assetService.getCredentialsDetails,
      action.payload
    );
    yield put(getCredentialsDetailsFulfilled(response));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "CREDENTIALS DATA",
      "OOPS. An error occurred while fetching credentials data."
    );
    yield put(getCredentialsDetailsRejected(resourceError));
  }
}

export function* createCredentialsDetails(action) {
  try {
    const response = yield call(
      assetService.createCredentialsDetails,
      action.payload
    );
    yield put(createCredentialsDetailsFulfilled(response));

    // const responseData = yield call(assetService.getCredentialsDetails);
    // yield put(getCredentialsDetailsFulfilled(responseData));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "POST",
      "CREDENTIALS DATA",
      "OOPS. An error occurred while creating credentials data."
    );
    yield put(createCredentialsDetailsRejected(resourceError));
  }
}

export function* getApiGetwayInfo(action) {
  try {
    const response = yield call(assetService.getApiGetwayInfo, action.payload);
    yield put(getApiGetwayInfoFulfilled(response));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "POST",
      "CREDENTIALS DATA",
      "OOPS. An error occurred while creating credentials data."
    );
    yield put(getApiGetwayInfoRejected(resourceError));
  }
}
export function* updateWhitelistIp(action) {
  try {
    const response = yield call(assetService.updateWhitelistIp, action.payload);
    yield put(updateWhitelistIpFulfilled(response));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "POST",
      "CREDENTIALS DATA",
      "OOPS. An error occurred while creating credentials data."
    );
    yield put(updateWhitelistIpRejected(resourceError));
  }
}

export function* getOrgDetails(action) {
  try {
    const response = yield call(assetService.getOrgDetails, action.payload);
    yield put(getOrgDetailsFulfilled(response));
    if (response?.status_code) {
      yield put(getCloudConnectionFulfilled(response));
    }
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "REMOVE",
      "ASSETS",
      "Oops. An error occurred when removing the asset."
    );
    yield put(getOrgDetailsRejected(resourceError));
  }
}

export function* resetCloudConnection(action) {
  try {
    let response = null;
    if (action.payload) {
      response = yield call(assetService.resetCloudConnection, action.payload);
    }
    yield put(getCloudConnectionFulfilled(response));
    if (response && response.status_code === CONST_VALUES.status_code_503) {
      yield call(showNotification, response.message);
    }
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "REMOVE",
      "ASSETS",
      "Oops. An error occurred when reseting connection status."
    );
    yield put(getCloudConnectionFulfilled(null));
    yield put(getCloudConnectionRejected(resourceError));
  }
}

export function* getPerformanceApproval(action) {
  try {
    const response = yield call(
      assetService.getPerformanceApproval,
      action.payload
    );
    if (response && response.connection_status) {
      yield put(getCloudConnectionFulfilled(response));
    }
    yield put(getPerformanceApprovalFulfilled(response));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "REMOVE",
      "ASSETS",
      "Oops. An error occurred when removing the asset."
    );
    yield put(getPerformanceApprovalRejected(resourceError));
  }
}

function* watchGetAssets() {
  yield takeEvery(GET_ASSETS_PENDING, getAssets);
}

function* watchGetFilteredAssets() {
  yield takeEvery(GET_FILTERED_ASSETS_PENDING, getFilteredAssets);
}

function* watchGetActiveAssets() {
  yield takeEvery(GET_ACTIVE_ASSETS_PENDING, getActiveAssets);
}

function* watchGetLunHostGroupDetails() {
  yield takeEvery(GET_LUN_HOST_GROUP_DETAILS_PENDING, getLunHostGroupDetails);
}

function* watchGetAssetsThreshold() {
  yield takeEvery(GET_ASSETS_THRESHOLD_PENDING, getAssets);
}

function* watchGetEntitlements() {
  yield takeEvery(GET_ENTITLEMENTS_PENDING, getAssets);
}

function* watchGetSelectedAsset() {
  yield takeEvery(GET_SELECTED_ASSET_PENDING, getSelectedAsset);
}

function* watchResetAssets() {
  yield takeEvery(GET_ASSETS_RESET, resetAssets);
}

function* watchResetAsset() {
  yield takeEvery(GET_ASSET_RESET, resetAsset);
}

function* watchGetAsset() {
  yield takeEvery(GET_ASSET_PENDING, getAsset);
}

function* watchGetAssetDetails() {
  yield takeEvery(GET_ASSET_DETAILS_PENDING, getAssetDetails);
}

function* watchGetHCPAssetDetails() {
  yield takeEvery(GET_HCP_ASSET_DETAILS_PENDING, getHCPAssetDetails);
}
function* watchGetHNASAssetDetails() {
  yield takeEvery(GET_HNAS_ASSET_DETAILS_PENDING, getHNASAssetDetails);
}
function* watchGetAssetDetailsReset() {
  yield takeEvery(GET_ASSET_DETAILS_RESET_PENDING, getAssetDetailsReset);
}
function* watchGetPerformanceDetailsReset() {
  yield takeEvery(
    GET_PERFORMANCE_DETAILS_RESET_PENDING,
    getPerformanceDetailsReset
  );
}

function* watchGetGraphRangeDetailsReset() {
  yield takeEvery(
    GET_GRAPH_RANGE_DETAILS_RESET_PENDING,
    getGraphRangeDetailsReset
  );
}

function* watchGetAssetGraph() {
  yield takeEvery(GET_ASSET_GRAPH_PENDING, getAssetGraph);
}

function* watchGetAnalytics() {
  yield takeLatest(GET_ANALYTICS_PENDING, getAnalytics);
}

function* watchPostAsset() {
  yield takeEvery(CREATE_ASSET_PENDING, postAsset);
}

function* watchPatchAsset() {
  yield takeEvery(PATCH_ASSET_PENDING, patchAsset);
}

function* watchPatchUserPreference() {
  yield takeEvery(PATCH_USER_PREFERENCE_PENDING, patchUserPreference);
}

function* watchPatchAssetToFavorites() {
  yield takeEvery(PATCH_ASSET_TO_FAVORITES_PENDING, patchAssetToFavorites);
}

function* watchGetIntUserAssets() {
  yield takeEvery(GET_INTUSER_ASSETS_PENDING, getAssetsForIntUser);
}
function* watchRemoveAssets() {
  yield takeEvery(DELETE_REMOVE_ASSETS_PENDING, removeAssets);
}

function* watchAddAssets() {
  yield takeEvery(CREATE_ADD_ASSETS_PENDING, addAssets);
}

function* watchGetAssetsReportCSV() {
  yield takeEvery(CREATE_GET_ASSETS_REPORT_CSV_PENDING, getAssetsReportCSV);
}

function* watchAddAssetsActionReset() {
  yield takeEvery(CREATE_ADD_ASSETS_ACTION_RESET_PENDING, addAssetsActionReset);
}

function* watchRemoveAssetsActionReset() {
  yield takeEvery(
    DELETE_REMOVE_ASSETS_ACTION_RESET_PENDING,
    removeAssetsActionReset
  );
}

function* watchGetAssetsReportCSVActionReset() {
  yield takeEvery(
    CREATE_GET_ASSETS_REPORT_CSV_ACTION_RESET_PENDING,
    getAssetsReportCSVActionReset
  );
}

function* watchGetConnectionStatus() {
  yield takeEvery(GET_CLOUD_CONNECTION_PENDING, getCloudConnection);
}

function* watchGetOrgDetails() {
  yield takeEvery(GET_ORG_DETAILS_PENDING, getOrgDetails);
}

function* watchGetCredentialsDetails() {
  yield takeEvery(GET_CREDENTIALS_DETAILS_PENDING, getCredentialsDetails);
}

function* watchCreateCredentialsDetails() {
  yield takeEvery(CREATE_CREDENTIALS_DETAILS_PENDING, createCredentialsDetails);
}
function* watchGetApiGetwayInfo() {
  yield takeEvery(GET_API_GATEWAY_INFO_PENDING, getApiGetwayInfo);
}
function* watchUpdateWhitelistIp() {
  yield takeEvery(UPDATE_WHITELIST_IP_PENDING, updateWhitelistIp);
}

function* watchPerformanceDetails() {
  yield takeEvery(GET_PERFORMANCE_DETAILS_PENDING, getPerformanceDetails);
}

function* watchResetCloudConnection() {
  yield takeEvery(GET_CLOUD_CONNECTION_NEXT_PENDING, resetCloudConnection);
}

function* watchPerformanceApproval() {
  yield takeEvery(GET_PERFORMACE_APPROVAL_PENDING, getPerformanceApproval);
}

function* watchGetPerformanceGraph() {
  yield takeEvery(GET_PERFORMANCE_GRAPH_PENDING, getPerformanceGraph);
}

function* watchGetGraphRangeDetails() {
  yield takeEvery(GET_GRAPH_RANGE_DETAILS_PENDING, getGraphRangeDetails);
}

export default [
  watchGetAssets,
  watchGetFilteredAssets,
  watchGetActiveAssets,
  watchGetLunHostGroupDetails,
  watchGetAssetsThreshold,
  watchGetEntitlements,
  watchGetAsset,
  watchGetAssetDetails,
  watchGetHCPAssetDetails,
  // watchGetUserPrefrence,
  // watchGetHNASCapacityHistoryData,
  watchGetHNASAssetDetails,
  watchGetAssetDetailsReset,
  watchGetAssetGraph,
  watchGetAnalytics,
  watchPostAsset,
  watchPatchAsset,
  watchPatchUserPreference,
  watchPatchAssetToFavorites,
  watchResetAssets,
  watchResetAsset,
  watchGetIntUserAssets,
  watchRemoveAssets,
  watchAddAssets,
  watchGetAssetsReportCSV,
  watchGetAssetsReportCSVActionReset,
  watchAddAssetsActionReset,
  watchRemoveAssetsActionReset,
  watchGetConnectionStatus,
  watchGetOrgDetails,
  watchGetCredentialsDetails,
  watchCreateCredentialsDetails,
  watchGetApiGetwayInfo,
  watchUpdateWhitelistIp,
  watchPerformanceDetails,
  watchResetCloudConnection,
  watchPerformanceApproval,
  watchGetPerformanceGraph,
  watchGetGraphRangeDetails,
  watchGetSelectedAsset,
  watchGetPerformanceDetailsReset,
  watchGetGraphRangeDetailsReset
];

export const createFulfilledObjectValueReducer = (
  state,
  { payload, meta: { operation, operationState } }
) =>
  operation === "CREATE" && operationState === "FULFILLED"
    ? {
        value: payload,
        version: payload.version
      }
    : state;

export const deleteFulfilledObjectValueReducer = (
  state,
  { payload, meta: { operation, operationState } }
) =>{
  return operation === "DELETE" && operationState === "FULFILLED"
    ? {
        value: payload,
        version: null
      }
    : state;
  }

export const getFulfilledObjectValueReducer = (
  state,
  { payload, meta: { dataKey, operation, operationState, parentIdKey } }
) => {
  if (operation !== "GET" || operationState !== "FULFILLED") {
    return state;
  }

  const newState = {
    value: (dataKey ? payload[dataKey] : payload) || null,
    version: payload
      ? (dataKey ? payload[dataKey].version : payload.version) || null
      : null
  };

  return parentIdKey
    ? {
        ...newState,
        [parentIdKey]: dataKey
          ? payload[dataKey][parentIdKey]
          : payload[parentIdKey]
      }
    : newState;
};

export const patchFulfilledObjectValueReducer = (
  state,
  { payload, meta: { operation, operationState } }
) =>
  operation === "PATCH" && operationState === "FULFILLED"
    ? {
        value: payload,
        version: payload.version
      }
    : state;

import { css } from "styled-components";

export default {
  BannerNotificationsWrapper: {
    style: css`
      position: absolute;
      top: 0px;
      left: 20px;
      right: 20px;

      & div {
        box-shadow: none;
      }
    `
  },
  SnackbarNotificationsWrapper: {
    style: css`
      position: absolute;
      top: 40px;
      right: 20px;

      & div {
        box-shadow: none;
      }
    `
  },
  HvBanner: {
    style: css`
      &&& {
        max-width: 100%;
        position: relative;
        margin-top: 10px;
        left: 0px;
        transform: none;
        & div {
          box-shadow: none;
        }
      }
    `
  },
  HvSnackbar: {
    style: css`
      &&& {
        position: relative;
        margin-top: 10px;
        margin-right: 10px;
        margin-left: 10px;
        right: 0px;
        cursor: pointer;
      }

      & > div {
        max-width: 400px;
        width: 100%;
      }
    `
  }
};

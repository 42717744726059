import { combineReducers } from "redux";
import * as assetReducers from "./assetReducer";
import comments from "./commentReducer";
import contractRenewals from "./contractRenewalReducer";
import * as customers from "./customerReducer";
import escalatedReports from "./escalatedReportReducer";
import microcodes from "./microcodeReducer";
import navigation from "./navigationReducer";
import credentials from "./credentialsReducer";

import * as notifications from "./notificationReducer";
import * as sustainability from "./sustainabilityReducer";
import * as security from "./securityReducer";
import * as users from "./userReducer";
import * as permissions from "./permissionsReducer";
import * as eula from "./eulaReducer";
import loader from "./loaderReducer";
import * as alerts from "./alertsReducer";
import * as multiSharePermissions from "./multiSharePermissionsReducer";

export default combineReducers({
  ...assetReducers,
  comments,
  contractRenewals,
  ...customers,
  escalatedReports,
  microcodes,
  navigation,
  ...notifications,
  ...users,
  ...permissions,
  ...eula,
  loader,
  ...alerts,
  ...multiSharePermissions,
  ...sustainability,
  ...security,
  credentials
});

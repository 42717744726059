import api from "./api";
import URLS from "../utils/urls.js";

export const getUser = () => {
  return api.get(URLS.BACKEND_API + `/logged_in_user_dtls/`);
};

export const checkAuth = () => {
  return api.get(URLS.BACKEND_API + `/is_session_valid`);
};

export const signoutUser = () => {
  return api.get(URLS.BACKEND_API + `/signout_session_cleaning`);
};

export const signoutUserWithContextSwitch = contextSwitch => {
  return api.get(
    URLS.BACKEND_API +
      `/signout_session_cleaning?context_switch=${contextSwitch}`
  );
};

export const postUserLogin = params => {
  return api.post(URLS.BACKEND_API + `/login/`, { ...params });
};

export const checkSystem = () => {
  return api.get(URLS.BACKEND_API + `/check_system`);
};

// CREATE
export const createPendingAsyncStateReducer = () => ({
  loading: true,
  error: null
});
export const createRejectedAsyncStateReducer = (state, { payload: error }) => ({
  loading: false,
  error
});
export const createFulfilledAsyncStateReducer = () => ({
  loading: false,
  error: null
});

// DELETE
export const deletePendingAsyncStateReducer = () => ({
  loading: true,
  error: null
});
export const deleteRejectedAsyncStateReducer = (state, { payload: error }) => ({
  loading: false,
  error
});
export const deleteFulfilledAsyncStateReducer = () => ({
  loading: false,
  error: null
});

// GET
export const getPendingAsyncStateReducer = () => ({
  error: null,
  loading: true
});
export const getCancelledAsyncStateReducer = () => ({
  loading: false
});
export const getRejectedAsyncStateReducer = (state, { payload: error }) => ({
  error,
  loading: false
});
export const getFulfilledAsyncStateReducer = () => ({
  error: null,
  loading: false,
  fulfilled:true
});

// PATCH
export const patchPendingAsyncStateReducer = () => ({
  error: null,
  loading: true
});
export const patchRejectedAsyncStateReducer = (state, { payload: error }) => ({
  error,
  loading: false
});
export const patchFulfilledAsyncStateReducer = () => ({
  error: null,
  loading: false
});

import { call, put, takeEvery } from "redux-saga/effects";
import * as microcodeService from "../../services/microcodeService";
import {
  GET_MICROCODES_PENDING,
  PUT_MICROCODES_PENDING,
  getMicrocodesRejected,
  getMicrocodesFulfilled,
  putMicrocodesRejected
} from "../actions/microcodeActions";
import getResourceError from "./helpers/getResourceError";
import { notificationSend } from "../actions/notificationActions";

export function* getMicrocodes(action) {
  const { params } = action.payload;

  try {
    const microcodes = yield call(microcodeService.getMicrocodes, params);
    yield put(getMicrocodesFulfilled(microcodes));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "MICROCODES",
      "Oops. An error occurred when fetching the list of microcodes."
    );
    yield put(getMicrocodesRejected(resourceError));
  }
}

export function* putMicrocodes(action) {
  const { serialNumbers, params, type } = action.payload;

  try {
    let service = microcodeService.upgradeMicrocodes;
    if (type === "mute") {
      service = microcodeService.muteMicrocodes;
    }
    if (type === "unmute") {
      service = microcodeService.unmuteMicrocodes;
    }
    yield call(service, serialNumbers);

    const microcodes = yield call(microcodeService.getMicrocodes, params);
    yield put(getMicrocodesFulfilled(microcodes));

    yield put(
      notificationSend({
        id: new Date(),
        label: "Your request was successfully sent.",
        variant: "success",
        dismissAfter: 5000
      })
    );
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "PUT",
      "MICROCODES",
      "Oops. An error occurred when updating the microcodes."
    );
    yield put(putMicrocodesRejected(resourceError));
  }
}

function* watchGetMicrocodes() {
  yield takeEvery(GET_MICROCODES_PENDING, getMicrocodes);
}

function* watchPutMicrocodes() {
  yield takeEvery(PUT_MICROCODES_PENDING, putMicrocodes);
}

export default [watchGetMicrocodes, watchPutMicrocodes];

import createWithActionCreator from "./helpers/createWithActionCreator";

export const GET_ALERTS_LIST_PENDING = "GET_ALERTS_LIST_PENDING";
export const GET_ALERTS_LIST_REJECTED = "GET_ALERTS_LIST_REJECTED";
export const GET_ALERTS_LIST_FULFILLED = "GET_ALERTS_LIST_FULFILLED";

export const getAlertsListPending = params => {
  return ({
    type: GET_ALERTS_LIST_PENDING,
    payload : { params }
    })
};

export const getAlertsListRejected = error => ({
  type: GET_ALERTS_LIST_REJECTED,
  payload: error
});

export const getAlertsListFulfilled = payload => {
  return {
    type: GET_ALERTS_LIST_FULFILLED,
    payload
  };
};

export const withGetAlertsList = createWithActionCreator(
  "getAlertsList",
  getAlertsListPending
);
import { call, put, takeLatest } from "redux-saga/effects";
import * as securityService from "../../services/securityService";
import getResourceError from "./helpers/getResourceError";
import {
  getSecurityRecommendationFulfilled,
  getSecurityRecommendationRejected,
  GET_SECURITY_RECOMMENDATION_PENDING
} from "../actions/securityActions";

export function* getSecurityRecommendation(action) {
  try {
    const response = yield call(
      securityService.getSecurityRecommendationsDetails,
      action.payload
    );
    yield put(getSecurityRecommendationFulfilled(response));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "SECURITY_RECOMMENDATON",
      "Oops. An error occurred when adding the energy."
    );
    yield put(getSecurityRecommendationRejected(resourceError));
  }
}

function* watchGetSecurityRecommendation() {
  yield takeLatest(
    GET_SECURITY_RECOMMENDATION_PENDING,
    getSecurityRecommendation
  );
}

export default [watchGetSecurityRecommendation];

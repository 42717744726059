import createWithActionCreator from "./helpers/createWithActionCreator";

// GET USER
export const GET_USER_PENDING = "GET_USER_PENDING";
export const GET_USER_REJECTED = "GET_USER_REJECTED";
export const GET_USER_FULFILLED = "GET_USER_FULFILLED";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SHOW_NOTIFICATIONS = "SHOW_NOTIFICATIONS";

export const getUserPending = (id, userType) => ({
  type: GET_USER_PENDING,
  payload: { id, userType }
});

export const getUserRejected = error => ({
  type: GET_USER_REJECTED,
  payload: error
});

export const getUserFulfilled = payload => {
  return {
    type: GET_USER_FULFILLED,
    payload
  };
};

//Get SalesForce site id & Hitrack site id mapping

export const GET_SITE_IDS_FULFILLED = "GET_SITE_IDS_FULFILLED";
export const getSiteIdsFulfilled = payload => {
  return {
    type: GET_SITE_IDS_FULFILLED,
    payload
  };
};

// GET AUTH
export const GET_AUTH_PENDING = "GET_AUTH_PENDING";
export const GET_AUTH_REJECTED = "GET_AUTH_REJECTED";
export const GET_AUTH_FULFILLED = "GET_AUTH_FULFILLED";

export const GET_SYSTEM_PENDING = "GET_SYSTEM_PENDING";
export const GET_SYSTEM_REJECTED = "GET_SYSTEM_REJECTED";
export const GET_SYSTEM_FULFILLED = "GET_SYSTEM_FULFILLED";

export const getAuthPending = () => ({
  type: GET_AUTH_PENDING
});

export const getSystemPending = () => ({
  type: GET_SYSTEM_PENDING
});

export const getAuthRejected = error => ({
  type: GET_AUTH_REJECTED,
  payload: error
});

export const getSystemRejected = error => ({
  type: GET_SYSTEM_REJECTED,
  payload: error
});

export const getAuthFulfilled = payload => {
  return {
    type: GET_AUTH_FULFILLED,
    payload
  };
};

export const getSystemFulfilled = payload => {
  return {
    type: GET_SYSTEM_FULFILLED,
    payload
  };
};

// SIGNOUT USER
export const signoutUser = params => ({
  type: SIGNOUT_USER,
  ...params
});

//
export const showNotifications = (error_text, color) => ({
  type: SHOW_NOTIFICATIONS,
  payload: { error_text, color }
});

// GET USER
export const GET_USER_LOGIN_PENDING = "GET_USER_LOGIN_PENDING";
export const GET_USER_LOGIN_REJECTED = "GET_USER_LOGIN_REJECTED";

export const getUserLoginPending = (username, password) => ({
  type: GET_USER_LOGIN_PENDING,
  payload: { username, password }
});

export const getUserLoginRejected = error => ({
  type: GET_USER_LOGIN_REJECTED,
  payload: error
});

export const getUserLoginFulfilled = payload => {
  return {
    type: GET_USER_FULFILLED,
    payload
  };
};

export const withGetUser = createWithActionCreator("getUser", getUserPending);

export const withGetUserLogin = createWithActionCreator(
  "getUserLogin",
  getUserLoginPending
);

export const withCheckAuth = createWithActionCreator(
  "checkAuth",
  getAuthPending
);

export const withCheckSystem = createWithActionCreator(
  "checkSystem",
  getSystemPending
);

export const withSignoutUser = createWithActionCreator(
  "signoutUser",
  signoutUser
);

export const withShowNotifications = createWithActionCreator(
  "showNotifications",
  showNotifications
);

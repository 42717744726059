import createWithActionCreator from "./helpers/createWithActionCreator";

export const SET_NAVIGATION = "SET_NAVIGATION";
export const SET_STORAGE_OPTION = "SET_STORAGE_OPTION";
export const SET_INVENTORY_SORT_ID = "SET_INVENTORY_SORT_ID";
export const SET_INVENTORY_TABLE_VIEW = "SET_INVENTORY_TABLE_VIEW";
export const SET_INVENTORY_TABLE_VIEW_SORT = "SET_INVENTORY_TABLE_VIEW_SORT";
export const SET_ASSET_DETAILS_TAB = "SET_ASSET_DETAILS_TAB";
export const SET_ALERT_CATEGORY = "SET_ALERT_CATEGORY";

export const setNavigation = payload => ({
  type: SET_NAVIGATION,
  payload
});

export const setStorageOption = payload => ({
  type: SET_STORAGE_OPTION,
  payload
});

export const withSetStorageOption = createWithActionCreator(
  "setStorageOption",
  setStorageOption
);
export const withSetNavigation = createWithActionCreator(
  "setNavigation",
  setNavigation
);

export const setInventorySortId = payload => ({
  type: SET_INVENTORY_SORT_ID,
  payload
});

export const setInventoryTableView = payload => ({
  type: SET_INVENTORY_TABLE_VIEW,
  payload
});

export const setInventoryTableViewSort = payload => ({
  type: SET_INVENTORY_TABLE_VIEW_SORT,
  payload
});

export const setAssetdetailsTab = payload => ({
  type: SET_ASSET_DETAILS_TAB,
  payload
});

export const setAlertCategory = payload => ({
  type: SET_ALERT_CATEGORY,
  payload
});

export const withSetInventorySortId = createWithActionCreator(
  "setInventorySortId",
  setInventorySortId
);

export const withSetInventoryTableView = createWithActionCreator(
  "setInventoryTableView",
  setInventoryTableView
);

export const withSetInventoryTableViewSort = createWithActionCreator(
  "setInventoryTableViewSort",
  setInventoryTableViewSort
);

export const withSetAssetdetailsTab = createWithActionCreator(
  "setAssetdetailsTab",
  setAssetdetailsTab
);

export const withSetAlertCategory = createWithActionCreator(
  "setAlertCategory",
  setAlertCategory
);

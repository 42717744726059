import createWithActionCreator from "./helpers/createWithActionCreator";

// GET ESCALATED_REPORTS
export const GET_ESCALATED_REPORTS_PENDING = "GET_ESCALATED_REPORTS_PENDING";
export const GET_ESCALATED_REPORTS_REJECTED = "GET_ESCALATED_REPORTS_REJECTED";
export const GET_ESCALATED_REPORTS_FULFILLED =
  "GET_ESCALATED_REPORTS_FULFILLED";

export const getEscalatedReportsPending = () => ({
  type: GET_ESCALATED_REPORTS_PENDING
});

export const getEscalatedReportsRejected = error => ({
  type: GET_ESCALATED_REPORTS_REJECTED,
  payload: error
});

export const getEscalatedReportsFulfilled = payload => {
  return {
    type: GET_ESCALATED_REPORTS_FULFILLED,
    payload
  };
};

export const withGetEscalatedReports = createWithActionCreator(
  "getEscalatedReports",
  getEscalatedReportsPending
);

import { takeEvery } from "redux-saga/effects";
import {
  IS_LOADER_VISIBLE
} from "../actions/loaderAction";

export function* handleLoader(action) {
  const { payload } = action;
}

function* watchHandleLoader() {
  yield takeEvery(IS_LOADER_VISIBLE, handleLoader);
}

export default [watchHandleLoader];

import { all, fork } from "redux-saga/effects";
import assetSaga from "./assetSaga";
import commentSaga from "./commentSaga";
import contractRenewalSaga from "./contractRenewalSaga";
import customerSaga from "./customerSaga";
import escalatedReportSaga from "./escalatedReportSaga";
import microcodeSaga from "./microcodeSaga";
import notificationSaga from "./notificationSaga";
import userSaga from "./userSaga";
import permissionSaga from "./permissionSaga";
import eulaSaga from "./eulaSaga";
import loaderSaga from "./loaderSaga";
import alertsSaga from "./alertsSaga";
import sustainabilitySaga from "./sustainabilitySaga";
import securitySaga from "./securitySaga";
import multiSharePermissionsSaga from "./multiSharePermissionsSaga";

const sagas = [
  // "api service sagas"
  ...assetSaga,
  ...commentSaga,
  ...contractRenewalSaga,
  ...customerSaga,
  ...escalatedReportSaga,
  ...microcodeSaga,
  ...notificationSaga,
  ...userSaga,
  ...permissionSaga,
  ...eulaSaga,
  ...loaderSaga,
  ...alertsSaga,
  ...sustainabilitySaga,
  ...multiSharePermissionsSaga,
  ...securitySaga
];

export default function* rootSaga() {
  yield all(sagas.map(saga => fork(saga)));
}

import React, { Component, Fragment, Suspense } from "react";
import ReactGA from "react-ga4";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { createGenerateClassName } from "@mui/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { JssProvider } from "react-jss";
import muiTtheme from "./theme/muiTheme";
import theme, { GlobalStyle } from "./theme";
import { store } from "./state/store";
import ScrollToTop from "./helpers/scrollToTop";
import { checkValidUrl } from "./utils/clearsightUtils";
import "./index.scss";
import "./commonStyles.scss";
import "./stylesheets/fonts.scss";

import { CircularProgress } from "@mui/material";

const RootLayout = React.lazy(() => import("./RootLayout"));
const SnackbarNotifications = React.lazy(() =>
  import("./containers/SnackbarNotifications")
);

const MEASUREMENT_ID = "G-6S1TMT516B";

ReactGA.initialize(MEASUREMENT_ID);

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true
});

class App extends Component {
  render() {
    checkValidUrl();
    return (
      <Provider store={store}>
        <HashRouter>
          <ScrollToTop />
          <JssProvider generateClassName={generateClassName}>
            <MuiThemeProvider theme={muiTtheme}>
              <Fragment>
                <CssBaseline />
                <GlobalStyle />
                <ThemeProvider theme={theme}>
                  <Suspense
                    fallback={
                      <div
                        className="grey-out flex-center"
                        style={{ zIndex: "30", top: 0 }}
                      >
                        <CircularProgress color="inherit" />
                      </div>
                    }
                  >
                    <div className="aperture-main-content-height">
                      <SnackbarNotifications />
                      <RootLayout />
                    </div>
                  </Suspense>
                </ThemeProvider>
              </Fragment>
            </MuiThemeProvider>
          </JssProvider>
        </HashRouter>
      </Provider>
    );
  }
}

export default App;

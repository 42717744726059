import createWithActionCreator from "./helpers/createWithActionCreator";

// GET CUSTOMER SITES
export const GET_CUSTOMER_SITES_PENDING = "GET_CUSTOMER_SITES_PENDING";
export const GET_CUSTOMER_SITES_REJECTED = "GET_CUSTOMER_SITES_REJECTED";
export const GET_CUSTOMER_SITES_FULFILLED = "GET_CUSTOMER_SITES_FULFILLED";

export const getCustomerSitesPending = () => ({
  type: GET_CUSTOMER_SITES_PENDING
});

export const getCustomerSitesRejected = error => ({
  type: GET_CUSTOMER_SITES_REJECTED,
  payload: error
});

export const getCustomerSitesFulfilled = payload => {
  return {
    type: GET_CUSTOMER_SITES_FULFILLED,
    payload
  };
};

export const withGetCustomerSites = createWithActionCreator(
  "getCustomerSites",
  getCustomerSitesPending
);

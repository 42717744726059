import { call, put, takeEvery, delay } from "redux-saga/effects";
import * as notificationService from "../../services/notificationService";
import {
  NOTIFICATION_SEND,
  GET_NOTIFICATION_MESSAGES_PENDING,
  SHOW_CUSTOM_CLOSE_NOTIFICATION,
  getNotificationMessagesFulfilled,
  getNotificationMessagesRejected,
  notificationSend,
  notificationDismiss
} from "../actions/notificationActions";

import getResourceError from "./helpers/getResourceError";

export function* autoDismissNotification(action) {
  const { payload } = action;

  if (payload.dismissAfter) {
    yield delay(payload.dismissAfter);
    yield put(notificationDismiss(payload.id));
  }
}

export function* notificationMessages(action) {
  try {
    const notificationMessages = yield call(
      notificationService.notificationMessages
    );
    yield put(getNotificationMessagesFulfilled(notificationMessages));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "NOTIFICATION_MESSAGES",
      "Oops. An error occurred when fetching the notification messages."
    );
    yield put(getNotificationMessagesRejected(resourceError));
  }
}

export function* showCustomCloseNotification(error) {
  yield put(
    notificationSend({
      id: new Date(),
      label: error.payload.message,
      variant: "error",
      closeKey: error.payload.closeKey
    })
  );
}

function* watchNotificationSend() {
  yield takeEvery(NOTIFICATION_SEND, autoDismissNotification);
}

function* watchNotificationMessages() {
  yield takeEvery(GET_NOTIFICATION_MESSAGES_PENDING, notificationMessages);
}

function* watchShowCustomCloseNotification() {
  yield takeEvery(SHOW_CUSTOM_CLOSE_NOTIFICATION, showCustomCloseNotification);
}

export default [
  watchNotificationSend,
  watchNotificationMessages,
  watchShowCustomCloseNotification
];

import { call, put, takeEvery } from "redux-saga/effects";
import * as userService from "../../services/userService";
import * as assetService from "../../services/assetService";
import * as eulaService from "../../services/eulaService";
import {
  GET_AUTH_PENDING,
  GET_USER_PENDING,
  GET_SYSTEM_PENDING,
  GET_USER_LOGIN_PENDING,
  SIGNOUT_USER,
  SHOW_NOTIFICATIONS,
  getUserRejected,
  getUserFulfilled,
  getUserLoginRejected,
  getUserLoginFulfilled,
  getAuthPending,
  getAuthFulfilled,
  getSystemFulfilled,
  getAuthRejected,
  getSystemRejected,
  getSiteIdsFulfilled
} from "../actions/userActions";
import { getUserEulaResponseFulfilled } from "../actions/eulaActions";
import getResourceError from "./helpers/getResourceError";
import { notificationSend } from "../actions/notificationActions";
import { CONST_SUCCESS_RESPONSE } from "./constantsSaga";
import { validateUrlForSec } from "../../utils/clearsightUtils";
import {
  CREATE_ADD_ASSETS_ACTION_RESET_PENDING,
  addAssetsFulfilled,
  addAssetsRejected,
  getUserPreferenceFulfilled,
  getUserPreferenceRejected,
  GET_USER_PREFERENCE_PENDING
} from "../actions/assetActions";

export function* checkAuth(action) {
  try {
    const auth = yield call(userService.checkAuth);
    yield put(getAuthFulfilled(auth));
    if (auth && auth.auth) {
      const eula = yield call(eulaService.getUserEulaResponse);
      yield put(getUserEulaResponseFulfilled(eula));
      if (
        parseInt(eula.eula_status) !== 0 &&
        eula.eula_status !== null &&
        parseInt(eula.eula_status) >= parseInt(eula.eula_version)
      ) {
        const user = yield call(userService.getUser);
        if (user?.id == 0) {
          window.location.href = "/";
        }
        yield put(getUserFulfilled(user));
        if (user) {
          let assetInfoUrl = window?.location?.hash.indexOf("asset-info") > -1;
          let siteInfoUrl = window?.location?.hash.indexOf("site-info") > -1;
          if (user?.role == "INTUSER" && (assetInfoUrl || siteInfoUrl)) {
            try {
              const response = yield call(assetService.addAssets, {
                site_serial_list: [window?.location?.hash?.split("/")[2]]
              });
              yield put(addAssetsFulfilled(response));
            } catch (error) {
              const resourceError = yield call(
                getResourceError,
                error,
                "REMOVE",
                "ASSETS",
                "Oops. An error occurred when removing the asset."
              );
              yield put(addAssetsRejected(resourceError));
            }
          }
          yield put(getSiteIdsFulfilled(user.site_info));
        }

        // const userPreference = yield call(assetService.getUserPreference);
        {
          try {
            if (user?.role == "CUSTOMER") {
              const userPreference = yield call(assetService.getUserPreference);
              yield put(getUserPreferenceFulfilled(userPreference));
            }
          } catch (error) {
            const resourceError = yield call(
              getResourceError,
              error,
              "GET",
              "USER PREFERENCE",
              "Oops. An error occurred when fetching user preferences."
            );
            yield put(getUserPreferenceRejected(resourceError));
          }
        }
      }
    }
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "USER",
      "Oops. An error occurred when fetching the user."
    );
    yield put(getAuthRejected(resourceError));
  }
}

export function* checkSystem(action) {
  try {
    const checkSystem = yield call(userService.checkSystem);
    yield put(getSystemFulfilled(checkSystem));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "USER",
      "Oops. An error occurred when fetching the user."
    );
    yield put(getSystemRejected(resourceError));
  }
}

export function* showNotificationsOfAssets(action) {
  const { error_text, color } = action.payload;
  yield put(
    notificationSend({
      id: new Date(),
      label: error_text,
      variant: color && color === "green" ? "success" : "error",
      dismissAfter: 5000
    })
  );
}

export function* getUser(action) {
  try {
    const user = yield call(userService.getUser);
    yield put(getUserFulfilled(user));
    if (user) {
      yield put(getSiteIdsFulfilled(user.site_info));
    }
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "USER",
      "Oops. An error occurred when fetching the user."
    );
    yield put(getUserRejected(resourceError));
  }
}
export function* getUserPreference() {
  try {
    const userPreference = yield call(assetService.getUserPreference);
    yield put(getUserPreferenceFulfilled(userPreference));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "USER PREFERENCE",
      "Oops. An error occurred when fetching user preferences."
    );
    yield put(getUserPreferenceRejected(resourceError));
  }
}
export function* getUserLogin(action) {
  const params = action.payload;

  try {
    const user = yield call(userService.postUserLogin, params);
    yield put(getAuthPending());
    yield put(getUserLoginFulfilled(user));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "POST",
      "USER",
      "Oops. Login Failed"
    );
    yield put(getUserLoginRejected(resourceError));
  }
}

export function* signoutUser(action) {
  const contextSwitch = action?.context_switch;
  try {
    let response;
    if (contextSwitch != "") {
      response = yield call(
        userService.signoutUserWithContextSwitch,
        contextSwitch
      );
    } else {
      response = yield call(userService.signoutUser);
    }

    if (response.message === CONST_SUCCESS_RESPONSE) {
      //If environment is not local then reload sandbox till apache rewrite url in place.
      const host = window.location.host;
      if (host.indexOf("localhost") !== -1) {
        window.location = "/";
      } else {
        window.location.href = validateUrlForSec(response.logoutUrl);
      }
    } else {
      window.location.href = "/";
    }

    yield put(
      notificationSend({
        id: new Date(),
        label: "Successfully signed out.",
        variant: "success",
        dismissAfter: 5000
      })
    );
  } catch (error) {
    yield put(
      notificationSend({
        id: new Date(),
        label: "An error occurred during signout.",
        variant: "error",
        dismissAfter: 5000
      })
    );
  }
}

export function* addAssetsActionReset(action) {
  const { payload } = action.payload;
  yield put(addAssetsFulfilled(payload));
}

function* watchCheckAuth() {
  yield takeEvery(GET_AUTH_PENDING, checkAuth);
}

function* watchAddAssetsActionReset() {
  yield takeEvery(CREATE_ADD_ASSETS_ACTION_RESET_PENDING, addAssetsActionReset);
}

function* watchCheckSystem() {
  yield takeEvery(GET_SYSTEM_PENDING, checkSystem);
}

function* watchGetUser() {
  yield takeEvery(GET_USER_PENDING, getUser);
}

function* watchSignoutUser() {
  yield takeEvery(SIGNOUT_USER, signoutUser);
}

function* watchLoginUser() {
  yield takeEvery(GET_USER_LOGIN_PENDING, getUserLogin);
}

function* watchShowNotificationsOfAssets() {
  yield takeEvery(SHOW_NOTIFICATIONS, showNotificationsOfAssets);
}
function* watchGetUserPrefrence() {
  yield takeEvery(GET_USER_PREFERENCE_PENDING, getUserPreference);
}

export default [
  watchSignoutUser,
  watchGetUser,
  watchCheckAuth,
  watchGetUserPrefrence,
  watchCheckSystem,
  watchLoginUser,
  watchShowNotificationsOfAssets,
  watchAddAssetsActionReset
];

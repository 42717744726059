import api from "./api";
import URLS from "../utils/urls.js";
import { CONST_VALUES } from "../constants/constants";

export const alertsList = (site, params, product_category_id) => {
  const newParams = {};
  if (params["favourites"] || params["siteId"] === CONST_VALUES.favourites)
    newParams["favourites"] = true;
  else newParams["site_id"] = site;
  newParams["product_category_id"] = product_category_id;
  return api.post(URLS.BACKEND_API + `/customers/assets/get_alerts`, {
    ...newParams
  });
};

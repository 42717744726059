import { validateLocalStorageValue } from "../../utils/clearsightUtils";
import {
  SET_NAVIGATION,
  SET_INVENTORY_SORT_ID,
  SET_INVENTORY_TABLE_VIEW,
  SET_INVENTORY_TABLE_VIEW_SORT,
  SET_ASSET_DETAILS_TAB,
  SET_ALERT_CATEGORY,
  SET_STORAGE_OPTION
} from "../actions/navigationActions";

const initialState = {
  site: null,
  inventorySortId: 7,
  inventoryTableView: false,
  inventoryTableViewSortOrder: "desc",
  inventoryTableViewSortId: "",
  assetDetailsTab: "capacityAndEnvironmental",
  alertCategory: null,
  storageOption: "overview"
};

if (validateLocalStorageValue(localStorage.getItem("storageOption")) !== -1) {
  window.localStorage.setItem("storageOption", "overview");
}
export default function(state = initialState, action) {
  switch (action.type) {
    case SET_NAVIGATION:
    case SET_INVENTORY_SORT_ID:
    case SET_INVENTORY_TABLE_VIEW:
    case SET_INVENTORY_TABLE_VIEW_SORT:
    case SET_ASSET_DETAILS_TAB:
    case SET_ALERT_CATEGORY:
    case SET_STORAGE_OPTION:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}

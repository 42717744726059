import {
  CREATE_CREDENTIALS_DETAILS_PENDING,
  CREATE_CREDENTIALS_DETAILS_FULFILLED,
  CREATE_CREDENTIALS_DETAILS_REJECTED,
  UPDATE_CREATE_CREDENTIALS_STATE,
  GET_CREDENTIALS_DETAILS_PENDING,
  GET_CREDENTIALS_DETAILS_FULFILLED,
  GET_CREDENTIALS_DETAILS_REJECTED
} from "../actions/assetActions";

const initialState = {
  createCredentials: {
    data: [],
    isLoading: false,
    error: null,
    success: false
  },
  getCredentials: {
    data: [],
    isLoading: false,
    error: null
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CREATE_CREDENTIALS_DETAILS_PENDING:
      return {
        ...state,
        createCredentials: {
          ...state.createCredentials,
          isLoading: true,
          error: null,
          data: {},
          success: false
        }
      };
    case CREATE_CREDENTIALS_DETAILS_FULFILLED:
      return {
        ...state,
        createCredentials: {
          ...state.createCredentials,
          isLoading: false,
          error: null,
          data: action.payload,
          success: true
        }
      };
    case UPDATE_CREATE_CREDENTIALS_STATE:
      return {
        ...state,
        createCredentials: {
          ...state.createCredentials,
          ...action.payload
        }
      };
    case CREATE_CREDENTIALS_DETAILS_REJECTED:
      return {
        ...state,
        createCredentials: {
          ...state.createCredentials,
          isLoading: false,
          error: action.error,
          success: false
        }
      };

    case GET_CREDENTIALS_DETAILS_PENDING:
      return {
        ...state,
        getCredentials: {
          ...state.getCredentials,
          isLoading: true,
          error: null,
          data: []
        }
      };
    case GET_CREDENTIALS_DETAILS_FULFILLED:
      return {
        ...state,
        getCredentials: {
          ...state.getCredentials,
          isLoading: false,
          error: null,
          data: action.payload
        }
      };
    case GET_CREDENTIALS_DETAILS_REJECTED:
      return {
        ...state,
        getCredentials: {
          ...state.getCredentials,
          isLoading: false,
          error: action.error
        }
      };

    default:
      return state;
  }
}

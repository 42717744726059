import { call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import * as sustainabilityService from "../../services/sustainabilityService";
import getResourceError from "./helpers/getResourceError";
import {
  GET_SUSTAINABILITY_PENDING,
  getSustainabilityRejected,
  getSustainabilityAssetFulfilled,
  getSustainabilityAssetRejected,
  PATCH_DISMISS_RECOMMENDATION_PENDING,
  PATCH_ADD_BOOKMARK_PENDING,
  UPDATE_RENEWABLE_ENERGY_PENDING,
  updateRenewableEnergyFulfilled,
  updateRenewableEnergyRejected,
  patchDismissRecommendationRejected,
  patchAddBookmarkRejected,
  getAssetManagerUrlFulfilled,
  getAssetManagerUrlRejected,
  GET_SUSTAINABILITY_MANAGER_PENDING
} from "../actions/sustainabilityAction";

export function* patchDismissRecommendation(action) {
  const { payload } = action;
  const params = payload?.params;
  const data = payload?.data;

  try {
    const response = yield call(
      sustainabilityService.dismissRecommendation,
      params
    );

    if (response.message === "success") {
      const updatedArray = updateDismissRecommendationArray(
        data?.recommendations,
        params
      );

      let newArray = {
        ...data,
        ["recommendations"]: updatedArray
      };
      yield put(getSustainabilityAssetFulfilled(newArray));
    }
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "DISMISS RECOMMENDATION",
      "Oops. An error occurred while dismissing the recommendation."
    );
    yield put(patchDismissRecommendationRejected(resourceError));
  }
}

function updateDismissRecommendationArray(data, params, type) {
  return data.map(obj => {
    const {
      serial_id,
      site_id,
      recommendation_id,
      product_family_id,
      ...rest
    } = obj;

    if (
      serial_id == params.serial_id &&
      site_id == params.site_id &&
      recommendation_id == params.recommendation_id &&
      product_family_id == params.product_family_id
    ) {
      return {
        serial_id,
        site_id,
        recommendation_id,
        product_family_id,
        ...rest,
        is_dismissed: true
      };
    }
    return obj;
  });
}

function updateBookmarkArray(data, params, type) {
  return data.map(obj => {
    const {
      serial_id,
      site_id,
      recommendation_id,
      product_family_id,
      ...rest
    } = obj;

    if (
      serial_id == params.serial_id &&
      site_id == params.site_id &&
      recommendation_id == params.recommendation_id &&
      product_family_id == params.product_family_id
    ) {
      return {
        serial_id,
        site_id,
        recommendation_id,
        product_family_id,
        ...rest,
        is_bookmarked: type == "add" ? true : false
      };
    }
    return obj;
  });
}

export function* patchAddBookmark(action) {
  const { payload } = action;
  const params = payload?.params;
  const data = payload?.data;
  const type = payload?.type;

  try {
    let response;
    if (type === "add") {
      response = yield call(sustainabilityService.addBookmark, params);
    } else {
      response = yield call(sustainabilityService.deleteBookmark, params);
    }

    if (response?.message == "success") {
      const updatedArray = updateBookmarkArray(
        data?.recommendations,
        params,
        type
      );
      let newArray = {
        ...data,
        ["recommendations"]: updatedArray
      };
      yield put(getSustainabilityAssetFulfilled(newArray));
    }
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "ADD/DELETE BOOKMARK",
      "Oops. An error occurred while adding or deleting bookmark."
    );
    yield put(patchAddBookmarkRejected(resourceError));
  }
}

function* getSustainabilityInfoAsset() {
  try {
    const sustainabilityAssetDetails = yield call(
      sustainabilityService.getSustainabilityInfoAsset
    );
    yield put(getSustainabilityAssetFulfilled(sustainabilityAssetDetails));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "SUSTAINABILITY_ASSETS_LIST",
      "Oops. An error occurred when fetching the sustainability assets list."
    );
    yield put(getSustainabilityAssetRejected(resourceError));
  }
}

export function* getSustainability(action) {
  try {
    yield fork(getSustainabilityInfoAsset);
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "SUSTAINABILITY",
      "Oops. An error occurred when fetching the sustainable details."
    );
    yield put(getSustainabilityRejected(resourceError));
  }
}

export function* updateRenewableEnergyPercent(action) {
  try {
    const response = yield call(
      sustainabilityService.updateRenewableEnergy,
      action.payload
    );
    yield put(updateRenewableEnergyFulfilled(response));
    yield put(getSustainabilityAssetFulfilled([]));
    yield fork(getSustainability);
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "ADD",
      "RENEWABLEENERGY",
      "Oops. An error occurred when adding the energy."
    );
    yield put(updateRenewableEnergyRejected(resourceError));
  }
}

export function* getAssetManagerUrl(action) {
  const { serialNumber, siteId, productFamilyId, params } = action.payload;
  const isPopupClose = params?.isPopupClose;
  try {
    const asset =
      !isPopupClose &&
      (yield call(
        sustainabilityService.getAssetManagerUrl,
        siteId,
        serialNumber,
        productFamilyId,
        params
      ));
    !isPopupClose
      ? yield put(getAssetManagerUrlFulfilled(asset))
      : yield put(getAssetManagerUrlFulfilled(null));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "ASSET",
      "Oops. An error occurred when fetching the url."
    );
    // yield put(getAssetManagerUrlFulfilled(null));
    yield put(getAssetManagerUrlRejected(resourceError));
  }
}

function* watchGetSustainability() {
  yield takeEvery(GET_SUSTAINABILITY_PENDING, getSustainability);
}

function* watchGetAssetManagerUrl() {
  yield takeEvery(GET_SUSTAINABILITY_MANAGER_PENDING, getAssetManagerUrl);
}

function* watchUpdateRenewableEnergy() {
  yield takeLatest(
    UPDATE_RENEWABLE_ENERGY_PENDING,
    updateRenewableEnergyPercent
  );
}

function* watchPatchDismissRecommendation() {
  yield takeEvery(
    PATCH_DISMISS_RECOMMENDATION_PENDING,
    patchDismissRecommendation
  );
}

function* watchAddBookmark() {
  yield takeEvery(PATCH_ADD_BOOKMARK_PENDING, patchAddBookmark);
}

export default [
  watchGetSustainability,
  watchUpdateRenewableEnergy,
  watchPatchDismissRecommendation,
  watchAddBookmark,
  watchGetAssetManagerUrl
];

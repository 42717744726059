export default {
  REDIRECT_DEV:
    "https://higdev01.oktapreview.com/app/hitachivantarapreview_toolsdev_1/exkkqr584uSvFUyPF0h7/sso/saml",
  REDIRECT_DEV2:
    "https://higdev01.oktapreview.com/app/hitachivantarapreview_guru20_1/exkuh0rz90BNmYPUC0h7/sso/saml",
  REDIRECT_UAT:
    "https://higdev01.oktapreview.com/app/hitachivantarapreview_toolsuat_1/exkqzavxjy85XNrhh0h7/sso/saml",
  REDIRECT_PROD:
    "https://hitachivantara.okta.com/app/hitachivantara_aperture_1/exkau28suyM094Ngf2p7/sso/saml",
  USERNAME_GET: "/api/auth/getUserDetails/",
  //for local
  // BACKEND_API: "",
  BACKEND_API: "/ap",
  // BACKEND_API: "/lh",
  //BACKEND_API: "http://localhost:8000",
  SANDBOX_URL: "localhost",
  LOCAL_HOST: "localhost",
  SIGN_OUT: "/ssoapp/signout"
};

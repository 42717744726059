import createWithActionCreator from "./helpers/createWithActionCreator";

// PUT USER EULA RESPONSE
export const PUT_USER_EULA_RESPONSE_PENDING = "PUT_USER_EULA_RESPONSE_PENDING";
export const PUT_USER_EULA_RESPONSE_REJECTED =
  "PUT_USER_EULA_RESPONSE_REJECTED";
export const PUT_USER_EULA_RESPONSE_FULFILLED =
  "PUT_USER_EULA_RESPONSE_FULFILLED";

export const putUserEulaResponsePending = payload => ({
  type: PUT_USER_EULA_RESPONSE_PENDING,
  payload: payload
});

export const putUserEulaResponseRejected = error => ({
  type: PUT_USER_EULA_RESPONSE_REJECTED,
  payload: error
});

export const putUserEulaResponseFulfilled = payload => {
  return {
    type: GET_USER_EULA_RESPONSE_FULFILLED,
    payload
  };
};

// GET USER EULA RESPONSE
export const GET_USER_EULA_RESPONSE_PENDING = "GET_USER_EULA_RESPONSE_PENDING";
export const GET_USER_EULA_RESPONSE_REJECTED =
  "GET_USER_EULA_RESPONSE_REJECTED";
export const GET_USER_EULA_RESPONSE_FULFILLED =
  "GET_USER_EULA_RESPONSE_FULFILLED";

export const getUserEulaResponsePending = () => ({
  type: GET_USER_EULA_RESPONSE_PENDING
});

export const getUserEulaResponseRejected = error => ({
  type: GET_USER_EULA_RESPONSE_REJECTED,
  payload: error
});

export const getUserEulaResponseFulfilled = payload => {
  return {
    type: GET_USER_EULA_RESPONSE_FULFILLED,
    payload
  };
};

export const withUserEulaResponse = createWithActionCreator(
  "userEulaResponse",
  putUserEulaResponsePending
);

export const withGetUserEulaResponse = createWithActionCreator(
  "getUserEulaResponse",
  getUserEulaResponsePending
);

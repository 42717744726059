import createRemoteResourceReducerCreator from "./createRemoteResourceReducerCreator";
import { initialRemoteArrayState } from "./remoteResourceInitialStates";
import { getRemoteArrayActionExpression } from "./actionExpressions";
import {
  createPendingAsyncStateReducer,
  createRejectedAsyncStateReducer,
  createFulfilledAsyncStateReducer,
  deleteFulfilledAsyncStateReducer,
  deletePendingAsyncStateReducer,
  deleteRejectedAsyncStateReducer,
  getPendingAsyncStateReducer,
  getCancelledAsyncStateReducer,
  getFulfilledAsyncStateReducer,
  getRejectedAsyncStateReducer,
  patchPendingAsyncStateReducer,
  patchRejectedAsyncStateReducer,
  patchFulfilledAsyncStateReducer
} from "./remoteResourceAsyncStateReducers";
import {
  createFulfilledArrayValueReducer,
  deleteFulfilledArrayValueReducer,
  getFulfilledArrayValueReducer,
  patchFulfilledArrayValueReducer
} from "./remoteArrayValueReducers";
import { setErrorReducer } from "./remoteResourceSetOperationReducers";

const operationReducers = {
  CREATE: {
    PENDING: (state, action) => ({
      ...state,
      ...createPendingAsyncStateReducer(state, action)
    }),
    REJECTED: (state, action) => ({
      ...state,
      ...createRejectedAsyncStateReducer(state, action)
    }),
    FULFILLED: (state, action) => ({
      ...state,
      ...createFulfilledAsyncStateReducer(state, action),
      ...createFulfilledArrayValueReducer(state, action)
    })
  },
  DELETE: {
    PENDING: (state, action) => ({
      ...state,
      ...deletePendingAsyncStateReducer(state, action)
    }),
    REJECTED: (state, action) => ({
      ...state,
      ...deleteRejectedAsyncStateReducer(state, action)
    }),
    FULFILLED: (state, action) => ({
      ...state,
      ...deleteFulfilledAsyncStateReducer(state, action),
      ...deleteFulfilledArrayValueReducer(state, action)
    })
  },
  GET: {
    PENDING: (state, action) => ({
      ...state,
      ...getPendingAsyncStateReducer(state, action)
    }),
    NEXT_PENDING: (state, action) => ({
      ...state,
      ...getPendingAsyncStateReducer(state, action)
    }),
    REJECTED: (state, action) => ({
      ...state,
      ...getRejectedAsyncStateReducer(state, action)
    }),
    CANCELLED: (state, action) => ({
      ...state,
      ...getCancelledAsyncStateReducer(state, action)
    }),
    FULFILLED: (state, action) => ({
      ...state,
      ...getFulfilledAsyncStateReducer(state, action),
      ...getFulfilledArrayValueReducer(state, action)
    })
  },
  PATCH: {
    PENDING: (state, action) => ({
      ...state,
      ...patchPendingAsyncStateReducer(state, action)
    }),
    REJECTED: (state, action) => ({
      ...state,
      ...patchRejectedAsyncStateReducer(state, action)
    }),
    FULFILLED: (state, action) => ({
      ...state,
      ...patchFulfilledAsyncStateReducer(state, action),
      ...patchFulfilledArrayValueReducer(state, action)
    })
  },
  SET: {
    ERROR: (state, action) => ({
      ...state,
      ...setErrorReducer(state, action)
    })
  }
};

export default createRemoteResourceReducerCreator(
  operationReducers,
  getRemoteArrayActionExpression,
  initialRemoteArrayState
);

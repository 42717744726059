import createWithActionCreator from "./helpers/createWithActionCreator";

// SecurityRecommendations Actions
export const GET_SECURITY_RECOMMENDATION_PENDING =
  "GET_SECURITY_RECOMMENDATION_PENDING";
export const GET_SECURITY_RECOMMENDATION_REJECTED =
  "GET_SECURITY_RECOMMENDATION_REJECTED";
export const GET_SECURITY_RECOMMENDATION_FULFILLED =
  "GET_SECURITY_RECOMMENDATION_FULFILLED";

// SECURITY RECOMMENDATION ACTION DEFINITION
export const getSecurityRecommendationPending = params => {
  return {
    type: GET_SECURITY_RECOMMENDATION_PENDING,
    payload: params
  };
};
export const getSecurityRecommendationFulfilled = data => ({
  type: GET_SECURITY_RECOMMENDATION_FULFILLED,
  payload: data
});

export const getSecurityRecommendationRejected = error => ({
  type: GET_SECURITY_RECOMMENDATION_REJECTED,
  error
});

export const withGetSecurityRecommendation = createWithActionCreator(
  "getSecurityRecommendation",
  getSecurityRecommendationPending
);

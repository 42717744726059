import createWithActionCreator from "./helpers/createWithActionCreator";

export const GET_SUSTAINABILITY_PENDING = "GET_SUSTAINABILITY_PENDING";
export const GET_SUSTAINABILITY_REJECTED = "GET_SUSTAINABILITY_REJECTED";

// SUSTAINABILITY ASSET MANAGER URL ACTIONS
export const GET_SUSTAINABILITY_MANAGER_PENDING =
  "GET_SUSTAINABILITY_MANAGER_PENDING";
export const GET_SUSTAINABILITY_MANAGER_REJECTED =
  "GET_SUSTAINABILITY_MANAGER_REJECTED";
export const GET_SUSTAINABILITY_MANAGER_FULFILLED =
  "GET_SUSTAINABILITY_MANAGER_FULFILLED";

// SUSTAINABILITY ASSET ACTIONS

export const GET_SUSTAINABILITY_ASSET_PENDING =
  "GET_SUSTAINABILITY_ASSET_PENDING";
export const GET_SUSTAINABILITY_ASSET_REJECTED =
  "GET_SUSTAINABILITY_ASSET_REJECTED";
export const GET_SUSTAINABILITY_ASSET_FULFILLED =
  "GET_SUSTAINABILITY_ASSET_FULFILLED";

// SUSTAINABILITY INFO AT ASSET LEVEL
export const GET_SUSTAINABILITY_ASSET_INFO_PENDING =
  "GET_SUSTAINABILITY_ASSET_INFO_PENDING";
export const GET_SUSTAINABILITY_ASSET_INFO_REJECTED =
  "GET_SUSTAINABILITY_ASSET_INFO_REJECTED";
export const GET_SUSTAINABILITY_ASSET_INFO_FULFILLED =
  "GET_SUSTAINABILITY_ASSET_INFO_FULFILLED";

export const PATCH_DISMISS_RECOMMENDATION_PENDING =
  "PATCH_DISMISS_RECOMMENDATION_PENDING";
export const PATCH_DISMISS_RECOMMENDATION_REJECTED =
  "PATCH_DISMISS_RECOMMENDATION_REJECTED";
export const PATCH_DISMISS_RECOMMENDATION_FULFILLED =
  "PATCH_DISMISS_RECOMMENDATION_FULFILLED";

export const PATCH_ADD_BOOKMARK_PENDING = "PATCH_ADD_BOOKMARK_PENDING";
export const PATCH_ADD_BOOKMARK_REJECTED = "PATCH_ADD_BOOKMARK_REJECTED";
export const PATCH_ADD_BOOKMARK_FULFILLED = "PATCH_ADD_BOOKMARK_FULFILLED";

// EnergyRenwable Actions
export const UPDATE_RENEWABLE_ENERGY_PENDING =
  "UPDATE_RENEWABLE_ENERGY_PENDING";
export const UPDATE_RENEWABLE_ENERGY_REJECTED =
  "UPDATE_RENEWABLE_ENERGY_REJECTED";
export const UPDATE_RENEWABLE_ENERGY_FULFILLED =
  "UPDATE_RENEWABLE_ENERGY_FULFILLED";

// ENERGY UPDATE ACTION DEFINITION
export const updateRenewableEnergyPending = params => {
  return {
    type: UPDATE_RENEWABLE_ENERGY_PENDING,
    payload: params
  };
};
export const updateRenewableEnergyFulfilled = data => ({
  type: UPDATE_RENEWABLE_ENERGY_FULFILLED,
  data
});

export const updateRenewableEnergyRejected = error => ({
  type: UPDATE_RENEWABLE_ENERGY_REJECTED,
  error
});

export const getAssetManagerUrlPending = (
  serialNumber,
  siteId,
  productFamilyId,
  params
) => ({
  type: GET_SUSTAINABILITY_MANAGER_PENDING,
  payload: { serialNumber, siteId, productFamilyId, params }
});

export const getAssetManagerUrlRejected = error => ({
  type: GET_SUSTAINABILITY_MANAGER_REJECTED,
  payload: error
});

export const getAssetManagerUrlFulfilled = payload => {
  return {
    type: GET_SUSTAINABILITY_MANAGER_FULFILLED,
    payload
  };
};

export const getSustainabilityPending = params => {
  return {
    type: GET_SUSTAINABILITY_PENDING,
    payload: { params }
  };
};

export const getSustainabilityRejected = error => ({
  type: GET_SUSTAINABILITY_REJECTED,
  payload: error
});

// SUSTAINABILITY ASSET ACTION DEFINITION

export const getSustainabilityAssetPending = params => {
  return {
    type: GET_SUSTAINABILITY_ASSET_PENDING,
    payload: { params }
  };
};

export const getSustainabilityAssetRejected = error => ({
  type: GET_SUSTAINABILITY_ASSET_REJECTED,
  payload: error
});

export const getSustainabilityAssetFulfilled = error => ({
  type: GET_SUSTAINABILITY_ASSET_FULFILLED,
  payload: error
});

// SUSTAINABILITY ASSET INFO ACTION DEFINITION

export const getSustainabilityAssetInfoPending = params => {
  return {
    type: GET_SUSTAINABILITY_ASSET_INFO_PENDING,
    payload: { params }
  };
};

export const getSustainabilityAssetInfoRejected = error => ({
  type: GET_SUSTAINABILITY_ASSET_INFO_REJECTED,
  payload: error
});

export const getSustainabilityAssetInfoFulfilled = error => ({
  type: GET_SUSTAINABILITY_ASSET_INFO_FULFILLED,
  payload: error
});

export const patchDismissRecommendationPending = (params, data) => ({
  type: PATCH_DISMISS_RECOMMENDATION_PENDING,
  payload: {
    params,
    data: data
  }
});

export const patchDismissRecommendationRejected = error => ({
  type: PATCH_DISMISS_RECOMMENDATION_REJECTED,
  payload: error
});

export const patchDismissRecommendationFulfilled = payload => {
  return {
    type: PATCH_DISMISS_RECOMMENDATION_FULFILLED,
    payload
  };
};

export const patchAddBookmarkPending = (params, data, type) => ({
  type: PATCH_ADD_BOOKMARK_PENDING,
  payload: {
    params,
    data: data,
    type: type
  }
});

export const patchAddBookmarkRejected = error => ({
  type: PATCH_ADD_BOOKMARK_REJECTED,
  payload: error
});

export const patchAddBookmarkFulfilled = payload => {
  return {
    type: PATCH_ADD_BOOKMARK_FULFILLED,
    payload
  };
};

export const withGetSustainability = createWithActionCreator(
  "getSustainability",
  getSustainabilityPending
);

export const withGetAssetManagerUrl = createWithActionCreator(
  "getAssetManagerUrl",
  getAssetManagerUrlPending
);

export const withUpdateRenewableEnergy = createWithActionCreator(
  "updateRenewableEnergyPercent",
  updateRenewableEnergyPending
);

export const withPatchDismissRecommendation = createWithActionCreator(
  "patchDismissRecommendation",
  patchDismissRecommendationPending
);

export const withPatchAddBookmark = createWithActionCreator(
  "patchAddBookmark",
  patchAddBookmarkPending
);

export const initialAsyncState = {
  loading: false,
  error: null
};

export const initialPagingMetaDataState = {
  lastPageFetched: null
};

export const initialPollingState = {
  lastPollResult: null,
  polling: false,
  pollStarted: false
};

const initialValueState = {
  value: null,
  version: null
};

export const initialObjectValueState = {
  ...initialValueState
};

export const initialArrayValueState = {
  ...initialValueState
};

export const initialEntityCountState = {
  entityCount: null,
  lastEntityCountCheckedTime: null,
  loadingEntityCount: false
};

export const initialRemoteObjectState = {
  ...initialAsyncState,
  ...initialValueState
};

export const initialRemoteArrayState = {
  ...initialAsyncState,
  ...initialArrayValueState
};

export const initialRemotePagedArrayState = {
  ...initialAsyncState,
  ...initialArrayValueState,
  ...initialPagingMetaDataState
};

export const initialRemotePolledPagedArrayState = {
  ...initialAsyncState,
  ...initialArrayValueState,
  ...initialPagingMetaDataState,
  ...initialPollingState,
  ...initialEntityCountState
};

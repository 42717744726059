import { css } from "styled-components";
import { rem } from "polished";
import {
  SPACE_TINY,
  SPACE_SMALL,
  SPACE_DEFAULT,
  SPACE_LARGE,
  SPACE_EXTRA_LARGE,
  SPACE_EXTRA_EXTRA_LARGE
} from "./helpers/constants";
import { breakpoint } from "./helpers/breakpoints";

export default {
  BreadCrumbWrapper: {
    style: css`
      & > div > div > div > span {
        cursor: pointer;
      }
    `
  },
  Critical: {
    style: css`
      margin-right: 0px;
    `
  },
  Warning: {
    style: css`
      && {
        fill: rgb(219, 107, 34);
        margin-right: 0px;
      }
    `
  },
  Attention: {
    style: css`
      && {
        fill: #e68c17;
        margin-right: 0px;
      }
    `
  },
  Good: {
    style: css`
      && {
        fill: #63A621
        margin-right: 0px;
      };
    `
  },
  Unknown: {
    style: css`
      && {
        fill: #bcbcbc;
        margin-right: 0px;
      }
    `
  },
  SettingsIcon: {
    style: css`
      && {
        // margin-right: 8px;
        fill: white;
        border-radius: 50%;
        background: #bcbcbc;
        padding: 1.5px;
        font-size: 22px;
      }
    `
  },
  Caption: {
    style: css`
      font-size: 12px;
      color: #4e4e4e;
    `
  },
  Dropdown: {
    style: css`
      && {
        max-width: 100%;
        min-width: 100%;
      }
    `
  },
  Footer: {
    FooterWrapper: {
      style: css`
        background-color: #484849;
        height: 40px;
        color: white;
        display: grid;
        grid-template-columns: auto 1fr auto;
        align-items: center;
        padding: 0px 15px;
      `
    }
  },
  Header: {
    FeedbackIcon: {
      style: css`
        && {
          background-color: #cc0000;
          height: 100%;
          width: 50px;
          padding: 8px;
          cursor: pointer;
        }
      `
    },
    HeaderWrapper: {
      style: css`
        overflow-x: auto;
        overflow-y: hidden;
        border-bottom: 1px solid lightgrey;

        & > header > div {
          padding-right: 0px;
        }

        & > header > div > div:nth-of-type(2) {
          justify-content: center;
        }
      `
    }
  },
  Login: {
    ContentWrapper: {
      style: css`
        height: calc(100vh - 90px);
        overflow: auto;
      `
    }
  },
  IconWrapper: {
    style: css`
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
    `
  },
  NoResults: {
    DocIcon: {
      style: css`
        & > g > path {
          fill: #bcbcbc;
        }
      `
    },
    Info: {
      style: css`
        && {
          fill: #999999;
        }
      `
    },
    NoReultsWrapper: {
      style: css`
        display: grid;
        // grid-template-columns: 1fr auto 1fr 1fr;
        grid-gap: 20px;
        margin-top: 50px;
        width: 100%;
        ${p => p.tableView && "text-align: center;"}
        // @media ${breakpoint.xm} and (max-width: 400px){
        //   grid-template-columns: auto auto 1fr 1fr;;
        //   grid-gap: 5px;
        //   text-align: left;
        //   margin-left:40px;
        // }
        @media ${breakpoint.mw} {
          grid-template-columns: auto auto 1fr 1fr;;
          grid-gap: 5px;
          text-align: left;
          margin-left:40px;
          display:flex;
        }
      `
    },
    NoInventoryWrapper: {
      style: css`
        display: grid;
        align-items: center;
        grid-template-columns: ${p =>
          p.tableView ? "1fr auto auto 1fr" : "auto 1fr"};
        grid-gap: ${p => (p.tableView ? "10px" : "0px")};
        @media ${breakpoint.xm} and (max-width: 400px) {
          grid-template-columns: auto auto 1fr 1fr;
          grid-gap: 5px;
          margin-left: 40px;
        }
      `
    }
  },
  SideNav: {
    SideNavWrapper: {
      style: css`
        width: 0px;
        padding: 0px;
        color: #5f5f5f;
        background-color: white;

        @media ${breakpoint.sm} {
          width: 180px;
          padding: 30px 8px 15px 8px;
        }
      `
    },
    MenuTitle: {
      style: css`
        font-size: 16px;
        font-weight: 500;
        padding-left: 10px;
      `
    },
    MenuItem: {
      style: css`
        font-size: 16px;
        padding: 5px 8px 5px 25px;
        margin: 0px;
        border-bottom: 1px solid white;

        &:hover {
          background-color: #484849;
          color: white;
          cursor: pointer;
        }

        &.selected {
          background-color: #484849;
          color: white;
        }
      `
    }
  },
  SearchInput: {
    SearchIcon: {
      style: css`
        margin: 0px 8px;
        height: 100%;
        && {
          fill: #414141;
          position: absolute;
          right: 0;
          cursor: pointer;
        }
      `
    },
    SearchIconWrapper: {
      style: css`
        height: 100%;
        display: grid;
        align-items: center;
        cursor: pointer;
        margin: auto;
      `
    },
    SearchWrapper: {
      style: css`
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        grid-gap: 20px;
        margin-bottom: 20px;

        @media ${breakpoint.sm} {
          max-width: 400px;
          margin-bottom: 30px;
        }
      `
    },
    TextField: {
      style: css`
        && {
          padding: 0px 10px;
        }
        height: 32px;

        * {
          height: 36px;
        }
      `
    },
    Wrapper: {
      style: css`
        display: grid;
        display: -ms-flexbox;
        grid-template-columns: auto 40px;
        align-items: center;
        background-color: white;

        ${p => p.border && "border: 2px solid #bcbcbc;"}
        ${p => p.border && "padding: 3px 0px 3px 5px;"}
      `
    }
  },
  Spacer: {
    CustomSpacer: {
      style: css`
        display: flex;
      `,
      dividerAlignment: {
        bottom: {
          style: css`
            align-items: flex-end;
          `
        },
        center: {
          style: css`
            align-items: center;
          `
        },
        top: {
          style: css`
            align-items: flex-start;
          `
        }
      },
      height: {
        none: {
          style: css`
            height: ${p => (p.divider ? "1px" : "0px")};
          `
        },
        extraSmall: {
          style: css`
            height: ${rem(SPACE_TINY)};
          `
        },
        small: {
          style: css`
            height: ${rem(SPACE_SMALL)};
          `
        },
        medium: {
          style: css`
            height: ${rem(SPACE_DEFAULT)};
          `
        },
        large: {
          style: css`
            height: ${rem(SPACE_LARGE)};
          `
        },
        extraLarge: {
          style: css`
            height: ${rem(SPACE_EXTRA_LARGE)};
          `
        },
        extraExtraLarge: {
          style: css`
            height: ${rem(SPACE_EXTRA_EXTRA_LARGE)};
          `
        },
        custom: {
          style: css`
            height: ${props => rem(props.customSizeInRem)};
          `
        }
      }
    },
    Divider: {
      style: css`
        width: 100%;
      `
    }
  },
  Table: {
    TableSection: {
      style: css`
        background: white;
        border: 1px solid rgba(224, 224, 224, 1);
        border-collapse: separate !important;
      `
    },
    CheckCircle: {
      style: css`
        && {
          fill: #86b779;
        }
      `
    },
    Info: {
      style: css`
        && {
          fill: #dab369;
        }
      `
    },
    Warning: {
      style: css`
        && {
          fill: #e04c6e;
        }
      `
    },
    TableCell: {
      style: css`
        && {
          padding: 0px 0px 0px 12px;
          font-size: 14px;
        }
      `
    },
    TableRow: {
      style: css`
        &&:hover {
          // background-color: #f0f0f0;
          cursor: pointer;
        }
      `
    },
    TablePagination: {
      style: css`
        border-bottom: 0 !important;
        & > div {
          display: -ms-grid;
          display: grid;
          -ms-grid-columns: auto auto auto auto auto auto 1fr;
          grid-template-columns: auto auto auto auto auto auto 1fr;
          justify-items: center;
          align-items: center;
          white-space: nowrap;
          padding: 0px;
          min-height: 44px;
        }

        &>div>span: nth-of-type(1) {
          -ms-grid-row: 1;
          -ms-grid-column: 1;
          display: block;
        }

        &>div>div: nth-of-type(2) {
          -ms-grid-row: 1;
          -ms-grid-column: 2;
          display: block;
          margin-right: 16px;
          font-size: 10px;
          line-height: 12px;
          font-weight: 400;
          color: #414141;
          min-width: 42px;

          & .MuiInputBase-input {
          }

          & > div {
            min-height: auto;
            opacity: 0;
            min-width: 14px !important;
          }
        }

        &>div>span: nth-of-type(3) {
          -ms-grid-row: 1;
          -ms-grid-column: 3;
          display: block;
          margin-left: 10px;
        }

        .MuiSelect-select {
          padding: 6px 23px 6px 8px;
        }

        .MuiIconButton-disabled span svg g g *,
        .MuiIconButton-disabled span svg g path {
          fill: rgba(0, 0, 0, 0.26);
        }

        ,
        .MuiTablePagination-selectRoot {
          margin-right: 10px;
        }

        .MuiTablePagination-select {
          text-align-last: left !important;
        }
      `
    },
    TableProgressWrapper: {
      style: css`
        display: flex;
        align-items: center;
        white-space: nowrap;
        flex-direction: column;
        align-items: flex-start;
        && .progress {
          width: 170px;
        }
        @media ${breakpoint.l} {
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          && .capacity-column {
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
          }
          // && .progress {
          //   width: 35%;
          // }
        }
      `
    }
  },
  TableHeader: {
    style: css`
      && {
        font-size: 12px;
        color: #000000de;
        font-weight: 600;
        // padding: 10px 6px;
        background-color: white;

        @media ${breakpoint.sm} {
          padding: 8.5px 15px;
        }
      }
    `
  },
  TableSortLabel: {
    style: css`
      && {
        position: relative;
        left: -30px;
        flex-direction: revert;
      }
    `
  },
  Title: {
    style: css`
      display: block;
      margin-top: 20px;
      margin-bottom: 40px;

      @media (max-width: 600px) {
        && {
          font-size: 18px;
          line-height: 32px;
          margin-top: 10px;
          margin-bottom: 30px;
        }
      }
    `
  },
  SortDefault: {
    style: css`
      && {
        width: 30px;
        height: 30px;
      }
    `
  },
  Textarea: {
    style: css`
      width: 100%;
      max-width: 100%;
      color: #414141;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      letter-spacing: 0.02em;
      padding: 5px 10px;
      min-height: 30px;
      border: 1px solid #bcbcbc;
    `
  },
  UserIcon: {
    style: css`
      padding: 8px;
    `
  }
};

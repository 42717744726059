import api from "./api";
import URLS from "../utils/urls.js";

export const entitlementCategories = () => {
  return api.get(
    URLS.BACKEND_API + `/api/permissions/get_entitlement_categories`
  );
};

export const permissionsList = () => {
  return api.get(URLS.BACKEND_API + `/api/permissions/permissionslist`);
};

export const postPermissions = payload => {
  return api.post(URLS.BACKEND_API + `/api/permissions/addnew`, { ...payload });
};

export const putPermissions = payload => {
  return api.put(URLS.BACKEND_API + `/api/permissions/edit`, { ...payload });
};

export const deletePermissions = payload => {
  return api.post(URLS.BACKEND_API + `/api/permissions/delete`, payload);
};

export const existingEmailLists = payload => {
  return api.get(
    URLS.BACKEND_API +
      `/api/permissions/get_shared_with_other_users_info/${payload.number}/${payload.siteId}`
  );
};

export const getEnrollmentStatus = payload => {
  return api.get(
    URLS.BACKEND_API +
      `/api/permissions/get_enrollment_status/${payload.number}/${payload.siteId}`
  );
};

const size = {
  xm: "200px",
  sm: "768px",
  m: "961px",
  l: "1270px",
  xl: "1920px",
  mMax: "960px",
  fullLarge: "1919px",
  ml: "1024px",
  minlarge: "1281px",
  standardSize: "1280px",
  mw: "600px",
  tab: "1162px"
};

export const breakpoint = {
  xm: `(min-width: ${size.xm})`,
  sm: `(min-width: ${size.sm})`,
  m: `(min-width: ${size.m})`,
  l: `(min-width: ${size.l})`,
  xs: `(min-width: ${size.xl})`,
  minlarge: `(min-width: ${size.minlarge})`,
  mMax: `(max-width: ${size.mMax})`,
  mw: `(max-width: ${size.mw})`,
  ml: `(max-width: ${size.ml})`,
  fullLarge: `(max-width: ${size.fullLarge}),`,
  standardSize: `(width: ${size.standardSize}),`,
  smMax: `(max-width: ${size.sm}),`,
  maxL: `(max-width: ${size.l}),`,
  minStandardSize: `(min-width: ${size.tab}),`,
  minTab: `(min-width: ${size.standardSize}),`
};

import createWithActionCreator from "./helpers/createWithActionCreator";

export const NOTIFICATION_SEND = "NOTIFICATION_SEND";
export const NOTIFICATION_DISMISS = "NOTIFICATION_DISMISS";
export const NOTIFICATION_CLEAR = "NOTIFICATION_CLEAR";

export const notificationSend = payload => {
  return { type: NOTIFICATION_SEND, payload };
};

export const notificationDismiss = id => {
  return { type: NOTIFICATION_DISMISS, payload: id };
};

export const notificationClear = () => {
  return { type: NOTIFICATION_CLEAR };
};

// GET Notification Messages
export const GET_NOTIFICATION_MESSAGES_PENDING =
  "GET_NOTIFICATION_MESSAGES_PENDING";
export const GET_NOTIFICATION_MESSAGES_REJECTED =
  "GET_NOTIFICATION_MESSAGES_REJECTED";
export const GET_NOTIFICATION_MESSAGES_FULFILLED =
  "GET_NOTIFICATION_MESSAGES_FULFILLED";

export const getNotificationMessagesPending = () => ({
  type: GET_NOTIFICATION_MESSAGES_PENDING
});

export const getNotificationMessagesRejected = error => ({
  type: GET_NOTIFICATION_MESSAGES_REJECTED,
  payload: error
});

export const getNotificationMessagesFulfilled = payload => {
  return {
    type: GET_NOTIFICATION_MESSAGES_FULFILLED,
    payload
  };
};

export const SHOW_CUSTOM_CLOSE_NOTIFICATION = "SHOW_CUSTOM_CLOSE_NOTIFICATION";

export const showCustomCloseNotification = payload => ({
  type: SHOW_CUSTOM_CLOSE_NOTIFICATION,
  payload
});

export const withNotificationMessages = createWithActionCreator(
  "getNotificationMessages",
  getNotificationMessagesPending
);

export const withShowCustomCloseNotification = createWithActionCreator(
  "showCustomCloseNotification",
  showCustomCloseNotification
);

import { call, put, takeEvery } from "redux-saga/effects";
import * as renewalService from "../../services/contractRenewalService";
import {
  GET_CONTRACT_RENEWALS_PENDING,
  PUT_CONTRACT_RENEWALS_PENDING,
  getContractRenewalsRejected,
  getContractRenewalsFulfilled,
  putContractRenewalsRejected
} from "../actions/contractRenewalActions";
import getResourceError from "./helpers/getResourceError";
import { notificationSend } from "../actions/notificationActions";

export function* getContractRenewals(action) {
  const { params } = action.payload;

  try {
    const contractRenewals = yield call(
      renewalService.getContractRenewals,
      params
    );
    yield put(getContractRenewalsFulfilled(contractRenewals));
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "GET",
      "CONTRACT_RENEWALS",
      "Oops. An error occurred when fetching the list of contractRenewals."
    );
    yield put(getContractRenewalsRejected(resourceError));
  }
}

export function* putContractRenewals(action) {
  const { serialNumbers, params, type } = action.payload;

  try {
    let service = renewalService.renewContractRenewals;
    if (type === "mute") {
      service = renewalService.muteContractRenewals;
    }
    if (type === "unmute") {
      service = renewalService.unmuteContractRenewals;
    }
    yield call(service, serialNumbers);

    const contractRenewals = yield call(
      renewalService.getContractRenewals,
      params
    );
    yield put(getContractRenewalsFulfilled(contractRenewals));

    yield put(
      notificationSend({
        id: new Date(),
        label: "Your request was successfully sent.",
        variant: "success",
        dismissAfter: 5000
      })
    );
  } catch (error) {
    const resourceError = yield call(
      getResourceError,
      error,
      "PUT",
      "CONTRACT_RENEWALS",
      "Oops. An error occurred when updating the contractRenewals."
    );
    yield put(putContractRenewalsRejected(resourceError));
  }
}

function* watchGetContractRenewals() {
  yield takeEvery(GET_CONTRACT_RENEWALS_PENDING, getContractRenewals);
}

function* watchPutContractRenewals() {
  yield takeEvery(PUT_CONTRACT_RENEWALS_PENDING, putContractRenewals);
}

export default [watchGetContractRenewals, watchPutContractRenewals];

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// this is a function which creates a higher order component
// propName: name of property in component props
// actionCreator: the action creator bound to dispatch in the property
export default (propName, actionCreator) => {
  return Component =>
    connect(
      null,
      dispatch => bindActionCreators({ [propName]: actionCreator }, dispatch)
    )(props => <Component {...props} />);
};

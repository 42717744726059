export const standardOperations = ["CREATE", "DELETE", "GET", "PATCH", "SET"];
export const standardOperationStates = [
  "CANCELLED",
  "ERROR",
  "FULFILLED",
  "PENDING",
  "REJECTED",
  "SELECTED",
  "NEXT_PENDING"
];

export const remoteObjectOperations = [...standardOperations];
export const remoteObjectOperationStates = [...standardOperationStates];

export const remoteArrayOperations = [...standardOperations];
export const remoteArrayOperationStates = [...standardOperationStates];

export const createRemoteActionExpression = (
  prefixes,
  suffixes
) => resourceId =>
  `^(${prefixes.join("|")})_${resourceId}_(${suffixes.join("|")})$`;

export const getRemoteArrayActionExpression = createRemoteActionExpression(
  remoteArrayOperations,
  remoteArrayOperationStates
);

export const getRemoteObjectActionExpression = createRemoteActionExpression(
  remoteObjectOperations,
  remoteObjectOperationStates
);

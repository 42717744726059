import {
  IS_LOADER_VISIBLE
 } from "../actions/loaderAction";

export default function loader(state = false, action) {

  switch (action.type) {
    case IS_LOADER_VISIBLE:
      return action.payload
    default:
      return state;
  }
}

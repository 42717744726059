import createWithActionCreator from "./helpers/createWithActionCreator";

// GET MICROCODES
export const GET_MICROCODES_PENDING = "GET_MICROCODES_PENDING";
export const GET_MICROCODES_REJECTED = "GET_MICROCODES_REJECTED";
export const GET_MICROCODES_FULFILLED = "GET_MICROCODES_FULFILLED";

export const getMicrocodesPending = params => ({
  type: GET_MICROCODES_PENDING,
  payload: { params }
});

export const getMicrocodesRejected = error => ({
  type: GET_MICROCODES_REJECTED,
  payload: error
});

export const getMicrocodesFulfilled = payload => {
  return {
    type: GET_MICROCODES_FULFILLED,
    payload
  };
};

// PUT MICROCODES
export const PUT_MICROCODES_PENDING = "PUT_MICROCODES_PENDING";
export const PUT_MICROCODES_REJECTED = "PUT_MICROCODES_REJECTED";
export const PUT_MICROCODES_FULFILLED = "PUT_MICROCODES_FULFILLED";

export const muteMicrocodesPending = (serialNumbers, params) => ({
  type: PUT_MICROCODES_PENDING,
  payload: { serialNumbers, params, type: "mute" }
});

export const unmuteMicrocodesPending = (serialNumbers, params) => ({
  type: PUT_MICROCODES_PENDING,
  payload: { serialNumbers, params, type: "unmute" }
});

export const upgradeMicrocodesPending = (serialNumbers, params) => ({
  type: PUT_MICROCODES_PENDING,
  payload: { serialNumbers, params, type: "upgrade" }
});

export const putMicrocodesRejected = error => ({
  type: PUT_MICROCODES_REJECTED,
  payload: error
});

export const putMicrocodesFulfilled = payload => {
  return {
    type: PUT_MICROCODES_FULFILLED,
    payload
  };
};

export const withGetMicrocodes = createWithActionCreator(
  "getMicrocodes",
  getMicrocodesPending
);

export const withMuteMicrocodes = createWithActionCreator(
  "muteMicrocodes",
  muteMicrocodesPending
);

export const withUnmuteMicrocodes = createWithActionCreator(
  "unmuteMicrocodes",
  unmuteMicrocodesPending
);

export const withUpgradeMicrocodes = createWithActionCreator(
  "upgradeMicrocodes",
  upgradeMicrocodesPending
);

import { createTheme } from "@mui/material/styles";
import { FONT_FAMILY } from "./helpers/constants";

export default createTheme({
  chip: {
    borderRadius: 3,
    fontSize: "0.75rem"
  },
  typography: {
    useNextVariants: true,
    fontFamily: FONT_FAMILY,
    button: {
      textTransform: "none",
      fontSize: 16
    }
  }
});

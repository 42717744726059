import { CONST_VALUES } from "../../constants/constants";
import { RESET_STATE } from "../actions/assetActions";
import createRemoteArrayResourceReducer from "./helpers/createRemoteArrayResourceReducer";
import createRemoteObjectResourceReducer from "./helpers/createRemoteObjectResourceReducer";
import {
  getPendingAsyncStateReducer,
  getRejectedAsyncStateReducer
} from "./helpers/remoteResourceAsyncStateReducers";

export const assets = createRemoteArrayResourceReducer("ASSETS");
export const filteredAssets = createRemoteArrayResourceReducer(
  "FILTERED_ASSETS"
);
export const activeAssets = createRemoteArrayResourceReducer("ACTIVE_ASSETS");
export const assetsThreshold = createRemoteArrayResourceReducer(
  "ASSETS_THRESHOLD"
);
export const entitlements = createRemoteArrayResourceReducer("ENTITLEMENTS");
export const intUserAssets = createRemoteArrayResourceReducer("INTUSER_ASSETS");
export const asset = createRemoteObjectResourceReducer("ASSET");
export const selectedAsset = createRemoteObjectResourceReducer(
  "SELECTED_ASSET"
);
export const addAssets = createRemoteObjectResourceReducer("ADD_ASSETS");
export const getAssetsReportCSV = createRemoteObjectResourceReducer(
  "GET_ASSETS_REPORT_CSV"
);
export const removeAssets = createRemoteObjectResourceReducer("REMOVE_ASSETS");
export const patchAssetToFavourites = createRemoteObjectResourceReducer(
  "ASSET_TO_FAVORITES"
);
export const orgDetails = createRemoteObjectResourceReducer("ORG_DETAILS");

export const credentialsGateWayApiInfo = createRemoteArrayResourceReducer(
  "API_GATEWAY_INFO"
);

export const updatedWhitelistIpInfo = createRemoteArrayResourceReducer(
  "UPDATE_WHITELIST_IP"
);
export const cloudConnectionStatus = createRemoteObjectResourceReducer(
  "CLOUD_CONNECTION"
);
export const performanceApprovalStatus = createRemoteObjectResourceReducer(
  "PERFORMACE_APPROVAL"
);

export const lunHostGroupDetails = createRemoteObjectResourceReducer(
  "LUN_HOST_GROUP_DETAILS"
);

export const evServers = createRemoteObjectResourceReducer("EV_SERVERS");
export const userPreference = createRemoteObjectResourceReducer(
  "USER_PREFERENCE"
);

export const analytics = (state = {}, action) => {
  const analyticsKeys = {
    lDevCapacity: "lDevCapacity",
    poolCapacity: "poolCapacity",
    parityGroupCapacity: "parityGroupCapacity",
    arrayMicrocodes: "arrayMicrocodes",
    supportContractRenewals: "supportContractRenewals",
    systemCapacityMetrics: "systemCapacityMetrics",
    systemTemperatureMetrics: "systemTemperatureMetrics",
    InternalDriveSummary: "internalDriveSummary",
    licensedAndInstalledCapacity: "licensedAndInstalledCapacity",
    fileStorageCapacity: "fileStorageCapacity"
  };

  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        value: null
      };

    case "GET_ANALYTICS_PENDING":
      return {
        ...state,
        ...getPendingAsyncStateReducer(state, action)
      };

    case "GET_ANALYTICS_REJECTED":
      return {
        ...state,
        ...getRejectedAsyncStateReducer(state, action)
      };

    case "GET_ANALYTICS_FULFILLED":
      switch (action.payload.analyticsType) {
        case "dataDriveBreakdown":
          return {
            ...state,
            value: {
              ...state.value,
              ...action.payload.data
            }
          };

        default:
          if (
            action.payload.data &&
            action.payload.data.message === CONST_VALUES.Error
          ) {
            return {
              ...state,
              value: {
                ...state.value,
                [analyticsKeys[action.payload.analyticsType]]: null
              }
            };
          }
          return {
            ...state,
            value: {
              ...state.value,
              [analyticsKeys[action.payload.analyticsType]]: action.payload.data
            }
          };
      }
    default:
      return state;
  }
};

export const assetDetails = (state = {}, action) => {
  const assetDetailsKeys = {
    Capacity: "usedVersusAvailableCapacity",
    Latency: "latencyTrend",
    Throughput: "throughputTrend",
    IOPS: "iopsTrend",
    Temperature: "temperatureTrend",
    Power: "powerConsumptionTrend",
    UsedCapacityUsageTrend: "usedCapacityUsageTrend",
    lDevCapacity: "lDevCapacity",
    parityGroupCapacity: "parityGroupCapacity",
    poolCapacity: "poolCapacity",
    serialNumber: "serialNumber",
    HCPStorageCapacity: "hcpStorageCapacity",
    HNASStorageCapacity: "hnasStorageCapacity",
    HCPMetricesDetails: "hcpMetricesDetails",
    InternalDriveSummary: "internalDriveSummary",
    StorageComponentsDetails: "storageComponentsDetails",
    NodesSummaryDetails: "nodesSummaryDetails",
    EvServers: "evServers",
    FileSystems: "fileSystems",
    Spans: "spans",
    Nodes: "nodes",
    SystemDrives: "systemDrives",
    // licenseVirtualStorage: "licenseVirtualStorage",
    // aggregateFileSystemCapacity: "aggregateFileSystemCapacity",
    // aggregateSpanCapacity: "aggregateSpanCapacity",
    CapacityHistory: "capacityHistory"
  };
  switch (action.type) {
    case "GET_ASSET_DETAILS_PENDING":
      return {
        ...state,
        ...getPendingAsyncStateReducer(state, action)
      };

    case "GET_ASSET_DETAILS_REJECTED":
      return {
        ...state,
        ...getRejectedAsyncStateReducer(state, action)
      };

    case "GET_ASSET_DETAILS_FULFILLED":
      switch (action.payload.detailType) {
        case "diskDriveBreakdown":
          return {
            ...state,
            value: {
              ...state.value,
              ...action.payload.data
            }
          };

        default:
          if (
            action.payload.data &&
            action.payload.data.message === CONST_VALUES.Error
          ) {
            return {
              ...state,
              value: {
                ...state.value,
                [assetDetailsKeys[action.payload.detailType]]: null
              }
            };
          }
          return {
            ...state,
            value: {
              ...state.value,
              [assetDetailsKeys[action.payload.detailType]]: action.payload.data
            }
          };
      }

    case "GET_HCP_ASSET_DETAILS_FULFILLED":
      return {
        ...state,
        value: {
          ...state.value,
          [assetDetailsKeys[action.payload.detailType]]: action.payload.data
        }
      };

    case "GET_HNAS_ASSET_DETAILS_FULFILLED":
      return {
        ...state,
        value: {
          ...state.value,
          [assetDetailsKeys[action.payload.detailType]]: action.payload.data
        }
      };

    // case "GET_HNAS_CAPACITY_HISTORY_DATA_FULFILLED":
    //   return {
    //     ...state,
    //     value: {
    //       ...state.value,
    //       [assetDetailsKeys[action.payload.detailType]]: action.payload.data
    //     }
    //   };

    case "GET_ASSET_DETAILS_RESET_FULFILLED":
      return {
        ...state,
        value: action.payload
      };

    case "GET_ASSET_GRAPH_PENDING":
      return {
        ...state,
        value: {
          ...state.value,
          [assetDetailsKeys[action.payload.params.type]]: {
            loader: true
          }
        }
      };

    case "GET_ASSET_GRAPH_FULFILLED":
      if (action.payload && action.payload.message === CONST_VALUES.Error) {
        return {
          ...state,
          value: {
            ...state.value,
            [assetDetailsKeys[action.graph]]: null
          }
        };
      }
      return {
        ...state,
        value: {
          ...state.value,
          [assetDetailsKeys[action.graph]]: action.payload
        }
      };
    default:
      return state;
  }
};

export const performanceDetails = (state = {}, action) => {
  const performanceDetailsKeys = {
    serialNumber: "serialNumber",
    ProcessorBusyRate: "processorBusyRate",
    CacheUsageRate: "cacheUsageRate",
    CacheWritePendingRate: "cacheWritePendingRate",
    VolumeIOPs: "volumeIOPs",
    VolumeResponseTime: "volumeResponseTime",
    VolumeTransferRate: "volumeTransferRate",
    OperationPerSecond: "operationPerSecond",
    ServerUtilization: "serverUtilization",
    DiskLatency: "diskLatency",
    EthernetThroughput: "ethernetThroughput"
  };
  switch (action.type) {
    case "GET_PERFORMANCE_DETAILS_PENDING":
      return {
        ...state,
        ...getPendingAsyncStateReducer(state, action)
      };

    case "GET_PERFORMANCE_DETAILS_REJECTED":
      return {
        ...state,
        ...getRejectedAsyncStateReducer(state, action)
      };

    case "GET_PERFORMANCE_DETAILS_FULFILLED":
      if (
        action.payload.data &&
        action.payload.data.message === CONST_VALUES.Error
      ) {
        return {
          ...state,
          value: {
            ...state.value,
            [performanceDetailsKeys[action.payload.detailType]]: null
          }
        };
      }
      return {
        ...state,
        value: {
          ...state.value,
          [performanceDetailsKeys[action.payload.detailType]]:
            action.payload.data
        }
      };

    case "GET_PERFORMANCE_GRAPH_PENDING":
      return {
        ...state,
        value: {
          ...state.value,
          [performanceDetailsKeys[action.payload.params.type]]: {
            loader: true
          }
        }
      };

    case "GET_PERFORMANCE_DETAILS_RESET_FULFILLED":
      return {
        ...state,
        value: action.payload
      };

    default:
      return state;
  }
};

export const graphRangeDetails = (state = {}, action) => {
  const graphRangeTabs = {
    CapacityAndEnvironment: "capacityAndEnvironment",
    Performance: "performance"
  };
  const graphs = {
    Capacity: "usedVersusAvailableCapacity",
    UsedCapacityUsageTrend: "usedCapacityUsageTrend",
    Temperature: "temperatureTrend",
    Power: "powerConsumptionTrend",
    ProcessorBusyRate: "processorBusyRate",
    OperationPerSecond: "operationPerSecond",
    ServerUtlization: "serverUtilization",
    DiskLatency: "diskLatency",
    EthernetThroughput: "ethernetThroughput",
    CacheUsageRate: "cacheUsageRate",
    CacheWritePendingRate: "cacheWritePendingRate",
    VolumeIOPs: "volumeIOPs",
    VolumeResponseTime: "volumeResponseTime",
    VolumeTransferRate: "volumeTransferRate"
  };
  switch (action.type) {
    case "GET_GRAPH_RANGE_DETAILS_PENDING":
      return {
        ...state,
        ...getPendingAsyncStateReducer(state, action)
      };

    case "GET_GRAPH_RANGE_DETAILS_REJECTED":
      return {
        ...state,
        ...getRejectedAsyncStateReducer(state, action)
      };

    case "GET_GRAPH_RANGE_DETAILS_FULFILLED":
      return {
        ...state,
        value: {
          ...state.value,
          [graphRangeTabs[action.payload.tab]]: {
            ...(state.value && state.value[graphRangeTabs[action.payload.tab]]),
            [graphs[action.payload.graph]]: action.payload.data
          }
        }
      };

    case "GET_GRAPH_RANGE_DETAILS_RESET_FULFILLED":
      return {
        ...state,
        value: action.payload
      };

    default:
      return state;
  }
};

export const COLORS = {
  Hoki: "#607D8B",
  Sushi: "#7AA434",
  HunterGreen: "#386641",
  LightGrey: "#D1D1D1",
  SilverTree: "#6EBA9A",
  DarkSlateBlue: "#483D8B",
  Dodgerblue: "#1E90FF",
  DarkSlateGray: "#2F4F4F",
  Teal: "#008080",
  Blue: "#0000FF",
  Orange: "#FFA500",
  DarkViolet: "#9400D3",
  DarkRed: "#8B0000",
  White: "#FFFFFF",
  DarkSeaGreen: "#8FBC8F",
  VioletBlue: "#AD5BA6",
  Apple: "#71AF57",
  Matrix: "#904B42",
  Eminence: "#6E3B7A",
  SteelBlue: "#528EB6",
  ChileanFire: "#D4603B",
  Shocking: "#DDA8C2",
  BrightSun: "#E9C02F",
  PalePurple: "#7E71AD",
  PeriwinkleBlue: "#B3C6EE",
  Terracotta: "#EBB07C",
  Celadon: "#8EC6B6",
  Lavender: " #8A8EB1 ",
  DustyRose: "#D99395",
  PaleYellow: "#FEE0A0",
  CeruleanBlue: "#569DC1",
  PastelPink: "#FBADBA",
  // background_color: "#F4F5F5",
  MediumBlue: "#95AFE8",
  SlateBlue: "#6F749F",
  Gray: "#E8E8E8",
  Green: "#478B1A",
  container_bg_color: "#FBFCFC",

  background_color: "#F4F5F5",
  divider_border_color: "#CCCED0",
  text: "#414141",
  brand_color: "#CC0000",
  primary_color: "#2064B4",
  tbl_line_color: "#E8E8E8",
  good_status_color: "#478B1A",
  warning_status_color: "#F27C27",
  critical_status_color: "#D43136",
  error_banner_color: "#F4D3D4",
  success_tag: "#D7E6CF",
  secondary_txt_color: "#999999"
};

import createWithActionCreator from "./helpers/createWithActionCreator";

// GET COMMENTS
export const GET_COMMENTS_PENDING = "GET_COMMENTS_PENDING";
export const GET_COMMENTS_REJECTED = "GET_COMMENTS_REJECTED";
export const GET_COMMENTS_FULFILLED = "GET_COMMENTS_FULFILLED";
// CREATE COMMENT
export const CREATE_COMMENT_PENDING = "CREATE_COMMENT_PENDING";
export const CREATE_COMMENT_REJECTED = "CREATE_COMMENT_REJECTED";
export const CREATE_COMMENT_FULFILLED = "CREATE_COMMENT_FULFILLED";

export const getCommentsPending = serialNumber => ({
  type: GET_COMMENTS_PENDING,
  payload: { serialNumber }
});

export const getCommentsRejected = error => ({
  type: GET_COMMENTS_REJECTED,
  payload: error
});

export const getCommentsFulfilled = payload => {
  return {
    type: GET_COMMENTS_FULFILLED,
    payload
  };
};

export const postCommentPending = (serialNumber, comment) => ({
  type: CREATE_COMMENT_PENDING,
  payload: { serialNumber, comment }
});

export const postCommentRejected = error => ({
  type: CREATE_COMMENT_REJECTED,
  payload: error
});

export const postCommentFulfilled = payload => {
  return {
    type: CREATE_COMMENT_FULFILLED,
    payload
  };
};

export const withGetComments = createWithActionCreator(
  "getComments",
  getCommentsPending
);

export const withPostComment = createWithActionCreator(
  "postComment",
  postCommentPending
);

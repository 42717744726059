import { getEnvironment, getGaIdEnv } from "./gaaperture";

export const gtagUrl = "https://www.googletagmanager.com/gtag/js?id=";
const environmentName = getEnvironment();

export function loadHotjar() {
  let [code, hotjarSiteId] = environmentName && getGaIdEnv(environmentName);
  (function(h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function() {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: hotjarSiteId, hjsv: 6 };
    a = o.getElementsByTagName("head")[0];
    r = o.createElement("script");
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");

  (function(h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function() {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    a = o.getElementsByTagName("head")[0];
    r = o.createElement("script");
    r.async = 1;
    r.src = t + code;
    a.appendChild(r);
  })(window, document, gtagUrl, code);
}

export function testHotjarIdentifyApi(apiName, user_id, user_Details) {
  try {
    window.hj(apiName, user_id, user_Details);
  } catch (error) {
    console.log("Inside Identify API catch", error);
    loadHotjar();
    window.hj && window.hj(apiName, user_id, user_Details);
  }
}
export function testHotjarEvents(triggeredEvent, generatedString) {
  try {
    window.hj(triggeredEvent, generatedString);
  } catch (error) {
    console.log("Inside Hotjar Event catch", error);
  }
}

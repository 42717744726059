import { css } from "styled-components";
import { breakpoint } from "./helpers/breakpoints";

export default {
  ActionLink: {
    style: css`
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 2px;
      align-items: center;
      white-space: nowrap;
      cursor: ${p => (p.noneChecked ? "not-allowed" : "pointer")};
      ${p => p.noneChecked && "opacity: 0.5;"}
    `
  },
  ActionMenuItem: {
    style: css`
      & > div {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 5px;
        align-items: center;
        cursor: pointer;
        margin-right: 5px;
      }
    `
  },
  BulkActions: {
    style: css`
      display: grid;
      grid-template-columns: auto auto auto 1fr;
      grid-gap: 5px;
      margin-bottom: 15px;
      align-items: center;
      padding: 5px 0px;
      ${p => p.anySelected && "background-color: #D3E3F6;"}
      
      @media ${breakpoint.sm} {
        grid-gap: 15px;
      }
    `
  },
  CheckAll: {
    style: css`
      &&& {
        border: none;
        & > span:first-child {
          padding: 5px;
        }

        @media ${breakpoint.sm} {
          margin-right: 5px;
          & > span:first-child {
            padding: 8px;
          }
        }
      }

      &&&:hover {
        background-color: transparent;
      }
    `
  },
  CheckBox: {
    style: css`
      &&& {
        border: none;
        & > span:first-child {
          padding: 5px;
        }

        @media ${breakpoint.sm} {
          & > span:first-child {
            padding: 8px;
          }
        }
      }
    `
  },
  IconWrapper: {
    style: css`
      display: grid;
      align-items: center;
      & > svg {
        fill: ${p => p.color};
      }
    `
  },
  MoreVert: {
    style: css`
      && {
        font-size: 20px;
        cursor: pointer;
      }
    `
  },
  MoreVertWrapper: {
    style: css`
      && {
        ${p => p.open && "background-color: white;"}
        margin-bottom: -10px;
        padding-bottom: 8px;
        margin-top: -10px;
        padding-top: 8px;
        display: grid;
        justify-items: center;
      }
    `
  },
  StatusText: {
    style: css`
      && {
        white-space: nowrap;
        margin-bottom: 0px;
      }
      ${p => p.critical && "color: rgb(198, 40, 40)"}
      ${p => p.critical && "font-weight: bold;"}
    `
  },
  TableCell: {
    style: css`
      ${p => p.theme?.Table?.TableCell?.style}
      
      &&& {
        padding: 5px 15px;
        
        ${p => p.checkbox && "padding: 0px;"}
        ${p => p.checkbox && "width: 40px;"}
        ${p => p.checkbox && "border-right: 1px solid lightgrey;"}
        
        ${p => p.actions && "width: 30px;"}
        ${p => p.actions && "max-width: 30px;"}
        ${p => p.actions && "padding-right: 0px;"}
        ${p => p.actions && "padding-left: 0px;"}
        ${p => p.actions && "border-left: 1px solid lightgrey;"}
      }

      &&& > div {
        display: grid;
        grid-template-columns: auto auto auto auto 1fr;
        grid-gap: 8px;
        align-items: center;
        white-space: nowrap;
        ${p => p.actions && "grid-template-columns: auto;"}
      }
    `
  },
  Upgrade: {
    style: css`
      transform: rotate(90deg);
    `
  },
  Mute: {
    style: css`
      && {
        font-size: 32px;
      }
    `
  }
};

// Test: {
//   style: css`
//
//   `,
// },

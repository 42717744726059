import createWithActionCreator from "./helpers/createWithActionCreator";

// GET Entitlment
export const GET_ENTITLEMENT_CATEGORIES_PENDING = "GET_ENTITLEMENT_CATEGORIES_PENDING";
export const GET_ENTITLEMENT_CATEGORIES_REJECTED = "GET_ENTITLEMENT_CATEGORIES_REJECTED";
export const GET_ENTITLEMENT_CATEGORIES_FULFILLED = "GET_ENTITLEMENT_CATEGORIES_FULFILLED";

export const getEntitlementCategoriesPending = () => ({
  type: GET_ENTITLEMENT_CATEGORIES_PENDING
});

export const getEntitlementCategoriesRejected = error => ({
  type: GET_ENTITLEMENT_CATEGORIES_REJECTED,
  payload: error
});

export const getEntitlementCategoriesFulfilled = payload => {
  return {
    type: GET_ENTITLEMENT_CATEGORIES_FULFILLED,
    payload
  };
};


// GET Permissions List
export const GET_PERMISSIONS_LIST_PENDING = "GET_PERMISSIONS_LIST_PENDING";
export const GET_PERMISSIONS_LIST_REJECTED = "GET_PERMISSIONS_LIST_REJECTED";
export const GET_PERMISSIONS_LIST_FULFILLED = "GET_PERMISSIONS_LIST_FULFILLED";

export const getPermissionsListPending = () => {
  return ({
  type: GET_PERMISSIONS_LIST_PENDING
})
};

export const getPermissionsListRejected = error => ({
  type: GET_PERMISSIONS_LIST_REJECTED,
  payload: error
});

export const getPermissionsListFulfilled = payload => {
  return {
    type: GET_PERMISSIONS_LIST_FULFILLED,
    payload
  };
};

export const withEntitlementCategories = createWithActionCreator(
    "entitlementCategories",
    getEntitlementCategoriesPending
);

export const withPermissionsList = createWithActionCreator(
  "permissionsList",
  getPermissionsListPending
);


// CREATE PERMISSIONS 
export const CREATE_PERMISSIONS_PENDING = "CREATE_PERMISSIONS_PENDING";
export const CREATE_PERMISSIONS_REJECTED = "CREATE_PERMISSIONS_REJECTED";
export const CREATE_PERMISSIONS_FULFILLED = "CREATE_PERMISSIONS_FULFILLED";

export const postPermissionsPending = (payload, type) => ({
  type: CREATE_PERMISSIONS_PENDING,
  payload: { payload, panelType:type }
});

export const postPermissionsRejected = error => ({
  type: CREATE_PERMISSIONS_REJECTED,
  payload: error
});

export const postPermissionsFulfilled = payload => {
  return {
    type: CREATE_PERMISSIONS_FULFILLED,
    payload
  };
};

export const withPostPermissions = createWithActionCreator(
  "postPermissions",
  postPermissionsPending
);

export const CREATE_POST_PERMISSIONS_ACTION_RESET_PENDING = "CREATE_POST_PERMISSIONS_ACTION_RESET_PENDING";

export const postPermissionsActionResetPending = (payload) => ({
  type: CREATE_POST_PERMISSIONS_ACTION_RESET_PENDING,
  payload: { payload }
});

export const withPostPermissionsActionReset = createWithActionCreator(
  "postPermissionsActionReset",
  postPermissionsActionResetPending
);

// Delete Permissions
export const DELETE_REMOVE_PERMISSIONS_PENDING = "DELETE_REMOVE_PERMISSIONS_PENDING";
export const DELETE_REMOVE_PERMISSIONS_REJECTED = "DELETE_REMOVE_PERMISSIONS_REJECTED";
export const DELETE_REMOVE_PERMISSIONS_FULFILLED = "DELETE_REMOVE_PERMISSIONS_FULFILLED";

export const deletePermissionsPending = (payload) => ({
  type: DELETE_REMOVE_PERMISSIONS_PENDING,
  payload: { payload }
});

export const deletePermissionsRejected = error => ({
  type: DELETE_REMOVE_PERMISSIONS_REJECTED,
  payload: error
});

export const deletePermissionsFulfilled = payload => {
  return {
    type: DELETE_REMOVE_PERMISSIONS_FULFILLED,
    payload
  };
};

export const withDeletePermissions = createWithActionCreator(
  "deletePermissions",
  deletePermissionsPending
);


export const DELETE_REMOVE_PERMISSIONS_ACTION_RESET_PENDING = "DELETE_REMOVE_PERMISSIONS_ACTION_RESET_PENDING";

export const deletePermissionsActionResetPending = (payload) => {
  return ({
  type: DELETE_REMOVE_PERMISSIONS_ACTION_RESET_PENDING,
  payload
  })
  };

export const withDeletePermissionsActionReset = createWithActionCreator(
  "deletePermissionsActionReset",
  deletePermissionsActionResetPending
);


// GET Existing Email List
export const GET_EXISTING_EMAIL_LISTS_PENDING = "GET_EXISTING_EMAIL_LISTS_PENDING";
export const GET_EXISTING_EMAIL_LISTS_REJECTED = "GET_EXISTING_EMAIL_LISTS_REJECTED";
export const GET_EXISTING_EMAIL_LISTS_FULFILLED = "GET_EXISTING_EMAIL_LISTS_FULFILLED";

export const getExistingEmailListsPending = (payload) => {
  return ({
  type: GET_EXISTING_EMAIL_LISTS_PENDING,
  payload: { payload }
})
};

export const getExistingEmailListsRejected = error => ({
  type: GET_EXISTING_EMAIL_LISTS_REJECTED,
  payload: error
});

export const getExistingEmailListsFulfilled = payload => {
  return {
    type: GET_EXISTING_EMAIL_LISTS_FULFILLED,
    payload
  };
};

export const withExistingEmailLists = createWithActionCreator(
  "existingEmailLists",
  getExistingEmailListsPending
);

// GET Enrollment Status
export const GET_ENROLLMENT_STATUS_PENDING = "GET_ENROLLMENT_STATUS_PENDING";
export const GET_ENROLLMENT_STATUS_REJECTED = "GET_ENROLLMENT_STATUS_REJECTED";
export const GET_ENROLLMENT_STATUS_FULFILLED = "GET_ENROLLMENT_STATUS_FULFILLED";

export const getEnrollmentStatusPending = (payload) => {
  return ({
  type: GET_ENROLLMENT_STATUS_PENDING,
  payload: { payload }
})
};

export const getEnrollmentStatusRejected = error => ({
  type: GET_ENROLLMENT_STATUS_REJECTED,
  payload: error
});

export const getEnrollmentStatusFulfilled = payload => {
  return {
    type: GET_ENROLLMENT_STATUS_FULFILLED,
    payload
  };
};

export const withEnrollmentStatus = createWithActionCreator(
  "getEnrollmentStatus",
  getEnrollmentStatusPending
);
import createWithActionCreator from "./helpers/createWithActionCreator";

export const IS_LOADER_VISIBLE = "IS_LOADER_VISIBLE";

export const handleLoader = payload => {
  return { type: IS_LOADER_VISIBLE, payload };
};

export const withCustomLoader = createWithActionCreator(
  "handleLoader",
  handleLoader
);